// src/Pages/Login.js

import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import authApis from "../utils/API/authApis";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAccessToken, setRefreshToken, setFcmToken } from "../State/userSlice";
import { toast } from "sonner"; // Import toast from Sonner
import img from "../Asset/woa.png";
import { requestFirebaseNotificationPermission } from "../firebase-config"; // Import the request function

const Login = () => {
  const navigate = useNavigate(); // Correct usage of useNavigate
  const dispatch = useDispatch();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [loginDetails, setLoginDetails] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  // Remove the error state as we'll use toast for errors

  const images = [
    "https://wheelsofavalon.com/wp-content/uploads/2024/03/tesla-modely.png",
    "https://wheelsofavalon.com/wp-content/uploads/2024/09/New_Project_-_2024-09-05T110456.850-transformed-removebg-preview.png",
    // "https://via.placeholder.com/400?text=Image+3",
  ];

  const handleImageChange = (index) => {
    setCurrentImageIndex(index);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    // Remove setError as we're using toast

    // Basic validation
    if (!loginDetails.email || !loginDetails.password) {
      toast.error("Please enter both email and password.");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(loginDetails.email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    setLoading(true);

    try {
      // Check if fcmToken exists in localStorage
      let fcmToken = localStorage.getItem("fcmToken");
      if (!fcmToken) {
        // Request a new FCM token
        fcmToken = await requestFirebaseNotificationPermission();
        if (fcmToken) {
          dispatch(setFcmToken(fcmToken));
          toast.success("FCM Token acquired successfully.");
        } else {
          toast.warning("Failed to acquire FCM Token. Proceeding without it.");
        }
      }

      // Prepare the payload with deviceId if fcmToken is available
      const payload = {
        ...loginDetails,
        deviceId: fcmToken || null, // Assign null or omit based on your API requirements
      };

      const response = await authApis.loginApi(payload);
      console.log("Response: ", response);
      if (response.status === 201 && response.data.accessToken) {
        console.log("Login Successful");
        dispatch(setAccessToken(response.data.accessToken));
        dispatch(setRefreshToken(response.data.refreshToken));
        toast.success("Login successful! Redirecting to dashboard...");
        navigate("/Dashboard", { replace: true });
      } else {
        console.error("Failed to login!");
        toast.error("Failed to login. Please check your credentials.");
      }
    } catch (err) {
      // Handle login errors, e.g., display error message
      const errorMessage =
        err.response?.data?.message || "Login failed. Please try again.";
      console.error("Login Error: ", errorMessage);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex h-screen">
      {/* Left side - Welcome Back with image carousel */}
      <div className="w-1/2 flex flex-col justify-center items-center text-gray-500">
        <div className="w-[90%] p-10 rounded-3xl h-[65%] bg-slate-100 flex flex-col justify-center text-gray-600">
          <h1 className="text-4xl font-bold text-start mt-5 mb-10">
            Welcome Back!
          </h1>
          <p className="text-xl mb-10">
            We’re glad to see you again. Meanwhile, we are adding a few tips &
            tricks that you can take help with.
          </p>
          <img
            src={images[currentImageIndex]}
            alt={`Carousel ${currentImageIndex + 1}`}
            className="w-full h-64 rounded-3xl mb-6 object-cover"
          />
          {/* Page Indicator */}
          <div className="flex justify-center space-x-2">
            {images.map((_, index) => (
              <div
                key={index}
                className={`w-5 h-5 rounded-full cursor-pointer ${
                  index === currentImageIndex ? "bg-green-500" : "bg-gray-400"
                }`}
                onClick={() => handleImageChange(index)}
              ></div>
            ))}
          </div>
        </div>
      </div>

      {/* Right side - Login Form */}
      <div className="w-1/2 flex flex-col justify-center items-center">
        {/* Logo */}
        <div className="mb-8">
          <img src={img} alt="Logo" className="w-60" />
        </div>
        <div>
          <p className="text-lg mb-6">Sign in to continue</p>
        </div>

        {/* Login Form */}
        <form className="w-4/5 max-w-md" onSubmit={handleLogin}>
          <div className="mb-8">
            <label
              className="block text-gray-600 text-xl font-bold mb-2"
              htmlFor="email"
            >
              Email<span className="text-red-500">*</span>
            </label>
            <input
              id="email"
              name="email"
              type="email"
              value={loginDetails.email}
              onChange={handleChange}
              className="w-full px-3 py-3 border border-gray-400 rounded-lg text-gray-700 focus:outline-none focus:border-green-600"
              placeholder="Enter your email"
              required
            />
          </div>

          <div className="mb-6 relative">
            <label
              className="block text-gray-600 text-xl font-bold mb-2"
              htmlFor="password"
            >
              Password<span className="text-red-500">*</span>
            </label>
            <input
              id="password"
              name="password"
              type={showPassword ? "text" : "password"}
              value={loginDetails.password}
              onChange={handleChange}
              className="w-full px-3 py-3 border border-gray-400 rounded-lg text-gray-700 focus:outline-none focus:border-green-600"
              placeholder="Enter your password"
              required
            />
            <div
              className="absolute inset-y-0 right-3 top-8 flex items-center cursor-pointer"
              onClick={togglePasswordVisibility}
              aria-label={showPassword ? "Hide password" : "Show password"}
            >
              {showPassword ? (
                <FaEyeSlash className="text-gray-600 h-6 w-6" />
              ) : (
                <FaEye className="text-gray-600 h-6 w-6" />
              )}
            </div>
          </div>

          <div className="flex justify-between items-center mb-4">
            <a href="#" className="text-blue-400 text-md hover:underline">
              Forgot Password?
            </a>
          </div>

          {/* Remove the inline error message */}
          {/* {error && <p className="text-red-500 text-sm mb-4">{error}</p>} */}

          <button
            type="submit"
            className={`w-full bg-green-600 text-white font-bold py-3 px-4 rounded-lg hover:bg-green-400 transition duration-300 ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={loading}
          >
            {loading ? "Logging in..." : "Log In"}
          </button>
        </form>

        <div className="mt-10">
          <p>© 2024 | All rights reserved</p>
        </div>
      </div>
    </div>
  );
};

export default Login;
