// src/State/userSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessToken: localStorage.getItem("authToken") || null,
  refreshToken: localStorage.getItem("refreshToken") || null,
  fcmToken: localStorage.getItem("fcmToken") || null,
  // Add other user-related states here
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setAccessToken(state, action) {
      state.accessToken = action.payload;
      localStorage.setItem("authToken", action.payload);
    },
    setRefreshToken(state, action) {
      state.refreshToken = action.payload;
      localStorage.setItem("refreshToken", action.payload);
    },
    setFcmToken(state, action) {
      state.fcmToken = action.payload;
      localStorage.setItem("fcmToken", action.payload);
    },
    removeTokens(state) {
      state.accessToken = null;
      state.refreshToken = null;
      state.fcmToken = null;
      localStorage.removeItem("authToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("fcmToken");
    },
  },
});

export const { setAccessToken, setRefreshToken, setFcmToken, removeTokens } = userSlice.actions;

export default userSlice.reducer;
