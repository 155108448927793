// src/hooks/useTokenRefresh.js

import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAccessToken,logout, removeTokens, setRefreshToken} from '../State/userSlice';
import { apiPostFetcher } from '../utils/API/apiFetcher';
import { getTokenExpiryTime } from '../utils/jwt';
import { ADMIN_AUTH } from '../utils/API/apiEndPoint';

const useTokenRefresh = () => {
  const dispatch = useDispatch();
  const refreshToken = useSelector((state) => state.user.refreshToken);
  const accessToken = useSelector((state) => state.user.accessToken);
  const refreshTimeout = useRef(null);

  const refreshAccessToken = async () => {
    if (!refreshToken) {
      console.warn('No refresh token available');
      dispatch(removeTokens());
      return;
    }

    try {
      const response = await apiPostFetcher(`${ADMIN_AUTH}/token`, { refreshToken });

      if (response.error) {
        console.error('Failed to refresh access token:', response.error);
        dispatch(removeTokens());
      } else {
        const { accessToken: newAccessToken } = response.data;
        dispatch(setAccessToken(newAccessToken));
        dispatch(setRefreshToken(response.data.refreshToken));
        console.log('Access token refreshed');
      }
    } catch (error) {
      console.error('Error refreshing access token:', error);
      dispatch(removeTokens());
    }
  };

  useEffect(() => {
    // Clear existing timeout
    if (refreshTimeout.current) {
      clearTimeout(refreshTimeout.current);
    }

    if (accessToken) {
      const expiryTime = getTokenExpiryTime(accessToken);
      if (expiryTime) {
        const currentTime = Date.now();
        const timeout = expiryTime - currentTime - 60000; // Refresh 1 minute before expiry

        if (timeout > 0) {
          refreshTimeout.current = setTimeout(() => {
            refreshAccessToken();
          }, timeout);
          console.log(`Access token will be refreshed in ${timeout / 1000} seconds`);
        } else {
          // Token already expired or about to expire, refresh immediately
          refreshAccessToken();
        }
      }
    }

    // Cleanup on unmount or when accessToken changes
    return () => {
      if (refreshTimeout.current) {
        clearTimeout(refreshTimeout.current);
      }
    };
  }, [accessToken, refreshToken]);

  return null; 
};

export default useTokenRefresh;
