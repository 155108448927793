// AddEditFare.jsx
import { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import fareApiFnc from "../../utils/API/fareApis";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useAuthToken from "../../hooks/customHooks";
import vehicleAPI from "../../utils/API/vehicleApis";
import { toast } from "sonner"; 


function AddEditFare() {
  const navigate = useNavigate();
  const token = useAuthToken();
  const location = useLocation();
  const { fareId } = useParams(); // Extract fareId from URL params
  const editFareFromState = location.state?.fare; // Extract fare from navigation state

  const [availableVehicleCategories, setAvailableVehicleCategories] = useState([]);

  const [fareDetails, setFareDetails] = useState({
    vehicleCategoryId: "",
    ratePerKm: "",
    ratePerHour: "",
    ratePerDay: "",
    woa_price_percentage: "",
    base_fare: "",
    minimum_fare: "",
    minimum_km: "",
    specialcharge_percentage: "",
  });

  const [loadingCategories, setLoadingCategories] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [fetchingFare, setFetchingFare] = useState(false);
  const [fetchFareError, setFetchFareError] = useState(null);

  // Debugging Logs
  useEffect(() => {
    console.log("fareId from params:", fareId);
    console.log("editFareFromState:", editFareFromState);
  }, [fareId, editFareFromState]);

  // Initialize fareDetails if editing
  useEffect(() => {
    if (fareId && editFareFromState) {
      console.log("Setting fareDetails from navigation state:", editFareFromState);
      setFareDetails(editFareFromState);
    } else if (fareId) {
      // If fare data isn't passed via state, fetch it from the API
      const fetchFare = async () => {
        setFetchingFare(true);
        try {
          const response = await fareApiFnc.getFareByIdAPI(fareId, token);
          if (response?.status === 200) {
            console.log("Fetched fare from API:", response.data.fare);
            setFareDetails(response.data.fare);
          } else {
            console.error("Failed to fetch fare from API:", response);
            setFetchFareError("Failed to load fare details.");
          }
        } catch (err) {
          console.error("Error fetching fare by ID:", err);
          setFetchFareError("Failed to load fare details.");
        } finally {
          setFetchingFare(false);
        }
      };
      fetchFare();
    }
  }, [fareId, editFareFromState, token]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFareDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // Fetch available vehicle categories
  useEffect(() => {
    const fetchVehicleCategories = async () => {
      setLoadingCategories(true);
      try {
        const response = await vehicleAPI.getVehiclesCategoryAPI(1, 100, {}, token);
        setAvailableVehicleCategories(response.data.paginatedData || []);
      } catch (err) {
        console.error("Failed to fetch vehicle categories:", err);
        alert("Failed to load vehicle categories. Please try again.");
      } finally {
        setLoadingCategories(false);
      }
    };
    fetchVehicleCategories();
  }, [token]);

  // Form validation
  const validate = () => {
    const newErrors = {};
    const requiredFields = [
      "vehicleCategoryId",
      "ratePerKm",
      "ratePerHour",
      "ratePerDay",
      "woa_price_percentage",
      "base_fare",
      "minimum_fare",
      "minimum_km",
      "specialcharge_percentage",
    ];

    requiredFields.forEach((field) => {
      if (!fareDetails[field]) {
        newErrors[field] = `${field.replace(/_/g, " ")} is required.`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    console.log("Submitting fareDetails:", fareDetails);

    setLoading(true);
    try {
      let response;
      if (fareId) {
        // Editing an existing fare
        response = await fareApiFnc.updateFareAPI(fareDetails, fareId, token);
      } else {
        // Adding a new fare
        response = await fareApiFnc.addFareAPI(fareDetails, token);
      }

      if (response?.status === 200 || response?.status === 201) {
        toast.success(`Fare ${fareId ? "updated" : "added"} successfully!`);
        navigate("/vehicles/fares"); // Navigate to the fares list
      } else {
        const errorMessage = response.data?.error || "Something went wrong. Please try again.";
        toast.error(errorMessage);
      }
    } catch (error) {
      alert(
        error.response?.data?.error ||
          error.message ||
          "An error occurred while submitting the form."
      );
    } finally {
      setLoading(false);
    }
  };

  // Handle form reset or cancellation
  const handleReset = () => {
    if (fareId) {
      navigate("/vehicles/fares");
    } else {
      setFareDetails({
        vehicleCategoryId: "",
        ratePerKm: "",
        ratePerHour: "",
        ratePerDay: "",
        woa_price_percentage: "",
        base_fare: "",
        minimum_fare: "",
        minimum_km: "",
        specialcharge_percentage: "",
      });
      setErrors({});
    }
  };

  // Handle loading and error states during fare fetching
  if (fareId && (fetchingFare || fetchFareError)) {
    return (
      <>
        <Navbar />
        <div className="flex pt-16">
          <Sidebar currentSelection={{ main: "fares", sub: "allFares" }} />
          <div className="flex-1 bg-stone-200 p-6 overflow-y-auto">
            <div className="container mx-auto p-4">
              {fetchingFare ? (
                <div className="flex justify-center p-4 text-gray-500">
                  Loading fare details...
                </div>
              ) : fetchFareError ? (
                <div className="flex justify-center p-4 text-red-500">
                  {fetchFareError}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Navbar />
      <div className="flex pt-16">
        <Sidebar currentSelection={{ main: "fares", sub: "allFares" }} />
        <div className="flex-1 bg-stone-200 p-6 overflow-y-auto">
          <div className="container mx-auto p-4">
            <div className="bg-stone-50 p-5 mb-8 rounded-xl mt-12">
              <form onSubmit={handleSubmit}>
                <div className="flex justify-between">
                  <div className="text-xl py-2 my-2">
                    {fareId ? "Edit Fare" : "Add New Fare"}
                  </div>
                  <div>
                    <button
                      type="button"
                      className="bg-transparent text-green-700 border border-green-700 rounded-lg p-2 h-10"
                      onClick={() => {
                        navigate("/vehicles/fares");
                      }}
                    >
                      Back
                    </button>
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-6 mt-4">
                  {[
                    { label: "Vehicle Category", name: "vehicleCategoryId", type: "select" },
                    { label: "Rate Per Km", name: "ratePerKm", type: "number" },
                    { label: "Rate Per Hour", name: "ratePerHour", type: "number" },
                    { label: "Rate Per Day", name: "ratePerDay", type: "number" },
                    { label: "WOA Price Percentage", name: "woa_price_percentage", type: "number" },
                    { label: "Base Fare", name: "base_fare", type: "number" },
                    { label: "Minimum Fare", name: "minimum_fare", type: "number" },
                    { label: "Minimum Km", name: "minimum_km", type: "number" },
                    { label: "Special Charge Percentage", name: "specialcharge_percentage", type: "number" },
                  ].map((input) => (
                    <div key={input.name} className="flex flex-col text-gray-600">
                      <label htmlFor={input.name}>{input.label} *</label>
                      {input.type === "select" ? (
                        <select
                          id={input.name}
                          name={input.name}
                          value={fareDetails[input.name]}
                          onChange={handleChange}
                          required
                          disabled={loadingCategories}
                          className={`border rounded-xl p-2 ${
                            errors[input.name] ? "border-red-500" : "border-gray-300"
                          }`}
                          aria-invalid={errors[input.name] ? "true" : "false"}
                        >
                          <option value="">Select Category</option>
                          {availableVehicleCategories?.map((vehicle) => (
                            <option key={vehicle.id} value={vehicle.id}>
                              {vehicle.category_name}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          type={input.type}
                          id={input.name}
                          name={input.name}
                          value={fareDetails[input.name]}
                          onChange={handleChange}
                          required
                          className={`border rounded-xl p-2 ${
                            errors[input.name] ? "border-red-500" : "border-gray-300"
                          }`}
                          aria-invalid={errors[input.name] ? "true" : "false"}
                        />
                      )}

                      {errors[input.name] && (
                        <span className="text-red-500 text-sm">{errors[input.name]}</span>
                      )}
                    </div>
                  ))}
                </div>

                <div className="flex gap-2 mt-4">
                  <button
                    type="submit"
                    className="bg-transparent text-green-700 border border-green-700 rounded-lg p-2 h-10"
                    disabled={loading}
                  >
                    {loading ? "Saving..." : "Save"}
                  </button>
                  <button
                    type="button"
                    className="bg-transparent text-red-700 border border-red-700 rounded-lg p-2 h-10"
                    onClick={handleReset}
                  >
                    {fareId ? "Cancel" : "Reset"}
                  </button>
                </div>
              </form>
              {loading && (
                <div className="flex justify-center p-4 text-gray-500">Saving...</div>
              )}
              {loadingCategories && (
                <div className="flex justify-center p-4 text-gray-500">
                  Loading Categories...
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddEditFare;
