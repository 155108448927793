// src/utils/apiFetcher.js

/**
 * Unified structure for API responses.
 * @typedef {Object} ApiResponse
 * @property {any|null} data - The data returned from the API.
 * @property {number|null} status - The HTTP status code from the response.
 * @property {string|null} error - The error message, if any.
 */

/**
 * Fetcher function for POST requests with JSON body.
 * @param {string} url - The endpoint URL.
 * @param {Object} body - The data to be sent in the request body.
 * @param {string} token - Bearer Token.
 * @param {Object} [options={}] - Additional fetch options.
 * @returns {Promise<ApiResponse>} An object containing `data`, `status`, and `error`.
 */
export const apiPostFetcher = async (url, body, token, options = {}) => {
  console.log("Token:",token);
  const defaultHeaders = {
    "Content-Type": "application/json",
    ...(token && { Authorization: `Bearer ${token}` }),
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        ...defaultHeaders,
        ...options.headers,
      },
      body: JSON.stringify(body),
      ...options,
    });

    const status = response.status;
    let data = null;
    let error = null;

    try {
      data = await response.json();
    } catch (e) {
      // If response is not JSON, get text
      data = await response.text();
    }

    if (!response.ok) {
      error = data?.message || `Error ${status}`;
    }

    return { data, status, error };
  } catch (err) {
    console.error("API POST fetcher error:", err);
    return { data: null, status: null, error: err.message || "Network Error" };
  }
};

/**
 * Fetcher function for PUT requests with JSON body.
 * @param {string} url - The endpoint URL.
 * @param {Object} body - The data to be sent in the request body.
 * @param {string} token - Bearer Token.
 * @param {Object} [options={}] - Additional fetch options.
 * @returns {Promise<ApiResponse>} An object containing `data`, `status`, and `error`.
 */
export const apiPutFetcher = async (url, body, token, options = {}) => {
  const defaultHeaders = {
    "Content-Type": "application/json",
    ...(token && { Authorization: `Bearer ${token}` }),
  };

  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        ...defaultHeaders,
        ...options.headers,
      },
      body: JSON.stringify(body),
      ...options,
    });

    const status = response.status;
    let data = null;
    let error = null;

    try {
      data = await response.json();
    } catch (e) {
      // If response is not JSON, get text
      data = await response.text();
    }

    if (!response.ok) {
      error = data?.message || `Error ${status}`;
    }

    return { data, status, error };
  } catch (err) {
    console.error("API PUT fetcher error:", err);
    return { data: null, status: null, error: err.message || "Network Error" };
  }
};

/**
 * Fetcher function for POST requests with FormData.
 * @param {string} url - API endpoint.
 * @param {FormData} formData - The FormData object containing driver details.
 * @param {string} token - Bearer Token.
 * @returns {Promise<ApiResponse>} An object containing `data`, `status`, and `error`.
 */
export const postFormDataAPI = async (url, formData, token) => {
  const defaultHeaders = {
    ...(token && { Authorization: `Bearer ${token}` }),
  };
  try {
    console.log("FormData: ", formData);
    const response = await fetch(url, {
      method: "POST",
      headers: {
        ...defaultHeaders,
      },
      body: formData,
      // Do not set the 'Content-Type'; browser sets it automatically
    });

    const status = response.status;
    let data = null;
    let error = null;

    try {
      data = await response.json();
    } catch (e) {
      // If response is not JSON, get text
      data = await response.text();
    }

    if (!response.ok) {
      error = data?.message || `Error ${status}`;
    }

    return { data, status, error };
  } catch (err) {
    console.error("API POST FormData fetcher error:", err);
    return { data: null, status: null, error: err.message || "Network Error" };
  }
};

/**
 * Fetcher function for PUT requests with FormData.
 * @param {string} url - API endpoint for editing driver.
 * @param {FormData} formData - The FormData object containing updated driver details.
 * @param {string} token - Bearer Token.
 * @returns {Promise<ApiResponse>} An object containing `data`, `status`, and `error`.
 */
export const updateFormDataAPI = async (url, formData, token) => {
  const defaultHeaders = {
    ...(token && { Authorization: `Bearer ${token}` }),
  };
  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        ...defaultHeaders,
      },
      body: formData,
      // Do not set the 'Content-Type'; browser sets it automatically
    });

    const status = response.status;
    let data = null;
    let error = null;

    try {
      data = await response.json();
    } catch (e) {
      // If response is not JSON, get text
      data = await response.text();
    }

    if (!response.ok) {
      error = data?.message || `Error ${status}`;
    }

    return { data, status, error };
  } catch (err) {
    console.error("API PUT FormData fetcher error:", err);
    return { data: null, status: null, error: err.message || "Network Error" };
  }
};

/**
 * Fetcher function for GET requests.
 * @param {string} url - The endpoint URL.
 * @param {string} token - Bearer Token.
 * @param {Object} [options={}] - Additional fetch options.
 * @returns {Promise<ApiResponse>} An object containing `data`, `status`, and `error`.
 */
export const apiGetFetcher = async (url, token, options = {}) => {
  const defaultHeaders = {
    "Content-Type": "application/json",
    ...(token && { Authorization: `Bearer ${token}` }),
  };

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        ...defaultHeaders,
        ...options.headers,
      },
      ...options,
    });

    const status = response.status;
    let data = null;
    let error = null;

    try {
      data = await response.json();
    } catch (e) {
      console.log("Can't parse the response from the server.")
    }

    if (!response.ok) {
      error = data?.message || `Error ${status}`;
    }

    return { data, status, error };
  } catch (err) {
    console.error("API GET fetcher error:", err);
    return { data: null, status: null, error: err.message || "Network Error" };
  }
};

/**
 * Fetcher function for DELETE requests.
 * @param {string} url - The endpoint URL.
 * @param {string} token - Bearer Token.
 * @param {Object} [options={}] - Additional fetch options.
 * @returns {Promise<ApiResponse>} An object containing `data`, `status`, and `error`.
 */
export const apiDeleteFetcher = async (url, token, options = {}) => {
  const defaultHeaders = {
    "Content-Type": "application/json",
    ...(token && { Authorization: `Bearer ${token}` }),
  };

  try {
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        ...defaultHeaders,
        ...options.headers,
      },
      ...options,
    });

    const status = response.status;
    let data = null;
    let error = null;

    try {
      data = await response.json();
    } catch (e) {
      // If response is not JSON, get text
      // data = await response.text();
      console.error("Error jsonfying response.");
    }

    if (!response.ok) {
      error = data?.message || `Error ${status}`;
    }

    return { data, status, error };
  } catch (err) {
    console.error("API DELETE fetcher error:", err);
    return { data: null, status: null, error: err.message || "Network Error" };
  }
};
