// src/Components/Pagination/Pagination.jsx

import React from "react";
import PropTypes from "prop-types";

function Pagination({
  currentPage,
  totalPages,
  onPageChange,
  onFirstPage,
  onLastPage,
  onPreviousPage,
  onNextPage,
  maxPageButtons = 5, // Optional: Customize the number of page buttons
}) {
  // Function to generate an array of page numbers to display
  const getPageNumbers = () => {
    const pages = [];

    // Calculate the range of page numbers to display
    let half = Math.floor(maxPageButtons / 2);
    let startPage = Math.max(1, currentPage - half);
    let endPage = Math.min(totalPages, currentPage + half);

    // Adjust if we're near the start
    if (currentPage <= half) {
      endPage = Math.min(maxPageButtons, totalPages);
    }

    // Adjust if we're near the end
    if (currentPage + half >= totalPages) {
      startPage = Math.max(totalPages - maxPageButtons + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  };

  const pageNumbers = getPageNumbers();

  return (
    <nav aria-label="Page navigation">
      <ul className="inline-flex items-center -space-x-px">
        {/* First Page Button */}
        <li>
          <button
            onClick={onFirstPage}
            disabled={currentPage === 1}
            className={`px-3 py-2 ml-0 leading-tight ${
              currentPage === 1
                ? "text-gray-400 cursor-not-allowed"
                : "text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            } border border-gray-300 rounded-l-lg`}
            aria-label="First Page"
          >
            First
          </button>
        </li>

        {/* Previous Page Button */}
        <li>
          <button
            onClick={onPreviousPage}
            disabled={currentPage === 1}
            className={`px-3 py-2 leading-tight ${
              currentPage === 1
                ? "text-gray-400 cursor-not-allowed"
                : "text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            } border border-gray-300`}
            aria-label="Previous Page"
          >
            &laquo;
          </button>
        </li>

        {/* Page Number Buttons */}
        {pageNumbers.map((pageNumber) => (
          <li key={pageNumber}>
            <button
              onClick={() => onPageChange(pageNumber)}
              className={`px-3 py-2 leading-tight ${
                currentPage === pageNumber
                  ? "text-green-700 bg-green-100 border border-green-600"
                  : "text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              } border border-gray-300`}
              aria-current={currentPage === pageNumber ? "page" : undefined}
            >
              {pageNumber}
            </button>
          </li>
        ))}

        {/* Next Page Button */}
        <li>
          <button
            onClick={onNextPage}
            disabled={currentPage === totalPages}
            className={`px-3 py-2 leading-tight ${
              currentPage === totalPages
                ? "text-gray-400 cursor-not-allowed"
                : "text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            } border border-gray-300`}
            aria-label="Next Page"
          >
            &raquo;
          </button>
        </li>

        {/* Last Page Button */}
        <li>
          <button
            onClick={onLastPage}
            disabled={currentPage === totalPages}
            className={`px-3 py-2 leading-tight ${
              currentPage === totalPages
                ? "text-gray-400 cursor-not-allowed"
                : "text-gray-700 hover:bg-gray-100 hover:text-gray-900"
            } border border-gray-300 rounded-r-lg`}
            aria-label="Last Page"
          >
            Last
          </button>
        </li>
      </ul>
    </nav>
  );
}

// PropTypes for type checking
Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onFirstPage: PropTypes.func.isRequired,
  onLastPage: PropTypes.func.isRequired,
  onPreviousPage: PropTypes.func.isRequired,
  onNextPage: PropTypes.func.isRequired,
  maxPageButtons: PropTypes.number,
};

export default Pagination;
