// http://localhost:3000/api/adminpanel/getridebustatus/:pageNumber/:itemsPerPage'

import { ADMIN_ROUTE, BASE_URL, DASHBOARD_METRICS} from "./apiEndPoint";
import { apiGetFetcher } from "./apiFetcher";


const adminRoute = `${BASE_URL}${ADMIN_ROUTE}`

/**
 * To get all metrics related to dashboard.
 * @param {String} token - Bearer Token.
 */
const getDashboardAPI = async (token) => {
    let url = `${adminRoute}${DASHBOARD_METRICS}`;
    return apiGetFetcher(url,token);
  };

const dashboardAPI= {
    getDashboardAPI
}

export default dashboardAPI;