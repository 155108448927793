import React, { useState } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import coupons from "./couponData.js"; // Correct path to the coupon data
import { useNavigate } from "react-router-dom";

function AddNewCoupons() {
  const navigate = useNavigate();
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  console.log(Array.isArray(coupons));

  // Calculate the total number of pages
  const totalPages = Math.ceil(coupons.length / itemsPerPage);

  // Get the coupons for the current page
  const displayedCoupons = coupons.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  const handlePageChange = (direction) => {
    if (direction === "next" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    } else if (direction === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      <Navbar />
      <div className="flex pt-16">
        <Sidebar currentSelection={{ main: "coupons", sub: "addNewCoupons" }} />
        <div className="flex-1 bg-stone-200 p-6 overflow-y-auto">
          <div className="container mx-auto p-4">
            <h1 className="text-2xl text-gray-600 font-bold mb-4">All Coupons</h1>

            <div className="flex justify-between">
              {/* General Section */}
              <div className="w-2/3 p-6 bg-white shadow-md rounded-lg">
                <h2 className="text-xl font-semibold mb-4">General</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="flex flex-col">
                    <label className="mb-2 font-medium">
                      Coupon Title <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      className="p-2 border rounded-md"
                      placeholder="Title will be visible to user"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="mb-2 font-medium">
                      Coupon Type <span className="text-red-500">*</span>
                    </label>
                    <select className="p-2 border rounded-md">
                      <option value="Fixed">Fixed</option>
                    </select>
                  </div>
                  <div className="flex flex-col">
                    <label className="mb-2 font-medium">
                      Coupon Code <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      className="p-2 border rounded-md"
                      placeholder="CODE (UNIQUE)"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="mb-2 font-medium">
                      Value <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="number"
                      className="p-2 border rounded-md"
                      placeholder="Enter fixed amount"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="mb-2 font-medium">
                      Select date expiry <span className="text-red-500">*</span>
                    </label>
                    <input type="datetime-local" className="p-2 border rounded-md" />
                  </div>
                  <div className="flex flex-col">
                    <label className="mb-2 font-medium">
                      Allow other CC with this? <span className="text-red-500">*</span>
                    </label>
                    <select className="p-2 border rounded-md">
                      <option value="No">No, when user apply this, they...</option>
                    </select>
                  </div>
                  <div className="col-span-2 flex flex-col">
                    <label className="mb-2 font-medium">Short Description</label>
                    <textarea
                      className="p-2 border rounded-md"
                      placeholder="Short description for public info"
                    />
                  </div>
                </div>
              </div>

              {/* Publish Section */}
              <div className="w-1/3 ml-6 p-6 bg-white shadow-md rounded-lg">
                <h2 className="text-xl font-semibold mb-4">Publish</h2>
                <div className="grid grid-cols-1 gap-4">
                  <div className="flex flex-col">
                    <label className="mb-2 font-medium">Status</label>
                    <select className="p-2 border rounded-md">
                      <option>--Select Status--</option>
                    </select>
                  </div>
                  <div className="flex flex-col">
                    <label className="mb-2 font-medium">Max per user</label>
                    <input
                      type="number"
                      className="p-2 border rounded-md"
                      placeholder="Enter max users"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="mb-2 font-medium">Max usage</label>
                    <input
                      type="number"
                      className="p-2 border rounded-md"
                      placeholder="Enter max usage"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="mb-2 font-medium">
                      Is Private? <span className="text-red-500">*</span>
                    </label>
                    <select className="p-2 border rounded-md">
                      <option>No, It's public</option>
                    </select>
                  </div>
                </div>
                {/* Save Button */}
                <div className="flex justify-end mt-6">
                  <button className="bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600">
                    Save Coupon
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddNewCoupons;
