import React from 'react';
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";

const LiveUsers = () => {
  return (
<>
      <Navbar />
      <div className="flex pt-16">
        <Sidebar currentSelection={{ main: "liveusers", sub: "allCoupons" }} />
        <div className="flex-1  py-2 overflow-y-auto">
         

    <div className="flex h-screen">
      {/* Sidebar */}
      <div className="w-1/5 bg-gray-100 p-4">
        <h2 className="text-xl font-semibold mb-4">Active Users</h2>
        <div className="space-y-4">
          {/* User Icons */}
          <div className="flex items-center space-x-2">
            <span className="h-4 w-4 bg-green-500 rounded-full"></span>
            <p>Customer</p>
          </div>
          <div className="flex items-center space-x-2">
            <span className="h-4 w-4 bg-green-500 rounded-full"></span>
            <p>Driver</p>
          </div>
          <div className="flex items-center space-x-2">
            <span className="h-4 w-4 bg-green-500 rounded-full"></span>
            <p>Pd</p>
          </div>
          {/* Instructions */}
          <p className="text-sm text-gray-500">Click on name to track</p>
        </div>
      </div>

      {/* Map Area */}
      <div className="w-4/5">
        <iframe
          className="w-full h-full"
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2886.504302159847!2d-79.3839345!3d43.653226!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b34d00b45c167%3A0x4819b4e63d308b0b!2sToronto%2C%20ON%2C%20Canada!5e0!3m2!1sen!2sin!4v1633123119405!5m2!1sen!2sin"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        />
      </div>
    </div>
    </div>
      </div>
    </>
  );
};

export default LiveUsers;
