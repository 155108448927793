import { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import api from "../../utils/API/customerApis";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Toaster, toast } from "sonner";
import useAuthToken from "../../hooks/customHooks";

function AddEditCustomer() {
  const navigate = useNavigate();
  const location = useLocation();
  const { customerId } = useParams();
  const editElement = location.state?.customer;
  const token = useAuthToken();

  const [userDetails, setUserDetails] = useState({
    id: editElement?.id || "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    countryCode: "+91", // Default country code
    email: "",
    status: "enabled",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  // useEffect to populate form data in edit mode
  useEffect(() => {
    if (customerId && editElement) {
      setUserDetails({
        ...editElement,
        countryCode: editElement.phoneNumber
          ? editElement.phoneNumber.slice(0, editElement.phoneNumber.indexOf(editElement.phoneNumber.match(/\d/)))
          : "+91",
        phoneNumber: editElement.phoneNumber
          ? editElement.phoneNumber.replace(/^\+\d+/, "")
          : "",
      });
    }
  }, [customerId, editElement]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));

    // Clear the error for the field being edited
    if (errors[name]) {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  const validate = () => {
    const newErrors = {};

    // First Name Validation
    if (!userDetails.firstName?.trim()) {
      newErrors.firstName = "First name is required.";
    } else if (!/^[A-Za-z\s'-]+$/.test(userDetails.firstName)) {
      newErrors.firstName =
        "First name should contain only letters, spaces, apostrophes, or hyphens.";
    } else if (userDetails.firstName.length > 20) {
      newErrors.firstName = "First name cannot exceed 20 characters.";
    }

    // Last Name Validation
    if (!userDetails.lastName?.trim()) {
      newErrors.lastName = "Last name is required.";
    } else if (!/^[A-Za-z\s'-]+$/.test(userDetails.lastName)) {
      newErrors.lastName =
        "Last name should contain only letters, spaces, apostrophes, or hyphens.";
    } else if (userDetails.lastName.length > 20) {
      newErrors.lastName = "Last name cannot exceed 20 characters.";
    }

    // Phone Number Validation
    if (!userDetails.phoneNumber?.trim()) {
      newErrors.phoneNumber = "Phone number is required.";
    } else if (!/^[0-9]{7,15}$/.test(userDetails.phoneNumber)) {
      newErrors.phoneNumber =
        "Phone number should be between 7 and 15 digits.";
    }

    // Email Validation
    if (!userDetails.email?.trim()) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(userDetails.email)) {
      newErrors.email = "Invalid email format.";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      toast.error("Please fix the highlighted errors.");
    }

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form data
    if (!validate()) return;

    setIsSubmitting(true);

    try {
      const fullPhoneNumber = `${userDetails.countryCode}${userDetails.phoneNumber}`;

      // Prepare payload with concatenated phone number
      const payload = {
        ...userDetails,
        phoneNumber: fullPhoneNumber, // Replace with concatenated number
      };

      delete payload.countryCode; // Remove countryCode as it's now part of phoneNumber

      let response;
      if (customerId) {
        response = await api.updateCustomerAPI(userDetails, customerId,token); // Use customerId
      } else {
        response = await api.addCustomerAPI(userDetails,token);
      }

      if (response.status === 200 || response.status === 201) {
        toast.success(
          customerId
            ? "Customer updated successfully!"
            : "Customer added successfully!"
        );
        navigate("/customer");
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred while submitting the form.");
      }
      console.error("Error submitting the form:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleReset = () => {
    if (customerId) {
      // Reset to original user details fetched from API
      navigate("/customer");
    } else {
      // Reset form fields
      setUserDetails({
        id: editElement?.id || "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        countryCode: "+91",
        email: "",
        status: "enabled",
      });
      setErrors({});
    }
  };

  return (
    <>
      <Navbar />
      <div className="flex pt-16">
        <Sidebar currentSelection={{ main: "users", sub: "customer" }} />
        <div className="flex-1 bg-stone-200 p-6 overflow-y-auto h-screen">
          <div className="container mx-auto p-4">
            <div className="bg-stone-50 p-5 mb-8 rounded-xl mt-12">
              <Toaster />
              <div className="flex justify-between">
                <div className="text-xl py-2 my-2">
                  {customerId ? "Edit Customer" : "Add Customer"}
                </div>
                <button
                  type="button"
                  className="bg-transparent text-green-700 border border-green-700 rounded-lg p-2 h-10"
                  onClick={() => navigate("/customer")}
                >
                  Back
                </button>
              </div>

              {/* Error Messages Box */}
              {Object.keys(errors).length > 0 && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
                  <strong className="font-bold">
                    Please fix the following errors:
                  </strong>
                  <ul className="list-disc list-inside mt-2">
                    {Object.values(errors).map((error, index) => (
                      <li key={index}>{error}</li>
                    ))}
                  </ul>
                </div>
              )}

              <form onSubmit={handleSubmit} noValidate>
                <div className="grid grid-cols-3 gap-6 mt-4">
                  {/* First Name */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="firstName">First Name *</label>
                    <input
                      id="firstName"
                      type="text"
                      name="firstName"
                      placeholder="Enter first name"
                      value={userDetails.firstName}
                      onChange={handleChange}
                      className={`border rounded-xl p-2 ${
                        errors.firstName
                          ? "border-red-500"
                          : "border-gray-300"
                      }`}
                    />
                    {errors.firstName && (
                      <span className="text-red-500 text-sm">
                        {errors.firstName}
                      </span>
                    )}
                  </div>

                  {/* Last Name */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="lastName">Last Name *</label>
                    <input
                      id="lastName"
                      type="text"
                      name="lastName"
                      placeholder="Enter last name"
                      value={userDetails.lastName}
                      onChange={handleChange}
                      className={`border rounded-xl p-2 ${
                        errors.lastName
                          ? "border-red-500"
                          : "border-gray-300"
                      }`}
                    />
                    {errors.lastName && (
                      <span className="text-red-500 text-sm">
                        {errors.lastName}
                      </span>
                    )}
                  </div>

                  {/* Phone Number */}
                  <div className="flex flex-col text-gray-600 col-span-3">
                    <label htmlFor="phoneNumber">Phone Number *</label>
                    <div className="flex space-x-4 mt-1">
                      {/* Country Code Dropdown */}
                      <select
                        id="countryCode"
                        name="countryCode"
                        value={userDetails.countryCode || "+91"}
                        onChange={handleChange}
                        className={`border rounded-xl p-2 px-3 w-1/12 ${
                          errors.phoneNumber
                            ? "border-red-500"
                            : "border-gray-300"
                        }`}
                      >
                        <option value="+91">+91</option>
                        <option value="+1">+1</option>
                        <option value="+44">+44</option>
                        <option value="+61"> +61</option>
                        <option value="+971">+971</option>
                      </select>

                      {/* Phone Number Input */}
                      <input
                        id="phoneNumber"
                        type="tel"
                        name="phoneNumber"
                        placeholder="Enter Phone Number"
                        value={userDetails.phoneNumber}
                        onChange={(e) => {
                          if (/^\d*$/.test(e.target.value)) {
                            handleChange(e);
                          }
                        }}
                        className={`border rounded-xl p-2 w-2/5 ${
                          errors.phoneNumber
                            ? "border-red-500"
                            : "border-gray-300"
                        }`}
                        inputMode="numeric"
                        pattern="\d*"
                      />
                    </div>
                    {errors.phoneNumber && (
                      <span className="text-red-500 text-sm">
                        {errors.phoneNumber}
                      </span>
                    )}
                  </div>

                  {/* Email */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="email">Email *</label>
                    <input
                      id="email"
                      type="email"
                      name="email"
                      placeholder="Enter Email Address"
                      value={userDetails.email}
                      onChange={handleChange}
                      className={`border rounded-xl p-2 ${
                        errors.email ? "border-red-500" : "border-gray-300"
                      }`}
                    />
                    {errors.email && (
                      <span className="text-red-500 text-sm">
                        {errors.email}
                      </span>
                    )}
                  </div>

                  {/* Status */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="status">Status</label>
                    <select
                      id="status"
                      name="status"
                      value={userDetails.status}
                      onChange={handleChange}
                      className="border border-gray-300 rounded-xl p-2"
                    >
                      <option value="enabled">Enabled</option>
                      <option value="disabled">Disabled</option>
                    </select>
                  </div>
                </div>

                <div className="flex gap-2 mt-4">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className={`bg-transparent text-green-700 border border-green-700 rounded-lg p-2 h-10 ${
                      isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                  >
                    {isSubmitting
                      ? customerId
                        ? "Updating..."
                        : "Saving..."
                      : customerId
                      ? "Update"
                      : "Save"}
                  </button>
                  <button
                    type="button"
                    onClick={handleReset}
                    className="bg-transparent text-red-700 border border-red-700 rounded-lg p-2 h-10"
                  >
                    Reset
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddEditCustomer;
