import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import StatusBadge from "../../Components/StatusBadge";
import { MdEdit, MdDelete } from "react-icons/md";
import Pagination from "../../Components/Pagination";
import apis from "../../utils/API/customerApis"; // Import customer APIs
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from 'sonner';
import useAuthToken from "../../hooks/customHooks";

function CustomerList() {
  const navigate = useNavigate();
  const token = useAuthToken();

  // State for customers data
  const [customers, setCustomers] = useState([]);

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  // State for loading and error
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // State for search and filter inputs
  const [filterValues, setFilterValues] = useState({
    searchCriteria: "",
    status: "",
  });

  // State for applied search and filter payload
  const [searchPayload, setSearchPayload] = useState({
    searchCriteria: "",
    status: "",
  });

  const [refresh, setRefresh] = useState(false);


  // Fetch customers whenever currentPage, itemsPerPage, or searchPayload changes
  useEffect(() => {
    const fetchCustomers = async () => {
      setLoading(true);
      setError(null);
      try {
        const data = await apis.getAllCustomers(
          currentPage,
          itemsPerPage,
          searchPayload,
          token
        );
        setCustomers(data.data.customers);
        setTotalCustomers(data.data.totalCustomers);
        setTotalPages(data.data.totalPages);
      } catch (err) {
        setError("Failed to fetch customers.");
      } finally {
        setLoading(false);
      }
    };
    fetchCustomers();
  }, [currentPage, itemsPerPage, searchPayload, refresh]);

  const handleEdit = (customer) => {
    navigate(`/edit-customer/${customer.id}`,{ state: { customer } });
  };

  const handleAddCustomer = () => {
    navigate("/add-customer");
  };

  const handleDeleteCustomer = async (customerID) => {
    if (!customerID) return;
  
    const confirmDelete = window.confirm("Are you sure you want to delete this customer?");
    if (!confirmDelete) return;
  
    setLoading(true);
    setError(null);
  
    try {
      await apis.deleteCustomerAPI(customerID, currentPage, itemsPerPage);
      setRefresh((prev) => !prev); // Toggle refresh to re-trigger fetch
    } catch (err) {
      setError(err.message || "Failed to delete customer.");
    } finally {
      setLoading(false);
    }
  };
  
  
  
  // Handle change in items per page
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  // Handle page number click
  const handlePageClick = (pageNumber) => setCurrentPage(pageNumber);

  // Handle first, last, previous, and next page actions
  const handleFirstPage = () => setCurrentPage(1);
  const handleLastPage = () => setCurrentPage(totalPages);
  const handlePreviousPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));
  const handleNextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));

  // Handle search input change
  const handleSearchChange = (e) => setFilterValues((prev) => ({ ...prev, searchCriteria: e.target.value }));

  // Handle status filter change
  const handleStatusFilterChange = (e) => setFilterValues((prev) => ({ ...prev, status: e.target.value }));

  // Handle filter button click
  const handleFilter = () => {
    setSearchPayload(filterValues);
    setCurrentPage(1);
  };

  // Handle reset button click
  const handleReset = () => {
    setFilterValues({ searchCriteria: "", status: "" });
    setSearchPayload({ searchCriteria: "", status: "" });
    setCurrentPage(1);
  };

  return (
    <>
      <Navbar />
      <div className="flex pt-16">
        <Sidebar currentSelection={{ main: "users", sub: "customer" }} />
        <div className="flex-1 bg-stone-200 p-6 overflow-y-auto h-screen">
          <div className="container mx-auto p-4">
            <h1 className="text-2xl text-gray-600 font-bold mb-4">Customer List</h1>

            {/* Search and Filter Section */}
            <div className="bg-stone-50 p-5 mb-8 rounded-xl">
              <div className="flex space-x-16 items-center">
                <div className="flex flex-col text-gray-600">
                  <label htmlFor="search">Search</label>
                  <input
                    id="search"
                    type="text"
                    placeholder="Search by Name | Mobile | Email"
                    className="border border-gray-300 rounded p-2 w-96"
                    value={filterValues.searchCriteria}
                    onChange={handleSearchChange}
                  />
                </div>

                <div className="flex flex-col text-gray-600">
                  <label htmlFor="status">Status</label>
                  <select
                    id="status"
                    className="border border-gray-300 rounded p-2"
                    value={filterValues.status}
                    onChange={handleStatusFilterChange}
                  >
                    <option value="">-- Select --</option>
                    <option value="enabled">Enabled</option>
                    <option value="disabled">Disabled</option>
                  </select>
                </div>
              </div>

              <div className="flex gap-2 mt-4">
                <button
                  className="bg-transparent text-green-700 border border-green-700 rounded-lg p-2 h-10"
                  onClick={handleFilter}
                >
                  Filter
                </button>
                <button
                  className="bg-transparent text-red-700 border border-red-700 rounded-lg p-2 h-10"
                  onClick={handleReset}
                >
                  Reset
                </button>
              </div>
            </div>

            <div className="flex bg-stone-50 font-semibold justify-between text-gray-600 mb-0.5 p-3 rounded-t-xl">
              <div>Customer List</div>
              <div className="flex items-center space-x-2">
                <label htmlFor="itemsPerPage" className="text-sm">
                  Items per page:
                </label>
                <select
                  id="itemsPerPage"
                  className="border border-gray-300 text-sm rounded p-2"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                </select>
                <button
                  className="border border-emerald-900 text-sm font-normal rounded-md p-2 text-emerald-900"
                  onClick={handleAddCustomer}
                >
                  Add Customer
                </button>
              </div>
            </div>

            <div className="flex flex-col md:flex-row bg-stone-50 justify-between items-center text-sm mb-0.5 p-3 rounded-b-none rounded-t-none">
              <div className="mb-2 md:mb-0">
                <span>
                  Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
                  {Math.min(currentPage * itemsPerPage, totalCustomers)} of{" "}
                  {totalCustomers}
                </span>
              </div>

              {totalPages > 1 && (
                <div className="flex justify-center mt-4">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageClick}
                    onFirstPage={handleFirstPage}
                    onLastPage={handleLastPage}
                    onPreviousPage={handlePreviousPage}
                    onNextPage={handleNextPage}
                  />
                </div>
              )}
            </div>

            <div className="space-y-0">
              <div className="flex bg-stone-50 justify-center items-center font-bold text-sm mb-0.5 p-2 border-b border-gray-300">
                <div className="flex-1 text-left">ID</div>
                <div className="flex-1 text-left">Name</div>
                <div className="flex-1 text-center">Mobile</div>
                <div className="flex-1 basis-40 text-left">Email</div>
                <div className="flex-1 text-left">Status</div>
                <div className="flex-1 text-left">Created At</div>
                <div className="flex-1 text-right">Actions</div>
              </div>

              {loading ? (
                <div className="flex justify-center items-center p-4">
                  Loading customers...
                </div>
              ) : error ? (
                <div className="flex justify-center items-center p-4 text-red-500">
                  {error}
                </div>
              ) : Array.isArray(customers) && customers.length > 0 ? (
                customers.map((customer) => (
                  <div
                    key={customer.customerID}
                    className="flex bg-white p-2 border-b border-gray-300 transition-transform transform text-sm gap-4 hover:bg-gray-50"
                  >
                    <div className="flex-1 px-1 text-left">{customer.id}</div>
                    <div className="flex-1 overflow-hidden text-left">
                      {customer.firstName} {customer.lastName}
                    </div>
                    <div className="flex-1 text-center">{customer.phoneNumber}</div>
                    <div className="flex-1 basis-40 overflow-hidden text-left">
                      {customer.email}
                    </div>
                    <div className="flex-1 text-left">
                      <StatusBadge status={customer.status} />
                    </div>
                    <div className="flex-1 text-left">
                      {new Date(customer.createdAt).toLocaleDateString()}
                    </div>
                    <div className="flex-1 flex justify-end">
                      <button
                        className="bg-transparent text-green-700 border border-green-700 rounded-l-lg p-2 text-xs h-8 hover:bg-green-100"
                        onClick={() => handleEdit(customer)}
                      >
                        <MdEdit />
                      </button>
                      <button
                        className="bg-transparent text-red-700 border border-red-700 rounded-r-lg p-2 text-xs h-8 hover:bg-red-100"
                        onClick={() => handleDeleteCustomer(customer.id)}
                      >
                        <MdDelete />
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <div className="flex bg-white p-4 text-center text-gray-500">
                  No customers found.
                </div>
              )}
               <div className="flex flex-col md:flex-row bg-stone-50 justify-between items-center text-sm mb-0.5 p-3  rounded-b-xl">
              <div className="mb-2 md:mb-0">
                <span>
                  Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
                  {Math.min(currentPage * itemsPerPage, totalCustomers)} of{" "}
                  {totalCustomers}
                </span>
              </div>

              {totalPages > 1 && (
                <div className="flex justify-center mt-4">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageClick}
                    onFirstPage={handleFirstPage}
                    onLastPage={handleLastPage}
                    onPreviousPage={handlePreviousPage}
                    onNextPage={handleNextPage}
                  />
                </div>
              )}
            </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomerList;
