import React, { useState } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import coupons from "./couponData.js"; // Correct path to the coupon data
import { useNavigate } from "react-router-dom";

function AllCoupons() {
  const navigate = useNavigate();
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  console.log(Array.isArray(coupons));

  // Calculate the total number of pages
  const totalPages = Math.ceil(coupons.length / itemsPerPage);

  // Get the coupons for the current page
  const displayedCoupons = coupons.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  const handlePageChange = (direction) => {
    if (direction === "next" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    } else if (direction === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      <Navbar />
      <div className="flex pt-16">
        <Sidebar currentSelection={{ main: "coupons", sub: "allCoupons" }} />
        <div className="flex-1 bg-stone-200 p-6 overflow-y-auto">
          <div className="container mx-auto p-4">
            <h1 className="text-2xl text-gray-600 font-bold mb-4">All Coupons</h1>

            <div className="bg-stone-50 p-5 mb-8 rounded-xl">
              <div className="flex space-x-16 items-center">
                <div className="flex flex-col text-gray-600">
                  <label>Search</label>
                  <input
                    type="text"
                    placeholder="Search by Name | Code"
                    className="border border-gray-300 rounded p-2 w-96"
                  />
                </div>
                <div className="flex flex-col text-gray-600">
                  <label>Status</label>
                  <select className="border border-gray-300 rounded p-2">
                    <option>-- Select --</option>
                    <option>Active</option>
                    <option>Expired</option>
                    <option>Inactive</option>
                  </select>
                </div>
              </div>
              <div className="flex gap-2 mt-4">
                <button className="bg-transparent text-green-700 border border-green-700 rounded-lg p-2 h-10">
                  Filter
                </button>
                <button className="bg-transparent text-red-700 border border-red-700 rounded-lg p-2 h-10">
                  Reset
                </button>
              </div>
            </div>

            <div className="flex bg-stone-50 font-semibold text-gray-600 mb-0.5 p-3 rounded-t-xl">
              Coupons List
            </div>
            <div className="flex bg-stone-50 justify-between text-sm mb-0.5 p-3">
              Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
              {Math.min(currentPage * itemsPerPage, coupons.length)} of{" "}
              {coupons.length}
              <div className="flex space-x-2">
                <label htmlFor="itemsPerPage" className="mr-2">
                  Items per page:
                </label>
                <select
                  id="itemsPerPage"
                  className="border border-gray-300 rounded p-2"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
                <div>
                  <button
                    onClick={() => handlePageChange("prev")}
                    className="bg-gray-300 text-black rounded p-2 mr-1"
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  <button
                    onClick={() => handlePageChange("next")}
                    className="bg-gray-300 text-black rounded p-2"
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>

            {/* Coupons List Section */}
            <div className="space-y-0">
              {/* Header Row */}
              <div className="flex flex-1 bg-stone-50 justify-center items-center font-bold mb-0.5 p-2">
                <div className="flex-1">ID</div>
                <div className="flex-1">Name</div>
                <div className="flex-1">Code</div>
                <div className="flex-1 text-center">Discount Type</div>
                <div className="flex-1">Status</div>
                <div className="flex-1">Used</div>
                <div className="flex-1">Used Users</div>
                <div className="flex-1"></div> {/* Placeholder for buttons */}
              </div>

              {/* Coupons Cards */}
              {displayedCoupons.map((coupon) => (
                <div
                  key={coupon.id}
                  className="flex bg-stone-50 p-2 border-b border-gray-300 transition-transform transform text-sm gap-8 hover:bg-gray-50"
                >
                  <div className="flex-1">{coupon.id}</div>
                  <div className="flex-1 overflow-hidden">{coupon.name}</div>
                  <div className="flex-1">{coupon.code}</div>
                  <div className="flex-1 text-center">{coupon.discountType}</div>
                  <div className="flex-1">{coupon.status}</div>
                  <div className="flex-1">{coupon.used}</div>
                  <div className="flex-1">{coupon.usedUsers}</div>
                  <div className="flex-1 flex justify-end">
                    <button
                      className="bg-transparent text-green-700 border border-green-700 rounded p-1 text-xs h-8"
                      onClick={() => {
                        navigate("/edit-coupon/" + coupon.id); // Navigate to edit page
                      }}
                    >
                      Edit
                    </button>
                    <button className="bg-transparent text-red-700 border border-red-700 rounded p-1 text-xs h-8">
                      Delete
                    </button>
                  </div>
                </div>
              ))}
              <div className="flex bg-stone-50 text-sm mb-0.5 p-3 rounded-b-xl">
                Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
                {Math.min(currentPage * itemsPerPage, coupons.length)} of{" "}
                {coupons.length}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllCoupons;
