import React, { useState } from "react";
import Navbar from "../../Components/Navbar"; // Adjust this to the actual path
import Sidebar from "../../Components/Sidebar"; // Adjust this to the actual path

const SettingsForm = () => {
    const [settings, setSettings] = useState({
        driverWalletMaxLimit: {
            autoLoad: false,
            value: 1000,
        },
        referralAmount: {
            autoLoad: true,
            value: 20,
        },
        notifyDriver: {
            autoLoad: true,
            value: 30,
        },
        minDistance: {
            autoLoad: true,
            value: 30000,
        },
        vatPercentage: {
            autoLoad: true,
            value: 5,
        },
        rideBaseFare: {
            autoLoad: true,
            value: 20,
        },
        defaultVehicleID: {
            autoLoad: true,
            value: 2918,
        },
        testingUserIDs: {
            autoLoad: true,
            value: "",
        },
    });

    const handleCheckboxChange = (field) => {
        setSettings((prev) => ({
            ...prev,
            [field]: {
                ...prev[field],
                autoLoad: !prev[field].autoLoad,
            },
        }));
    };

    const handleInputChange = (field, value) => {
        setSettings((prev) => ({
            ...prev,
            [field]: {
                ...prev[field],
                value: value,
            },
        }));
    };

    return (
        <>
            <Navbar />
            <div className="flex pt-16">
                <Sidebar currentSelection={{main:"settingsform",sub:"null"}}/>
                <div className="flex-1 bg-gray-50 p-6 overflow-y-auto h-screen">
                    <h1 className="text-2xl font-semibold mb-6">Dashboard</h1>
                    <div className="bg-white rounded-lg shadow p-6">
                        <h2 className="text-xl font-semibold mb-4">All Rides</h2>
                        <div className="max-w-xl mx-auto p-6 bg-white shadow rounded-lg">
                            <div className="border-b mb-6 pb-6">
                                <h4 className="text-lg font-semibold mb-4">Global Settings</h4>

                                <div className="space-y-6">
                                    {/* Driver Wallet Max Limit */}
                                    <div className="space-y-2">
                                        <label className="block font-medium">Driver wallet max limit</label>
                                        <p className="text-sm text-gray-500">(max amount the driver can keep in his wallet)</p>
                                        <div className="flex items-center space-x-2">
                                            <input
                                                type="checkbox"
                                                checked={settings.driverWalletMaxLimit.autoLoad}
                                                onChange={() => handleCheckboxChange("driverWalletMaxLimit")}
                                                className="form-checkbox h-5 w-5"
                                            />
                                            <span>Auto Load</span>
                                        </div>
                                        <input
                                            type="text"
                                            value={settings.driverWalletMaxLimit.value}
                                            onChange={(e) => handleInputChange("driverWalletMaxLimit", e.target.value)}
                                            className="form-input w-full border-gray-300 rounded-md"
                                        />
                                    </div>

                                    {/* Referral Amount */}
                                    <div className="space-y-2">
                                        <label className="block font-medium">Referral amount</label>
                                        <p className="text-sm text-gray-500">(referral amount)</p>
                                        <div className="flex items-center space-x-2">
                                            <input
                                                type="checkbox"
                                                checked={settings.referralAmount.autoLoad}
                                                onChange={() => handleCheckboxChange("referralAmount")}
                                                className="form-checkbox h-5 w-5"
                                            />
                                            <span>Auto Load</span>
                                        </div>
                                        <input
                                            type="text"
                                            value={settings.referralAmount.value}
                                            onChange={(e) => handleInputChange("referralAmount", e.target.value)}
                                            className="form-input w-full border-gray-300 rounded-md"
                                        />
                                    </div>

                                    {/* Notify Driver */}
                                    <div className="space-y-2">
                                        <label className="block font-medium">Notify to driver for scheduled ride</label>
                                        <p className="text-sm text-gray-500">
                                            (time before which the driver is to be notified about the ride in minutes)
                                        </p>
                                        <div className="flex items-center space-x-2">
                                            <input
                                                type="checkbox"
                                                checked={settings.notifyDriver.autoLoad}
                                                onChange={() => handleCheckboxChange("notifyDriver")}
                                                className="form-checkbox h-5 w-5"
                                            />
                                            <span>Auto Load</span>
                                        </div>
                                        <input
                                            type="text"
                                            value={settings.notifyDriver.value}
                                            onChange={(e) => handleInputChange("notifyDriver", e.target.value)}
                                            className="form-input w-full border-gray-300 rounded-md"
                                        />
                                    </div>

                                    {/* Minimum Distance */}
                                    <div className="space-y-2">
                                        <label className="block font-medium">Minimum distance between customer and driver to start the ride</label>
                                        <p className="text-sm text-gray-500">(this is the minimum distance allowed for accepting the ride in meters)</p>
                                        <div className="flex items-center space-x-2">
                                            <input
                                                type="checkbox"
                                                checked={settings.minDistance.autoLoad}
                                                onChange={() => handleCheckboxChange("minDistance")}
                                                className="form-checkbox h-5 w-5"
                                            />
                                            <span>Auto Load</span>
                                        </div>
                                        <input
                                            type="text"
                                            value={settings.minDistance.value}
                                            onChange={(e) => handleInputChange("minDistance", e.target.value)}
                                            className="form-input w-full border-gray-300 rounded-md"
                                        />
                                    </div>

                                    {/* VAT Percentage */}
                                    <div className="space-y-2">
                                        <label className="block font-medium">VAT percentage</label>
                                        <p className="text-sm text-gray-500">(write in %)</p>
                                        <div className="flex items-center space-x-2">
                                            <input
                                                type="checkbox"
                                                checked={settings.vatPercentage.autoLoad}
                                                onChange={() => handleCheckboxChange("vatPercentage")}
                                                className="form-checkbox h-5 w-5"
                                            />
                                            <span>Auto Load</span>
                                        </div>
                                        <input
                                            type="text"
                                            value={settings.vatPercentage.value}
                                            onChange={(e) => handleInputChange("vatPercentage", e.target.value)}
                                            className="form-input w-full border-gray-300 rounded-md"
                                        />
                                    </div>

                                    {/* Ride Base Fare */}
                                    <div className="space-y-2">
                                        <label className="block font-medium">Ride base fare</label>
                                        <p className="text-sm text-gray-500">(write in numbers)</p>
                                        <div className="flex items-center space-x-2">
                                            <input
                                                type="checkbox"
                                                checked={settings.rideBaseFare.autoLoad}
                                                onChange={() => handleCheckboxChange("rideBaseFare")}
                                                className="form-checkbox h-5 w-5"
                                            />
                                            <span>Auto Load</span>
                                        </div>
                                        <input
                                            type="text"
                                            value={settings.rideBaseFare.value}
                                            onChange={(e) => handleInputChange("rideBaseFare", e.target.value)}
                                            className="form-input w-full border-gray-300 rounded-md"
                                        />
                                    </div>

                                    {/* Default Vehicle ID */}
                                    <div className="space-y-2">
                                        <label className="block font-medium">Default Vehicle ID</label>
                                        <p className="text-sm text-gray-500">(Enter the default vehicle ID)</p>
                                        <div className="flex items-center space-x-2">
                                            <input
                                                type="checkbox"
                                                checked={settings.defaultVehicleID.autoLoad}
                                                onChange={() => handleCheckboxChange("defaultVehicleID")}
                                                className="form-checkbox h-5 w-5"
                                            />
                                            <span>Auto Load</span>
                                        </div>
                                        <input
                                            type="text"
                                            value={settings.defaultVehicleID.value}
                                            onChange={(e) => handleInputChange("defaultVehicleID", e.target.value)}
                                            className="form-input w-full border-gray-300 rounded-md"
                                        />
                                    </div>

                                    {/* Testing User IDs */}
                                    <div className="space-y-2">
                                        <label className="block font-medium">Testing User IDs</label>
                                        <p className="text-sm text-gray-500">(Enter the IDs of the testing users)</p>
                                        <div className="flex items-center space-x-2">
                                            <input
                                                type="checkbox"
                                                checked={settings.testingUserIDs.autoLoad}
                                                onChange={() => handleCheckboxChange("testingUserIDs")}
                                                className="form-checkbox h-5 w-5"
                                            />
                                            <span>Auto Load</span>
                                        </div>
                                        <input
                                            type="text"
                                            value={settings.testingUserIDs.value}
                                            onChange={(e) => handleInputChange("testingUserIDs", e.target.value)}
                                            className="form-input w-full border-gray-300 rounded-md"
                                        />
                                    </div>
                                    <button className="bg-green-600 text-gray-50 py-2 px-4 rounded">
              Save Changes
            </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SettingsForm;
