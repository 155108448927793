import {
  ADD_BRAND,
  ADD_COLORS,
  ADD_MODEL,
  ADD_VEHICLE,
  ADD_VEHICLE_CATEGORY,
  ADMIN_ROUTE,
  BASE_URL,
  DELETE_BRAND,
  DELETE_COLOR,
  DELETE_MODEL,
  DELETE_VEHICLE,
  DELETE_VEHICLE_CATEGORY,
  EDIT_BRAND,
  EDIT_COLOR,
  EDIT_MODEL,
  EDIT_VEHICLE,
  EDIT_VEHICLE_CATEGORY,
  GET_ALL_VEHICLE_CATEGORIES,
  GET_BRANDS,
  GET_COLORS,
  GET_MODELS,
  GET_UNALLOCATED_DRIVERS,
  GET_VEHICLES,
  VEHICLE_ROUTE,
} from "./apiEndPoint";
import {
  apiPostFetcher,
  apiGetFetcher,
  apiPutFetcher,
  apiDeleteFetcher,
} from "./apiFetcher";

const vehicleUrl = `${BASE_URL}${ADMIN_ROUTE}${VEHICLE_ROUTE}`;
const adminUrl = `${BASE_URL}${ADMIN_ROUTE}`;

/**
 * Add a  POST requests.
 * @param {Object} payload - The data to be sent in the request body.
 * @param {String} token - Bearer Token.
 * @returns {Promise} The response data.
 */
const addBrandAPI = async (payload,token) => {
  let url = `${vehicleUrl}${ADD_BRAND}`;
  return apiPostFetcher(url, payload,token);
};

/**
 * PUT API to edit vehicle.
 * @param {Object} payload - The data to be sent in the request body.
 * @param {String} brandId - The brandId to be edited
 * @param {String} token - Bearer Token.
 * @returns {Promise} The response data.
 */
const editBrandAPI = async (payload, brandId,token) => {
  let url = `${vehicleUrl}${EDIT_BRAND}/${brandId}`;
  return apiPutFetcher(url, payload,token);
};

/**
 * To get all brands also supports search
 * @param {Number} page - Page to be retreived
 * @param {Number} items -  Items per page
 * @param {Object} payload - Search by brand name and status.
 * @param {String} token - Bearer Token.
 */
const getBrandsAPI = async (page, items, payload,token) => {
  let url = `${vehicleUrl}${GET_BRANDS}/${page}/${items}`;
  return apiPostFetcher(url, payload,token);
};

/**
 * Deletes a brand by ID.
 * @param {string} brandId - The ID of the brand to delete.
 * @param {string} page - The current page number.
 * @param {string} items - Max items in a page
 * @param {String} token - Bearer Token.
 * @returns {Object} - Promise resolving to the deletion result.
 */
const deleteBrandAPI = async (brandId, page, items,token) => {
  const url = `${vehicleUrl}${DELETE_BRAND}/${brandId}/${page}/${items}`;
  return apiDeleteFetcher(url,token);
};

/**
 * To get all brands also supports search
 * @param {Number} page - Current Page
 * @param {Number} items -  Items per page
 * @param {Object} payload - Search by brand name and status.
 * @param {String} token - Bearer Token.
 */
const getModelsAPI = async (page, items, payload,token) => {
  let url = `${vehicleUrl}${GET_MODELS}/${page}/${items}`;
  return apiPostFetcher(url, payload,token);
};

/**
 * Add a  POST requests.
 * @param {Object} payload - The data to be sent in the request body.
 * @param {String} token - Bearer Token.
 * @returns {Promise} The response data.
 */
const addModelAPI = async (payload,token) => {
  let url = `${vehicleUrl}${ADD_MODEL}`;
  return apiPostFetcher(url, payload,token);
};

/**
 * PUT API to edit vehicle.
 * @param {Object} payload - The data to be sent in the request body.
 * @param {String} token - Bearer Token.
 * @returns {Promise} The response data.
 */
const editModelAPI = async (payload, modelId,token) => {
  let url = `${vehicleUrl}${EDIT_MODEL}/${modelId}`;
  return apiPutFetcher(url, payload,token);
};

/**
 * Deletes a model by ID.
 * @param {string} modelId - The ID of the model to delete.
 * @param {string} page - The current page number.
 * @param {string} items - Max items in a page
 * @param {String} token - Bearer Token.
 * @returns {Object} - Promise resolving to the deletion result.
 */
const deleteModelAPI = async (modelId, page, items,token) => {
  const url = `${vehicleUrl}${DELETE_MODEL}/${modelId}/${page}/${items}`;
  return apiDeleteFetcher(url,token);
};

/**
 * To get all brands also supports search
 * @param {Number} page - Page to retrieve.
 * @param {Number} items -  Items per page
 * @param {Object} payload - Search by brand name and status.
 * @param {String} token - Bearer Token.
 */
const getColorsAPI = async (page, items, payload,token) => {
  let url = `${vehicleUrl}${GET_COLORS}/${page}/${items}`;
  return apiPostFetcher(url, payload,token);
};

/**
 * Add a  POST requests.
 * @param {Object} payload - The data to be sent in the request body.
 * @param {String} token - Bearer Token.
 * @returns {Promise} The response data.
 */
const addColorAPI = async (payload,token) => {
  let url = `${vehicleUrl}${ADD_COLORS}`;
  return apiPostFetcher(url, payload,token);
};

/**
 * PUT API to edit vehicle.
 * @param {Object} body - The data to be sent in the request body.
 * @param {String} token - Bearer Token.
 * @returns {Promise} The response data.
 */
const editColorAPI = async (payload, colorId,token) => {
  let url = `${vehicleUrl}${EDIT_COLOR}/${colorId}`;
  return apiPutFetcher(url, payload,token);
};

/**
 * Deletes a color by ID.
 * @param {string} colorId - The ID of the color to delete.
 * @param {string} page - The current page number.
 * @param {string} items - Max items in a page.
 * @param {String} token - Bearer Token.
 * @returns {Object} - Promise resolving to the deletion result.
 */
const deleteColorAPI = async (colorId, page, items,token) => {
  const url = `${vehicleUrl}${DELETE_COLOR}/${colorId}/${page}/${items}`;
  return apiDeleteFetcher(url,token);
};

/**
 * To get all brands also supports search
 * @param {Number} page - Current Page
 * @param {Number} items -  Items per page
 * @param {Object} payload - Search by brand name and status.
 */
const getVehiclesAPI = async (page, items, payload,token) => {
  let url = `${vehicleUrl}${GET_VEHICLES}/${page}/${items}`;
  return apiPostFetcher(url, payload,token);
};

/**
 * Add a  POST requests.
 * @param {Object} payload - The data to be sent in the request body.
 * @param {String} token - Bearer token.
 * @returns {Promise} The response data.
 */
const addVehicleAPI = async (payload,token) => {
  let url = `${vehicleUrl}${ADD_VEHICLE}`;
  return apiPostFetcher(url, payload,token);
};

/**
 * PUT API to edit vehicle.
 * @param {Object} payload - The data to be sent in the request body.
 * @param {String} vehicleId - The vehicle that has to be edited.
 * @param {String} token - Bearer token.
 * @returns {Promise} The response data.
 */
const editVehicleAPI = async (payload, vehicleId,token) => {
  let url = `${vehicleUrl}${EDIT_VEHICLE}/${vehicleId}`;
  return apiPutFetcher(url, payload,token);
};

/**
 * Deletes a vehicle by ID.
 * @param {string} vehicleId - The ID of the vehicle to delete.
 * @param {string} page - The current page number.
 * @param {string} items - Max items in a page
 * @returns {Object} - Promise resolving to the deletion result.
 */
const deleteVehicleAPI = async (vehicleId, page, items,token) => {
  const url = `${vehicleUrl}${DELETE_VEHICLE}/${vehicleId}/${page}/${items}`;
  return apiDeleteFetcher(url,token);
};

/**
 * To get all brands also supports search
 * @param {String} token - Current Page
 */
const getUnallocatedDriversAPI = async (token) => {
  let url = `${BASE_URL}${ADMIN_ROUTE}${GET_UNALLOCATED_DRIVERS}`;
  return apiGetFetcher(url,token);
};

/**
 * To get all vehicle categories also supports search
 * @param {Number} page - Page to be retreived
 * @param {Number} items -  Items per page
 * @param {Object} payload - Search by brand name and status.
 * @param {String} token - Bearer Token.
 */
const getVehiclesCategoryAPI = async (page, items, payload,token) => {
  let url = `${adminUrl}${GET_ALL_VEHICLE_CATEGORIES}/${page}/${items}`;
  return apiPostFetcher(url, payload,token);
};

/**
 * Deletes a vehicle category by ID.
 * @param {string} vehicleCategoryId - The ID of the vehicle category to delete.
 * @returns {Object} - Promise resolving to the deletion result.
 */
const deleteVehicleCategoryAPI = async (vehicleCategoryId,currentPage,items,token) => {
  const url = `${adminUrl}${DELETE_VEHICLE_CATEGORY}/${vehicleCategoryId}/${currentPage}/${items}`;
  return apiDeleteFetcher(url,token);
};

/**
 * Add a Vehicle category.
 * @param {Object} payload - The data to be sent in the request body.
 * @param {String} token - Bearer Token.
 * @returns {Promise} The response data.
 */
const addVehicleCategoryAPI = async (payload,token) => {
  let url = `${adminUrl}${ADD_VEHICLE_CATEGORY}`;
  return apiPostFetcher(url, payload,token);
};

/**
 * PUT API to edit vehicle category.
 * @param {Object} payload - The data to be sent in the request body.
 * @param {String} vehicleCategoryId - The vehicle category id that has to be edited.
 * @param {String} token - Bearer token.
 * @returns {Promise} The response data.
 */
const editVehicleCategoryAPI = async (payload, vehicleCategoryId,token) => {
  let url = `${adminUrl}${EDIT_VEHICLE_CATEGORY}/${vehicleCategoryId}`;
  return apiPutFetcher(url, payload,token);
};



const vehicleAPI = {
  addBrandAPI,
  getBrandsAPI,
  getModelsAPI,
  addModelAPI,
  getColorsAPI,
  addColorAPI,
  getVehiclesAPI,
  addVehicleAPI,
  editVehicleAPI,
  getUnallocatedDriversAPI,
  editBrandAPI,
  editModelAPI,
  editColorAPI,
  deleteBrandAPI,
  deleteColorAPI,
  deleteModelAPI,
  deleteVehicleAPI,
  getVehiclesCategoryAPI,
  editVehicleCategoryAPI,
  addVehicleCategoryAPI,
  deleteVehicleCategoryAPI
};

export default vehicleAPI;
