import driverData from "./driverData"
import { cashData } from "./cashData";
import {pageData} from "./pageData";

const fakeApiFetch = (data, page = 1, limit = 10, entityName) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        try {
          const totalItems = data.length;
          const totalPages = Math.ceil(totalItems / limit) || 1;
          const currentPage = Math.min(Math.max(1, page), totalPages);
          const startIndex = (currentPage - 1) * limit;
          const endIndex = startIndex + limit;
          const paginatedData = data.slice(startIndex, endIndex);
  
          resolve({
            [entityName]: paginatedData,
            totalPages,
            totalItems,
            currentPage,
          });
        } catch (error) {
          reject(`An error occurred while fetching ${entityName}.`);
        }
      }, 500);
    });
  };
  

  export const fakeApiFetchAdmins = (params) => fakeApiFetch(driverData, params.page, params.limit, 'admins');
  export const fakeApiCashDetails = (params) => fakeApiFetch(cashData,params.page,params.limit,'cash');
  export const fakeApiPageDetails = (params) => fakeApiFetch(pageData,params.page,params.limit,'page');
  