// src/Pages/Vehicles/VehiclesList.jsx

import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import { MdEdit, MdDelete } from "react-icons/md";
import StatusBadge from "../../Components/StatusBadge";
import Pagination from "../../Components/Pagination";
import { useNavigate } from "react-router-dom";
import vehicleAPI from "../../utils/API/vehicleApis";
import useAuthToken from "../../hooks/customHooks";
import { toast } from "sonner"; // Import Sonner for toast notifications
import ConfirmModal from "../../Components/ConfirmModal"; // Import ConfirmModal

function VehiclesList() {
  const navigate = useNavigate();
  const token = useAuthToken();

  // State for filters
  const [filters, setFilters] = useState({
    searchString: "",
    vehicleStatus: "",
  });

  // State for pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  // State for vehicles data, total pages, and total items
  const [vehicles, setVehicles] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0); // Added totalItems

  // State for loading and error
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // State for confirmation modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [vehicleToDelete, setVehicleToDelete] = useState(null);

  /**
   * Fetches vehicles from the API based on current filters and pagination.
   */
  const fetchVehicles = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await vehicleAPI.getVehiclesAPI(
        currentPage,
        itemsPerPage,
        filters,
        token
      );

      setVehicles(response.data.items || []);
      setTotalPages(response.data.totalPages || 1);
      setTotalItems(response.data.totalItems || 0); // Set total items
    } catch (err) {
      const errorMessage =
        err.response?.data?.message ||
        err.message ||
        "An error occurred while fetching vehicles.";
      setError(errorMessage);
      toast.error("Failed to fetch vehicles. Please try again."); // Show error toast
    } finally {
      setLoading(false);
    }
  };

  /**
   * Fetch vehicles whenever currentPage, itemsPerPage, or filters change.
   */
  useEffect(() => {
    fetchVehicles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage, filters]);

  /**
   * Handles change in items per page.
   * @param {object} e - Event object.
   */
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  /**
   * Handles input changes for filters.
   * @param {object} e - Event object.
   */
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  /**
   * Handles page number click.
   * @param {number} pageNumber - The page number to navigate to.
   */
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  /**
   * Handles navigation to the first page.
   */
  const handleFirstPage = () => setCurrentPage(1);

  /**
   * Handles navigation to the last page.
   */
  const handleLastPage = () => setCurrentPage(totalPages);

  /**
   * Handles navigation to the previous page.
   */
  const handlePreviousPage = () =>
    setCurrentPage((prev) => Math.max(prev - 1, 1));

  /**
   * Handles navigation to the next page.
   */
  const handleNextPage = () =>
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));

  /**
   * Handles Filter button click.
   */
  const handleFilterClick = () => {
    setCurrentPage(1); // Reset to first page when filters are applied
    fetchVehicles();
  };

  /**
   * Handles Reset button click.
   */
  const handleResetClick = () => {
    setFilters({
      searchString: "",
      vehicleStatus: "",
    });
    setCurrentPage(1);
    fetchVehicles(); // Fetch all vehicles without filters
  };

  /**
   * Handles Edit button click.
   * @param {string} vehicleId - The ID of the vehicle to edit.
   * @param {object} vehicle - The vehicle data.
   */
  const handleEdit = (vehicleId, vehicle) => {
    navigate(`/vehicles/edit-vehicle/${vehicleId}`, { state: { vehicle } });
  };

  /**
   * Opens the confirmation modal to delete a vehicle.
   * @param {object} vehicle - The vehicle object to delete.
   */
  const handleDeleteClick = (vehicle) => {
    setVehicleToDelete(vehicle);
    setIsModalOpen(true);
  };

  /**
   * Confirms the deletion of the vehicle.
   */
  const confirmDeleteVehicle = async () => {
    if (!vehicleToDelete) return;

    setIsModalOpen(false);
    setLoading(true);
    setError(null);

    try {
      await vehicleAPI.deleteVehicleAPI(
        vehicleToDelete.vehicleId,
        currentPage,
        itemsPerPage,
        token
      );
      toast.success("Vehicle deleted successfully!"); // Show success toast
      await fetchVehicles(); // Refetch data to update the list
    } catch (err) {
      const errorMessage =
        err.response?.data?.message || err.message || "Failed to delete vehicle.";
      setError(errorMessage);
      toast.error("Failed to delete vehicle. Please try again."); // Show error toast
      // Revert to previous state in case of error
      setVehicles((prevVehicles) =>
        [...prevVehicles, vehicleToDelete].sort((a, b) => a.vehicleId - b.vehicleId)
      );
      setTotalItems((prevTotal) => prevTotal + 1);
      setTotalPages(Math.ceil(totalItems / itemsPerPage));
    } finally {
      setLoading(false);
      setVehicleToDelete(null);
    }
  };

  /**
   * Cancels the deletion and closes the modal.
   */
  const cancelDelete = () => {
    setIsModalOpen(false);
    setVehicleToDelete(null);
  };

  return (
    <>
      <Navbar />
      <div className="flex pt-16">
        {/* Sidebar with current selection */}
        <Sidebar currentSelection={{ main: "vehicles", sub: "allVehicles" }} />
        <div className="flex-1 bg-stone-200 p-6 overflow-y-auto">
          <div className="container mx-auto p-4">
            {/* Heading */}
            <h1 className="text-2xl text-gray-600 font-bold mb-4">
              Vehicles List
            </h1>

            {/* Search and Filter Section */}
            <div className="bg-stone-50 p-5 mb-8 rounded-xl">
              <div className="flex flex-col md:flex-row space-x-0 md:space-x-16 space-y-4 md:space-y-0 items-start md:items-center">
                {/* Search Input */}
                <div className="flex flex-col text-gray-600">
                  <label htmlFor="searchString">Search</label>
                  <input
                    id="searchString"
                    type="text"
                    name="searchString" // Updated name
                    placeholder="Search by Brand | Model | Year"
                    value={filters.searchString}
                    onChange={handleFilterChange}
                    className="border border-gray-300 rounded p-2 w-full md:w-96"
                  />
                </div>

                {/* Status Filter */}
                <div className="flex flex-col text-gray-600">
                  <label htmlFor="vehicleStatus">Status</label>
                  <select
                    id="vehicleStatus"
                    name="vehicleStatus" // Updated name
                    value={filters.vehicleStatus}
                    onChange={handleFilterChange}
                    className="border border-gray-300 rounded p-2 w-full md:w-auto"
                  >
                    <option value="">-- Select --</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                    {/* Add more status options as needed */}
                  </select>
                </div>
              </div>

              {/* Filter and Reset Buttons */}
              <div className="flex gap-2 mt-4">
                <button
                  type="button"
                  className="bg-transparent text-green-700 border border-green-700 rounded-lg p-2 h-10"
                  onClick={handleFilterClick}
                >
                  Filter
                </button>
                <button
                  type="button"
                  className="bg-transparent text-red-700 border border-red-700 rounded-lg p-2 h-10"
                  onClick={handleResetClick}
                >
                  Reset
                </button>
              </div>
            </div>

            {/* Vehicles List Header */}
            <div className="flex bg-stone-50 font-semibold justify-between text-gray-600 mb-0.5 p-3 rounded-t-xl">
              <div>Vehicle List</div>
              {/* Items Per Page Selector */}
              <div className="flex items-center space-x-2">
                <label htmlFor="itemsPerPage" className="text-sm">
                  Items per page:
                </label>
                <select
                  id="itemsPerPage"
                  className="border border-gray-300 text-sm rounded p-2"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                </select>
                <button
                  type="button"
                  className="border border-emerald-900 text-sm font-normal rounded-md p-2 text-emerald-900"
                  onClick={() => {
                    navigate("/vehicles/add-vehicle");
                  }}
                >
                  + Add New Vehicle
                </button>
              </div>
            </div>

            {/* Pagination and Items Information */}
            <div className="flex flex-col md:flex-row bg-stone-50 justify-between items-center text-sm mb-0.5 p-3 rounded-b-none rounded-t-none">
              {/* Showing X to Y of Z */}
              <div className="mb-2 md:mb-0">
                {loading ? (
                  <span>Loading...</span>
                ) : (
                  <span>
                    Showing{" "}
                    {totalItems === 0
                      ? 0
                      : (currentPage - 1) * itemsPerPage + 1}{" "}
                    to {Math.min(currentPage * itemsPerPage, totalItems)} of{" "}
                    {totalItems}
                  </span>
                )}
              </div>

              {/* Pagination Component */}
              {totalPages > 1 && (
                <div className="flex justify-center mt-4">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageClick}
                    onFirstPage={handleFirstPage}
                    onLastPage={handleLastPage}
                    onPreviousPage={handlePreviousPage}
                    onNextPage={handleNextPage}
                  />
                </div>
              )}
            </div>

            {/* Vehicles List Section */}
            <div className="space-y-0">
              {/* Header Row */}
              <div className="flex bg-stone-50 justify-center items-center font-bold text-sm mb-0.5 p-2 border-b border-gray-300">
                <div className="flex-1 text-left">ID</div>
                <div className="flex-1 text-left">Brand Name</div>
                <div className="flex-1 text-left">Model</div>
                <div className="flex-1 text-left">Color</div>
                <div className="flex-1 text-left">Status</div>
                <div className="flex-1 text-right">Actions</div>
              </div>

              {/* Loading and Error States */}
              {loading ? (
                <div className="flex justify-center p-4 text-gray-500">
                  Loading...
                </div>
              ) : error ? (
                <div className="flex bg-white p-4 text-center text-gray-500">
                  {error}
                </div>
              ) : vehicles.length > 0 ? (
                vehicles.map((vehicle) => (
                  <div
                    key={vehicle.vehicleId}
                    className="flex bg-white p-2 border-b border-gray-300 transition-transform transform text-sm gap-4 hover:bg-gray-50"
                  >
                    <div className="flex-1 px-1 text-left">
                      {vehicle.vehicleId}
                    </div>
                    <div
                      className="flex-1 overflow-hidden text-left"
                      style={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                      }}
                    >
                      {vehicle.vehicleMake}
                    </div>
                    <div className="flex-1 mr-4 text-left">
                      {vehicle.vehicleModel}
                      <em className="text-xs">({vehicle.vehicleYear})</em>
                    </div>
                    <div className="flex-1 mr-4 text-left">
                      <span
                        className={`px-2 py-1 rounded-full text-white text-xs`}
                        style={{
                          backgroundColor: vehicle.color.toLowerCase()==='white'?'grey':vehicle.color.toLowerCase(),
                        }}
                      >
                        {vehicle.color}
                      </span>
                    </div>
                    <div className="flex-1 mr-4 text-left">
                      <StatusBadge status={vehicle.status} />
                    </div>

                    <div className="flex-1 flex justify-end space-x-2">
                      <button
                        className="bg-transparent text-green-700 border border-green-700 rounded-lg p-2 text-xs h-8 hover:bg-green-100"
                        aria-label={`Edit vehicle ${vehicle.vehicleId}`}
                        onClick={() => handleEdit(vehicle.vehicleId, vehicle)}
                      >
                        <MdEdit />
                      </button>
                      <button
                        className="bg-transparent text-red-700 border border-red-700 rounded-lg p-2 text-xs h-8 hover:bg-red-100"
                        aria-label={`Delete vehicle ${vehicle.vehicleId}`}
                        onClick={() => handleDeleteClick(vehicle)} // Attach delete handler
                        disabled={loading} // Disable while loading
                      >
                        <MdDelete />
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <div className="flex bg-white p-4 text-center text-gray-500">
                  No vehicles found.
                </div>
              )}

              {/* Footer Showing X to Y of Z */}
              <div className="flex bg-stone-50 justify-between items-center text-sm mb-0.5 p-3 rounded-b-xl">
                <div>
                  Showing{" "}
                  {totalItems === 0 ? 0 : (currentPage - 1) * itemsPerPage + 1}{" "}
                  to {Math.min(currentPage * itemsPerPage, totalItems)} of{" "}
                  {totalItems}
                </div>

                {/* Pagination Component at Bottom */}
                {totalPages > 1 && (
                  <div className="flex justify-center mt-4">
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageClick}
                      onFirstPage={handleFirstPage}
                      onLastPage={handleLastPage}
                      onPreviousPage={handlePreviousPage}
                      onNextPage={handleNextPage}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Confirmation Modal for Deletion */}
      <ConfirmModal
        isOpen={isModalOpen}
        title="Confirm Deletion"
        message={`Are you sure you want to delete this vehicle? This action cannot be undone.`}
        onConfirm={confirmDeleteVehicle}
        onCancel={cancelDelete}
      />

      {/* Remove the fixed error display and rely on toasts */}
      {/* {error && (
        <div className="fixed bottom-4 right-4 bg-red-500 text-white p-3 rounded">
          {error}
        </div>
      )} */}
    </>
  );
}

export default VehiclesList;
