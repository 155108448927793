import { ADMIN_ROUTE, ASSIGN_VEHICLE, BASE_URL, DEASSIGN_VEHICLE, GET_All_ASSIGNMENTS, GET_ASSIGNED_VEHICLES, GET_UNASSIGNED_VEHICLES, REASSIGN_VEHICLE, VEHICLE_ASSIGN_ROUTE } from "./apiEndPoint"
import { apiGetFetcher, apiPostFetcher, apiPutFetcher } from "./apiFetcher";


const vehicleAssign = `${BASE_URL}${ADMIN_ROUTE}${VEHICLE_ASSIGN_ROUTE}`
/**
 * To assign a vehicle to a driver.
 * @param {Object} payload - Search by brand name and status.
 * @param {String} token - Bearer token.
 */
const assignApi = async (payload,token) => {
    const url = `${vehicleAssign}${ASSIGN_VEHICLE}`;
    return apiPostFetcher(url,payload,token);
}


/**
 * To reassign a vehicle to a driver.
 * @param {Object} payload - Search by brand name and status.
 * @param {String} token - Bearer token.
 */
const reassignApi = async (payload,token) => {
    const url = `${vehicleAssign}${REASSIGN_VEHICLE}`;
    return apiPostFetcher(url,payload,token);
}

/**
 * To deassign a vehicle to a driver.
 * @param {Object} payload - Search by brand name and status.
 * @param {String} token - Bearer token.
 */
const deassignApi = async (payload,token) => {
    const url = `${vehicleAssign}${DEASSIGN_VEHICLE}`;
    return apiPostFetcher(url,payload,token);
}

/**
 * To all vehicle assignment list.
 * @param {Object} payload - Search by brand name and status.
 * @param {String} token - Bearer token.
 */
const getAllAssignments = async (page,items,payload,token) => {
    const url = `${vehicleAssign}${GET_All_ASSIGNMENTS}/${page}/${items}`;
    return apiPostFetcher(url,payload,token);
}

/**
 * To all vehicle assignment list.
 * @param {Object} payload - Search by brand name and status.
 * @param {String} token - Bearer token.
 */
const getAllUnassignedVehicles = async (token) => {
    const url = `${vehicleAssign}${GET_UNASSIGNED_VEHICLES}`;
    return apiGetFetcher(url,token);
}

const assignmentApis = {
    getAllAssignments,
    assignApi,
    reassignApi,
    deassignApi,
    getAllUnassignedVehicles
}

export default assignmentApis;




