// src/Components/RescheduledRidesTable.jsx

import React from "react";
import RidesTable from "./RidesTable";

export default function RescheduledRidesTable({ rides }) {
  const headers = [
    "Ride ID",
    "Customer ID",
    "Pickup",
    "Drop",
    "Vehicle Type",
    "Distance (km)",
    "Fare",
    "Driver ID",
    "Rescheduled Date & Time",
    "Status",
  ];

  return (
    <RidesTable
      headers={headers}
      rides={rides}
      type="rescheduled"
    />
  );
}
