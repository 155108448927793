// src/pages/Vehicles/AddEditModel.jsx

import { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import vehicleAPI from "../../utils/API/vehicleApis";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useAuthToken from "../../hooks/customHooks";
import { toast } from "sonner";

function AddEditModel() {
  const navigate = useNavigate();
  const token = useAuthToken();
  const location = useLocation();
  const { modelId } = useParams(); // If modelId is present, we're editing
  const editModel = location.state?.model;

  const [modelDetails, setModelDetails] = useState({
    modelName: "",
    brandMasterId: "",
    vehicleType: "",
    vehicleCategory: "",
    color: "",
    year: "",
    seatCapacity: "",
    status: "",
  });

  const [errors, setErrors] = useState({});
  const [availableBrands, setAvailableBrands] = useState([]);
  const [availableVehicleCategories, setAvailableVehicleCategories] = useState(
    []
  );
  const [availableColors, setAvailableColors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);

  // Fetch available brands for the brand dropdown
  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await vehicleAPI.getBrandsAPI(1, 100, {}, token); // Adjust pagination as needed
        setAvailableBrands(response.data.items || []);
      } catch (err) {
        console.error("Failed to fetch brands:", err);
        alert("Failed to load brands. Please try again.");
      }
    };
    fetchBrands();
  }, []);

  // Fetch available colors for the color dropdown
  useEffect(() => {
    const fetchColors = async () => {
      try {
        const response = await vehicleAPI.getColorsAPI(1, 100, {}, token);
        setAvailableColors(response.data.items || []);
      } catch (err) {
        console.error("Failed to fetch colors:", err);
        alert("Failed to load colors. Please try again.");
      }
    };
    fetchColors();
  }, []);

  // Fetch available vehicle categories
  useEffect(() => {
    const fetchVehicleCategories = async () => {
      setLoadingCategories(true);
      try {
        const response = await vehicleAPI.getVehiclesCategoryAPI(
          1,
          100,
          {},
          token
        ); // Ensure this API exists
        setAvailableVehicleCategories(response.data.paginatedData|| []);
      } catch (err) {
        console.error("Failed to fetch vehicle categories:", err);
        alert("Failed to load vehicle categories. Please try again.");
      } finally {
        setLoadingCategories(false);
      }
    };
    fetchVehicleCategories();
  }, []);

  useEffect(() => {
    if (modelId && editModel) {
      console.log("Edit Model: ", editModel);
      setModelDetails({
        modelName: editModel.modelName,
        brandName: editModel.brandName,
        brandMasterId: editModel.brandMasterId,
        vehicleType: editModel.vehicleType,
        vehicleCategory: editModel.vehicleCategory,
        color: editModel.color,
        year: editModel.year,
        seatCapacity: editModel.seatCapacity,
        status: editModel.status,
      });
    }
  }, [modelId, editModel]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setModelDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
    console.log(modelDetails);
  };

  // Validation function
  const validate = () => {
    const newErrors = {};

    // Model Name: Required and must be a string
    if (!modelDetails.modelName.trim()) {
      newErrors.modelName = "Model name is required.";
    }else if (modelDetails.modelName.length > 20) {
      newErrors.modelName = "Model Name cannot exceed 20 characters.";
      toast.error("Model Name cannot exceed 20 characters.");
    }

    // // Brand Name: Required
    // if (!modelDetails.brandName) {
    //   newErrors.brandName = "Please select a brand.";
    // }

    // Vehicle Type: Required
    if (!modelDetails.vehicleType) {
      newErrors.vehicleType = "Please select a vehicle type.";
    }

    // Vehicle Category: Required
    if (!modelDetails.vehicleCategory) {
      newErrors.vehicleCategory = "Please select a vehicle category.";
    }

    // Color: Required
    if (!modelDetails.color.trim()) {
      newErrors.color = "Color is required.";
    }

    // Year: Required and must be a valid year
    if (!modelDetails.year.toString().trim()) {
      newErrors.year = "Year is required.";
    } else if (!/^\d{4}$/.test(modelDetails.year.toString().trim())) {
      newErrors.year = "Please enter a valid four-digit year.";
    }

    // Seating Capacity: Required and must be a positive integer
    if (!modelDetails.seatCapacity.toString().trim()) {
      newErrors.seatCapacity = "Seating capacity is required.";
    } else if (!/^\d+$/.test(modelDetails.seatCapacity.toString().trim())) {
      newErrors.seatCapacity = "Please enter a valid number.";
    }

    // Status: Must be either "draft" or "published"
    if (!["draft", "published"].includes(modelDetails.status)) {
      newErrors.status = "Please select a valid status.";
    }

    setErrors(newErrors);

    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform validation
    if (!validate()) {
      // If validation fails, do not proceed
      return;
    }

    try {
      let response;
      if (modelId) {
        // Editing existing model
        response = await vehicleAPI.editModelAPI(modelDetails, modelId, token);
      } else {
        // Adding new model
        response = await vehicleAPI.addModelAPI(modelDetails, token);
        console.log(response);
      }

      if (response.status === 200 || response.status === 201) {
        toast.success(`Model ${modelId ? "updated" : "added"} successfully!`);
        // Optionally, navigate away after successful submission
        navigate("/vehicles/model");
      } else {
        console.log("Something went wrong. Please try again.");
        // Attempt to extract error message from response
        const errorMessage =
          response.data?.error || "Something went wrong. Please try again.";
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      // Extract error message from the error object
      const errorMessage =
        error.response?.data?.error ||
        error.message ||
        "An error occurred while submitting the form.";
      alert(errorMessage);
    }
  };

  // Handle form reset
  const handleReset = () => {
    if (modelId) {
      // If editing, navigate back to the models list to prevent accidental data loss
      navigate("/vehicles/model");
    } else {
      // If adding, clear the form
      setModelDetails({
        modelName: "",
        brandMasterId: "",
        vehicleType: "",
        vehicleCategory: "",
        color: "",
        year: "",
        seatCapacity: "",
        status: "",
      });
      setErrors({});
    }
  };

  return (
    <>
      <Navbar />
      <div className="flex pt-16">
        <Sidebar currentSelection={{ main: "vehicles", sub: "model" }} />
        <div className="flex-1 bg-stone-200 p-6 overflow-y-auto">
          <div className="container mx-auto p-4">
            <div className="bg-stone-50 p-5 mb-8 rounded-xl mt-12">
              <form onSubmit={handleSubmit}>
                <div className="flex justify-between">
                  <div className="text-xl py-2 my-2">
                    {modelId ? "Edit Model" : "Add New Model"}
                  </div>
                  <div>
                    <button
                      type="button"
                      className="bg-transparent text-green-700 border border-green-700 rounded-lg p-2 h-10"
                      onClick={() => {
                        navigate("/vehicles/model");
                      }}
                    >
                      Back
                    </button>
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-6 mt-4">
                  {/* Model Name */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="modelName">Model Name *</label>
                    <input
                      type="text"
                      id="modelName"
                      name="modelName"
                      placeholder="Enter model name"
                      value={modelDetails.modelName}
                      onChange={handleChange}
                      required
                      className={`border rounded-xl p-2 ${
                        errors.modelName ? "border-red-500" : "border-gray-300"
                      }`}
                      aria-invalid={errors.modelName ? "true" : "false"}
                    />
                    {errors.modelName && (
                      <span className="text-red-500 text-sm">
                        {errors.modelName}
                      </span>
                    )}
                  </div>

                  {/* Brand */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="brandName">Brand *</label>
                    {/* Brand Dropdown */}
                    <select
                      id="brandMasterId"
                      name="brandMasterId"
                      value={modelDetails.brandMasterId}
                      onChange={handleChange}
                      required
                      className={`border rounded-xl p-2 ${
                        errors.brandName ? "border-red-500" : "border-gray-300"
                      }`}
                      aria-invalid={errors.brandName ? "true" : "false"}
                    >
                      <option value="">Select Brand</option>
                      {availableBrands?.map(
                        (brand) => (
                          console.log("Brands: ", brand),
                          (
                            <option
                              key={brand.brandId}
                              value={brand.brandMasterId}
                            >
                              {brand.brandName}
                            </option>
                          )
                        )
                      )}
                    </select>
                    {errors.brandName && (
                      <span className="text-red-500 text-sm">
                        {errors.brandName}
                      </span>
                    )}
                  </div>

                  {/* Vehicle Type */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="vehicleType">Vehicle Type *</label>
                    <input
                      type="text"
                      id="vehicleType"
                      name="vehicleType"
                      placeholder="Enter vehicle type"
                      value={modelDetails.vehicleType}
                      onChange={handleChange}
                      required
                      className={`border rounded-xl p-2 ${
                        errors.vehicleType
                          ? "border-red-500"
                          : "border-gray-300"
                      }`}
                      aria-invalid={errors.vehicleType ? "true" : "false"}
                    />
                    {errors.vehicleType && (
                      <span className="text-red-500 text-sm">
                        {errors.vehicleType}
                      </span>
                    )}
                  </div>

                  {/* Vehicle Category */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="vehicleCategory">Vehicle Category *</label>
                    <select
                      id="vehicleCategory"
                      name="vehicleCategory"
                      placeholder="Enter vehicle vehicle category"
                      value={modelDetails.vehicleCategory}
                      onChange={handleChange}
                      required
                      className={`border rounded-xl p-2 ${
                        errors.vehicleCategory
                          ? "border-red-500"
                          : "border-gray-300"
                      }`}
                      aria-invalid={errors.vehicleCategory ? "true" : "false"}
                    >
                      <option value="">Select Category</option>
                      {availableVehicleCategories?.map((vehicle) => (
                        <option key={vehicle.id} value={vehicle.category_name}>
                          {vehicle.category_name}
                        </option>
                      ))}
                    </select>
                    {errors.vehicleCategory && (
                      <span className="text-red-500 text-sm">
                        {errors.vehicleCategory}
                      </span>
                    )}
                  </div>

                  {/* Color */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="color">Color *</label>
                    <select
                      id="color"
                      name="color"
                      value={modelDetails.color}
                      onChange={handleChange}
                      required
                      className={`border rounded-xl p-2 ${
                        errors.color ? "border-red-500" : "border-gray-300"
                      }`}
                      aria-invalid={errors.color ? "true" : "false"}
                    >
                      <option value="">Select Color</option>
                      {availableColors?.map((color) => (
                        <option key={color.id} value={color.color}>
                          {color.color}
                        </option>
                      ))}
                    </select>
                    {errors.color && (
                      <span className="text-red-500 text-sm">
                        {errors.color}
                      </span>
                    )}
                  </div>

                  {/* Year */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="year">Year *</label>
                    <input
                      type="number"
                      id="year"
                      name="year"
                      placeholder="Enter year (e.g., 2023)"
                      value={modelDetails.year}
                      onChange={handleChange}
                      required
                      min="1900"
                      max={new Date().getFullYear() + 1} // Allowing next year
                      className={`border rounded-xl p-2 ${
                        errors.year ? "border-red-500" : "border-gray-300"
                      }`}
                      aria-invalid={errors.year ? "true" : "false"}
                    />
                    {errors.year && (
                      <span className="text-red-500 text-sm">
                        {errors.year}
                      </span>
                    )}
                  </div>

                  {/* Seating Capacity */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="seatCapacity">Seating Capacity *</label>
                    <input
                      type="number"
                      id="seatCapacity"
                      name="seatCapacity"
                      placeholder="Enter seating capacity"
                      value={modelDetails.seatCapacity}
                      onChange={handleChange}
                      required
                      min="1"
                      className={`border rounded-xl p-2 ${
                        errors.seatCapacity
                          ? "border-red-500"
                          : "border-gray-300"
                      }`}
                      aria-invalid={errors.seatCapacity ? "true" : "false"}
                    />
                    {errors.seatCapacity && (
                      <span className="text-red-500 text-sm">
                        {errors.seatCapacity}
                      </span>
                    )}
                  </div>

                  {/* Status */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="status">Status *</label>
                    <select
                      id="status"
                      name="status"
                      value={modelDetails.status}
                      onChange={handleChange}
                      required
                      className={`border rounded-xl p-2 ${
                        errors.status ? "border-red-500" : "border-gray-300"
                      }`}
                      aria-invalid={errors.status ? "true" : "false"}
                    >
                      <option value="">Select Status</option>
                      <option value="draft">Draft</option>
                      <option value="published">Published</option>
                    </select>
                    {errors.status && (
                      <span className="text-red-500 text-sm">
                        {errors.status}
                      </span>
                    )}
                  </div>
                </div>

                <div className="flex gap-2 mt-4">
                  <button
                    type="submit"
                    className="bg-transparent text-green-700 border border-green-700 rounded-lg p-2 h-10"
                    disabled={loading}
                  >
                    {loading ? "Saving..." : "Save"}
                  </button>
                  <button
                    type="button"
                    className="bg-transparent text-red-700 border border-red-700 rounded-lg p-2 h-10"
                    onClick={handleReset}
                  >
                    {modelId ? "Cancel" : "Reset"}
                  </button>
                </div>
              </form>
              {loading && (
                <div className="flex justify-center p-4 text-gray-500">
                  Loading...
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddEditModel;
