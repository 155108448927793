// couponData.js

const coupons = [
    {
      id: 570,
      name: "flat60",
      code: "FLAT60",
      discountType: "Fixed",
      status: "Enabled",
      used: 0,
      usedUsers: 0, // Assuming this represents the number of unique users
    },
    {
      id: 571,
      name: "seasonalSale",
      code: "SEASONAL20",
      discountType: "Fixed",
      status: "Enabled",
      used: 5,
      usedUsers: 5,
    },
    {
      id: 572,
      name: "newYearOffer",
      code: "NY2024",
      discountType: "Fixed",
      status: "Disabled",
      used: 2,
      usedUsers: 2,
    },
    {
      id: 573,
      name: "blackFridayDeal",
      code: "BF2024",
      discountType: "Fixed",
      status: "Enabled",
      used: 10,
      usedUsers: 10,
    },
    {
      id: 574,
      name: "holidayDiscount",
      code: "HOLIDAY25",
      discountType: "Fixed",
      status: "Enabled",
      used: 0,
      usedUsers: 0,
    },
  ];
  
  export default coupons;
  