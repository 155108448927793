// src/components/Dashboard.jsx

import React, { useState, useEffect } from 'react';
import Navbar from "../Components/Navbar"; // Adjust this to the actual path
import Sidebar from "../Components/Sidebar"; // Adjust this to the actual path
import { Line } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import dashboardAPI from '../utils/API/dashboardApis';
import useAuthToken from '../hooks/customHooks';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner'; // Ensure Sonner is properly configured
import useLogout from '../hooks/useLogout'; // Custom hook for logout

// Register Chart.js components
Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

function Dashboard() {
  const navigate = useNavigate();
  const performLogout = useLogout();
  const token = useAuthToken(); // Custom hook to retrieve the auth token

  // States for dynamic data
  const [dashboardStats, setDashboardStats] = useState({
    totalRevenue: 0,
    dailyRevenue: 0,
    monthlyRevenue: 0,
    yearlyRevenue: 0,
    totalRides: 0,
    totalAccepted: 0,
    totalStarted: 0,
    totalScheduled: 0
  });

  const [latestRides, setLatestRides] = useState([]);
  const [topDrivers, setTopDrivers] = useState([]);
  const [topCustomers, setTopCustomers] = useState([]);
  const [ridesGraph, setRidesGraph] = useState([]);

  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Rides',
        data: [],
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        pointBorderColor: 'rgba(75, 192, 192, 1)',
        pointBackgroundColor: 'rgba(75, 192, 192, 1)',
        fill: true,
      },
    ],
  });

  const [chartOptions, setChartOptions] = useState({
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: true,
        text: 'Rides Graph',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Number of Rides',
        },
      },
    },
  });

  // Loading and error states
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchDashboardData = async () => {
    try {
      setLoading(true);
      setError(null);

      // Fetch dashboard data from the API
      const response = await dashboardAPI.getDashboardAPI(token);

      if (response && response.data) {
        const data = response.data;

        // Update dashboardStats
        setDashboardStats({
          totalRevenue: data.totalRevenue,
          dailyRevenue: data.dailyRevenue,
          monthlyRevenue: data.monthlyRevenue,
          yearlyRevenue: data.yearlyRevenue,
          totalRides: data.totalRides,
          totalAccepted: data.totalAccepted,
          totalStarted: data.totalStarted,
          totalScheduled: data.totalScheduled
        });

        // Update latestRides
        setLatestRides(data.latestRides);

        // Update topDrivers
        setTopDrivers(data.topDrivers);

        // Update topCustomers
        setTopCustomers(data.topCustomers);

        // Update ridesGraph and chartData
        setRidesGraph(data.ridesGraph);
        const labels = data.ridesGraph.map(item => item.date);
        const rideCounts = data.ridesGraph.map(item => item.rideCount);

        setChartData(prevChartData => ({
          ...prevChartData,
          labels: labels,
          datasets: [
            {
              ...prevChartData.datasets[0],
              data: rideCounts
            }
          ]
        }));
      } else {
        setError('Invalid API response');
      }

    } catch (err) {
      console.error('Error fetching dashboard data:', err);
      setError('Failed to fetch dashboard data');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      fetchDashboardData();
    }
  }, [token]); // Re-fetch data if token changes

  useEffect(() => {
    // Push a new state to manage back navigation
    window.history.pushState(null, null, window.location.href);

    const handlePopState = () => {
      // Prompt the user for logout confirmation
      const confirmLogout = window.confirm("Do you want to logout?");
      if (confirmLogout) {
        performLogout().catch((error) => {
          console.error("Logout failed:", error);
          toast.error("Logout failed. Please try again.");
        });
      } else {
        // Re-push the current state to prevent navigating back
        window.history.pushState(null, null, window.location.href);
      }
    };

    // Add the event listener for popstate
    window.addEventListener('popstate', handlePopState);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [performLogout]);

  return (
    <>
      <Navbar />
      <div className="flex pt-16 h-[92vh]">
        <Sidebar currentSelection={{ main: "dashboard", sub: "null" }} />
        {/* Main content area */}
        <div className="flex-1 bg-gray-50 p-6 overflow-y-auto h-screen">
          <h1 className="text-xl font-semibold p-6">Dashboard</h1>

          {loading ? (
            <div className="text-center">Loading...</div>
          ) : error ? (
            <div className="text-center text-red-500">{error}</div>
          ) : (
            <>
              {/* Stats Section */}
              <div className="bg-gray-100 rounded-lg shadow p-6">
                <div className="h-[60vh] flex">
                  {/* Left Side - Stats Section */}
                  <div className="w-2/5 grid grid-cols-2 gap-4">
                    {/* Revenue and Ride Stats */}
                    {Object.entries(dashboardStats).map(([key, value], index) => (
                      <div key={index} className="bg-white p-2 rounded-lg shadow-md text-center min-h-[100px] flex flex-col justify-center">
                        <h3 className="text-sm font-medium text-gray-600">
                          {key
                            .replace(/([A-Z])/g, ' $1')
                            .replace(/^./, str => str.toUpperCase())
                            .trim()}
                        </h3>
                        <p className="text-base font-bold">
                          {typeof value === 'number' ? value.toLocaleString() : value}
                        </p>
                      </div>
                    ))}
                  </div>

                  {/* Right Side - Latest Rides Section */}
                  <div className="w-3/5 ml-4">
                    {/* Ride List */}
                    <div className="bg-white p-4 rounded-lg shadow-md overflow-y-auto" style={{
                       height: "60vh"
                      }}>
                      <div className="flex justify-between items-center mb-4">
                        <h2 className="text-xl font-bold">Latest Rides</h2>
                        <div className="flex space-x-4">
                          <button
                            onClick={fetchDashboardData}
                            className="bg-blue-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-600"
                          >
                            Refresh
                          </button>
                          <button className="bg-green-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-green-600">
                            View All Rides
                          </button>
                        </div>
                      </div>

                      {/* Ride Items */}
                      {latestRides.length === 0 ? (
                        <p className="text-center text-gray-500">No rides available.</p>
                      ) : (
                        latestRides.map((ride) => (
                          <div key={ride.tripId} className="flex items-center py-2 border-b border-gray-200">
                            <div className="w-10 h-10 rounded-full bg-blue-200 flex items-center justify-center">
                              {/* Placeholder for user's avatar */}
                              <span className="text-lg font-bold text-blue-600">👤</span>
                            </div>
                            <div className="ml-4 flex-1">
                              <p className="font-medium">Trip ID: {ride.tripId}</p>
                              <p className="text-sm text-gray-500">Customer ID: {ride.customerId}</p>
                              <p className="text-sm text-gray-500">Driver ID: {ride.driverId}</p>
                              <p className="text-sm text-gray-500">Fare: ${ride.fare.toFixed(2)}</p>
                              <p className="text-sm text-gray-500">Date: {new Date(ride.createdAt).toLocaleString()}</p>
                            </div>
                            <p className={`ml-4 text-sm font-medium ${ride.status === 'completed' ? 'text-green-500' : ride.status === 'initiated' ? 'text-yellow-500' : 'text-red-500'}`}>
                              {ride.status.charAt(0).toUpperCase() + ride.status.slice(1)}
                            </p>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>

                {/* NEW SECTION: Chart and Tables */}
                <div className="bg-white p-6 rounded-lg shadow-md mt-10">
                  {/* Chart.js Rides Graph */}
                  <Line data={chartData} options={chartOptions} />

                  {/* Top Drivers and Customers Tables */}
                  <div className="grid grid-cols-2 gap-6 mt-6">
                    {/* Top Drivers */}
                    <div className="bg-white p-6 rounded-lg shadow-md">
                      <h2 className="text-lg font-bold mb-4">Top Drivers</h2>
                      {topDrivers.length === 0 ? (
                        <p className="text-center text-gray-500">No top drivers available.</p>
                      ) : (
                        <table className="min-w-full bg-white">
                          <thead>
                            <tr>
                              <th className="text-left py-2 px-4 border-b">Driver ID</th>
                              <th className="text-left py-2 px-4 border-b">Completed Trips</th>
                            </tr>
                          </thead>
                          <tbody>
                            {topDrivers.map((driver, index) => (
                              <tr key={index}>
                                <td className="py-2 px-4 border-b">{driver.driverId}</td>
                                <td className="py-2 px-4 border-b">{driver.completedTrips}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>

                    {/* Top Customers */}
                    <div className="bg-white p-6 rounded-lg shadow-md">
                      <h2 className="text-lg font-bold mb-4">Top Customers</h2>
                      {topCustomers.length === 0 ? (
                        <p className="text-center text-gray-500">No top customers available.</p>
                      ) : (
                        <table className="min-w-full bg-white">
                          <thead>
                            <tr>
                              <th className="text-left py-2 px-4 border-b">Customer ID</th>
                              <th className="text-left py-2 px-4 border-b">Total Spent ($)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {topCustomers.map((customer, index) => (
                              <tr key={index}>
                                <td className="py-2 px-4 border-b">{customer.customerId}</td>
                                <td className="py-2 px-4 border-b">{customer.totalSpent.toFixed(2)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Dashboard;
