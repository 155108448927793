// src/Pages/Vehicles/AssignList.jsx

import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import StatusBadge from "../../Components/StatusBadge";
import Pagination from "../../Components/Pagination";
import assignmentApis from "../../utils/API/assignmentApis";
import vehicleAPI from "../../utils/API/vehicleApis";
import useAuthToken from "../../hooks/customHooks";
import { toast } from "sonner";
import ConfirmModal from "../../Components/ConfirmModal"; // Import ConfirmModal

function AssignList() {
  const token = useAuthToken();

  // State for filters
  const [filters, setFilters] = useState({
    searchString: "",
    assignmentStatus: "",
  });

  // State for pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  // State for assignments data, total pages, and total items
  const [assignments, setAssignments] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  // State for drivers and vehicles
  const [drivers, setDrivers] = useState([]);
  const [vehicles, setVehicles] = useState([]);

  // State for assignment modal
  const [isAssignmentModalOpen, setIsAssignmentModalOpen] = useState(false);
  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [selectedDriver, setSelectedDriver] = useState(null); // For new assignments

  // State for unassign confirmation modal
  const [isUnassignModalOpen, setIsUnassignModalOpen] = useState(false);
  const [assignmentToUnassign, setAssignmentToUnassign] = useState(null);

  // State for loading and error
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  /**
   * Fetches assignments, drivers, and vehicles from the API.
   */
  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      // Fetch assignments based on current filters and pagination
      const assignmentsResponse = await assignmentApis.getAllAssignments(
        currentPage,
        itemsPerPage,
        filters,
        token
      );

      setAssignments(assignmentsResponse.data.items || []);
      setTotalPages(assignmentsResponse.data.totalPages || 1);
      setTotalItems(assignmentsResponse.data.totalItems || 0);

      // Fetch all unallocated drivers
      const driversResponse = await vehicleAPI.getUnallocatedDriversAPI(token);
      setDrivers(driversResponse.data.unassigned || []);

      // Fetch all unassigned vehicles
      const vehiclesResponse = await assignmentApis.getAllUnassignedVehicles(
        token
      );
      setVehicles(vehiclesResponse.data.unassigned || []);
    } catch (err) {
      const errorMessage =
        err.response?.data?.message ||
        err.message ||
        "An error occurred while fetching data.";
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Fetch data whenever currentPage, itemsPerPage, or filters change.
   */
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage, filters]);

  /**
   * Handles change in items per page.
   * @param {object} e - Event object.
   */
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  /**
   * Handles input changes for filters.
   * @param {object} e - Event object.
   */
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  /**
   * Handles page number click.
   * @param {number} pageNumber - The page number to navigate to.
   */
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  /**
   * Handles navigation to the first page.
   */
  const handleFirstPage = () => setCurrentPage(1);

  /**
   * Handles navigation to the last page.
   */
  const handleLastPage = () => setCurrentPage(totalPages);

  /**
   * Handles navigation to the previous page.
   */
  const handlePreviousPage = () =>
    setCurrentPage((prev) => Math.max(prev - 1, 1));

  /**
   * Handles navigation to the next page.
   */
  const handleNextPage = () =>
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));

  /**
   * Handles Filter button click.
   */
  const handleFilterClick = () => {
    setCurrentPage(1); // Reset to first page when filters are applied
    fetchData();
  };

  /**
   * Handles Reset button click.
   */
  const handleResetClick = () => {
    setFilters({
      searchString: "",
      assignmentStatus: "",
    });
    setCurrentPage(1);
    fetchData(); // Fetch all assignments without filters
  };

  /**
   * Handles Re-Assign button click.
   * @param {object} assignment - The assignment data.
   */
  const handleReAssignClick = (assignment) => {
    setSelectedAssignment(assignment);
    setIsAssignmentModalOpen(true);
  };

  /**
   * Handles Add Assignment button click.
   */
  const handleAddAssignmentClick = () => {
    setSelectedAssignment(null); // Clear selection for new assignment
    setSelectedVehicle("");
    setSelectedDriver(null);
    setIsAssignmentModalOpen(true);
  };

  /**
   * Handles Unassign button click.
   * @param {object} assignment - The assignment data.
   */
  const handleUnassignVehicle = (assignment) => {
    setAssignmentToUnassign(assignment);
    setIsUnassignModalOpen(true);
  };

  /**
   * Confirms the unassignment.
   */
  const confirmUnassignVehicle = async () => {
    if (!assignmentToUnassign) return;

    setLoading(true);
    setError(null);
    try {
      const payload = {
        driverID: assignmentToUnassign.driverID || "",
      };
      await assignmentApis.deassignApi(payload, token);
      toast.success("Vehicle unassigned successfully!");
      handleCloseUnassignModal();
      fetchData();
    } catch (err) {
      const errorMessage =
        err.response?.data?.message ||
        err.message ||
        "Failed to unassign vehicle.";
      toast.error(errorMessage);
    } finally {
      setLoading(false);
      handleCloseUnassignModal();
    }
  };

  /**
   * Closes the unassign confirmation modal.
   */
  const handleCloseUnassignModal = () => {
    setIsUnassignModalOpen(false);
    setAssignmentToUnassign(null);
  };

  /**
   * Closes the assignment modal.
   */
  const handleCloseAssignmentModal = () => {
    setIsAssignmentModalOpen(false);
    setSelectedAssignment(null);
    setSelectedVehicle("");
    setSelectedDriver(null);
  };

  /**
   * Confirms reassignment or new assignment.
   */
  const handleConfirmAssignment = async () => {
    if (!selectedVehicle) {
      toast.error("Please select a vehicle.");
      return;
    }

    if (!selectedAssignment && !selectedDriver) {
      toast.error("Please select a driver.");
      return;
    }

    setLoading(true);
    setError(null);
    try {
      if (selectedAssignment) {
        const reassignPayload = {
          driverID: selectedAssignment.driverID || "",
          vehicleId: selectedVehicle,
        };
        // Re-assign existing driver to a new vehicle
        await assignmentApis.reassignApi(reassignPayload, token);
        toast.success("Vehicle reassigned successfully!");
      } else {
        const assignPayload = {
          driverID: selectedDriver.id,
          vehicleId: selectedVehicle,
        };
        // Assign a new driver to a vehicle
        await assignmentApis.assignApi(assignPayload, token);
        toast.success("Vehicle assigned successfully!");
      }
      handleCloseAssignmentModal();
      fetchData(); // Refetch data to reflect changes
    } catch (err) {
      const errorMessage =
        err.response?.data?.message ||
        err.message ||
        "Failed to assign vehicle.";
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  // Determine if the Assign/Re-Assign button should be disabled
  const isAssignButtonDisabled =
    loading ||
    !selectedVehicle ||
    (!selectedAssignment && (!selectedDriver || !selectedDriver.id));

  return (
    <>
      <Navbar />
      <div className="flex pt-16">
        <Sidebar currentSelection={{ main: "assignments", sub:null}} />
        <div className="flex-1 bg-stone-200 p-6 overflow-y-auto">
          <div className="container mx-auto p-4">
            {/* Heading */}
            <h1 className="text-2xl text-gray-600 font-bold mb-4">
              Assigned List
            </h1>

            {/* Search and Filter Section */}
            <div className="bg-stone-50 p-5 mb-8 rounded-xl">
              <div className="flex flex-col md:flex-row space-x-0 md:space-x-16 space-y-4 md:space-y-0 items-start md:items-center">
                {/* Search Input */}
                <div className="flex flex-col text-gray-600">
                  <label htmlFor="searchString">Search</label>
                  <input
                    id="searchString"
                    type="text"
                    name="searchString"
                    placeholder="Search by Driver | Vehicle | Register Number"
                    value={filters.searchString}
                    onChange={handleFilterChange}
                    className="border border-gray-300 rounded p-2 w-96"
                  />
                </div>

                {/* Status Filter */}
                <div className="flex flex-col text-gray-600">
                  <label htmlFor="assignmentStatus">Status</label>
                  <select
                    id="assignmentStatus"
                    name="assignmentStatus"
                    value={filters.assignmentStatus}
                    onChange={handleFilterChange}
                    className="border border-gray-300 rounded p-2"
                  >
                    <option value="">-- Select --</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                    {/* Add more status options as needed */}
                  </select>
                </div>
              </div>

              {/* Filter and Reset Buttons */}
              <div className="flex gap-2 mt-4">
                <button
                  type="button"
                  className="bg-transparent text-green-700 border border-green-700 rounded-lg p-2 h-10"
                  onClick={handleFilterClick}
                >
                  Filter
                </button>
                <button
                  type="button"
                  className="bg-transparent text-red-700 border border-red-700 rounded-lg p-2 h-10"
                  onClick={handleResetClick}
                >
                  Reset
                </button>
              </div>
            </div>

            {/* Assignments List Header */}
            <div className="flex bg-stone-50 font-semibold justify-between text-gray-600 mb-0.5 p-3 rounded-t-xl">
              <div>Assigned List</div>
              {/* Items Per Page Selector */}
              <div className="flex items-center space-x-2">
                <label htmlFor="itemsPerPage" className="text-sm">
                  Items per page:
                </label>
                <select
                  id="itemsPerPage"
                  className="border border-gray-300 text-sm rounded p-2"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                </select>
                <button
                  type="button"
                  className="border border-emerald-900 text-sm font-normal rounded-md p-2 text-emerald-900"
                  onClick={handleAddAssignmentClick}
                >
                  + Add New Assignment
                </button>
              </div>
            </div>

            {/* Pagination and Items Information */}
            <div className="flex flex-col md:flex-row bg-stone-50 justify-between items-center text-sm mb-0.5 p-3 rounded-b-none rounded-t-none">
              {/* Showing X to Y of Z */}
              <div className="mb-2 md:mb-0">
                {loading ? (
                  <span>Loading...</span>
                ) : (
                  <span>
                    Showing{" "}
                    {totalItems === 0
                      ? 0
                      : (currentPage - 1) * itemsPerPage + 1}{" "}
                    to {Math.min(currentPage * itemsPerPage, totalItems)} of{" "}
                    {totalItems}
                  </span>
                )}
              </div>

              {/* Pagination Component */}
              {totalPages > 1 && (
                <div className="flex justify-center mt-4">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageClick}
                    onFirstPage={handleFirstPage}
                    onLastPage={handleLastPage}
                    onPreviousPage={handlePreviousPage}
                    onNextPage={handleNextPage}
                  />
                </div>
              )}
            </div>

            {/* Assignments List Section */}
            <div className="space-y-0">
              {/* Header Row */}
              <div className="flex bg-stone-50 justify-center items-center font-bold text-sm mb-0.5 p-2 border-b border-gray-300">
                <div className="flex-1 text-left">ID</div>
                <div className="flex-1 text-left">Driver Name</div>
                <div className="flex-1 text-left">Vehicle</div>
                <div className="flex-1 text-left">Register Number</div>
                <div className="flex-1 text-left">Status</div>
                <div className="flex-1 text-right">Actions</div>
              </div>

              {/* Loading and Error States */}
              {loading ? (
                <div className="flex justify-center p-4 text-gray-500">
                  Loading...
                </div>
              ) : error ? (
                <div className="flex justify-center p-4 text-red-500">
                  {error}
                </div>
              ) : assignments.length > 0 ? (
                assignments.map((assignment,index) => (
                  <div
                    key={assignment.id} // Use unique assignment id
                    className="flex bg-white p-2 border-b border-gray-300 transition-transform transform text-sm gap-4 hover:bg-gray-50"
                  >
                    <div className="flex-1 text-left">{index+1}</div>
                    <div className="flex-1 text-left">
                      {assignment.driverName}
                    </div>
                    <div className="flex-1 text-left">
                      {assignment.vehicleMake} {assignment.vehicleModel} (
                      {assignment.vehicleYear})
                    </div>
                    <div className="flex-1 text-left">
                      {assignment.vehicleRegistration}
                    </div>
                    <div className="flex-1 text-left">
                      <StatusBadge status={assignment.status} />
                    </div>
                    <div className="flex-1 flex justify-end space-x-2">
                      <button
                        className="bg-transparent text-blue-700 border border-blue-700 rounded-lg p-2 text-xs hover:bg-blue-100"
                        onClick={() => handleReAssignClick(assignment)}
                        aria-label={`Re-assign vehicle for ${assignment.driverName}`}
                      >
                        Re-Assign
                      </button>
                      <button
                        className="bg-transparent text-red-700 border border-red-700 rounded-lg p-2 text-xs hover:bg-red-100"
                        onClick={() => handleUnassignVehicle(assignment)}
                        aria-label={`Unassign vehicle from ${assignment.driverName}`}
                        disabled={loading}
                      >
                        Unassign
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <div className="flex bg-white p-4 text-center text-gray-500">
                  No assignments found.
                </div>
              )}

              {/* Footer Showing X to Y of Z */}
              <div className="flex bg-stone-50 justify-between items-center text-sm mb-0.5 p-3 rounded-b-xl">
                <div>
                  Showing{" "}
                  {totalItems === 0
                    ? 0
                    : (currentPage - 1) * itemsPerPage + 1}{" "}
                  to {Math.min(currentPage * itemsPerPage, totalItems)} of{" "}
                  {totalItems}
                </div>

                {/* Pagination Component at Bottom */}
                {totalPages > 1 && (
                  <div className="flex justify-center mt-4">
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageClick}
                      onFirstPage={handleFirstPage}
                      onLastPage={handleLastPage}
                      onPreviousPage={handlePreviousPage}
                      onNextPage={handleNextPage}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Assignment Modal */}
      {isAssignmentModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
            <h2 className="text-lg font-bold mb-4">
              {selectedAssignment ? "Re-Assign Vehicle" : "Add New Assignment"}
            </h2>

            {/* Driver Selection Dropdown for New Assignment */}
            {!selectedAssignment && (
              <div className="mb-4">
                <label htmlFor="driverSelect" className="block text-sm mb-2">
                  Select Driver:
                </label>
                <select
                  id="driverSelect"
                  className="border border-gray-300 rounded p-2 w-full"
                  onChange={(e) => {
                    const driverId = e.target.value;
                    const driverName =
                      e.target.options[e.target.selectedIndex].text;
                    setSelectedDriver({ id: driverId, name: driverName });
                  }}
                  value={selectedDriver?.id || ""}
                >
                  <option value="">-- Select Driver --</option>
                  {drivers.map((driver) => (
                    <option key={driver.driverID} value={driver.driverID}>
                      {driver.driverName} ({driver.driverID})
                    </option>
                  ))}
                </select>
              </div>
            )}

            {/* Vehicle Selection Dropdown */}
            <div className="mb-4">
              <label htmlFor="vehicleSelect" className="block text-sm mb-2">
                Select Vehicle:
              </label>
              <select
                id="vehicleSelect"
                className="border border-gray-300 rounded p-2 w-full"
                value={selectedVehicle}
                onChange={(e) => setSelectedVehicle(e.target.value)}
              >
                <option value="">-- Select Vehicle --</option>
                {vehicles.map((vehicle) => (
                  <option key={vehicle.vehicleId} value={vehicle.vehicleId}>
                    {vehicle.vehicleMake} {vehicle.vehicleModel} (
                    {vehicle.vehicleRegistration})({vehicle.vehicleId})
                  </option>
                ))}
              </select>
            </div>

            <div className="flex justify-end space-x-2">
              <button
                className="bg-gray-300 text-gray-800 rounded-lg px-4 py-2"
                onClick={handleCloseAssignmentModal}
              >
                Cancel
              </button>
              <button
                className={`rounded-lg px-4 py-2 ${
                  isAssignButtonDisabled
                    ? "bg-gray-400 text-white cursor-not-allowed"
                    : "bg-green-500 text-white hover:bg-green-600"
                }`}
                onClick={handleConfirmAssignment}
                disabled={isAssignButtonDisabled}
              >
                {selectedAssignment ? "Re-Assign" : "Assign"}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Confirm Unassign Modal */}
      <ConfirmModal
        isOpen={isUnassignModalOpen}
        title="Confirm Unassignment"
        message="Are you sure you want to unassign this vehicle? This action cannot be undone."
        onConfirm={confirmUnassignVehicle}
        onCancel={handleCloseUnassignModal}
      />

      {/* Display Persistent Error Notification (Optional) */}
      {error && (
        <div className="fixed bottom-4 right-4 bg-red-500 text-white p-3 rounded">
          {error}
        </div>
      )}
    </>
  );
}

export default AssignList;
