// src/App.js
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { Toaster } from "sonner";
import {
  requestFirebaseNotificationPermission,
  onMessageListener,
} from "./firebase-config";
import { Navigate } from "react-router-dom";

// Import your components
import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";
import DriverList from "./Pages/Driver/DriverList";
import AddEditDriver from "./Pages/Driver/AddEditDriver";
import CustomerList from "./Pages/Customer/CustomerList";
import AddEditCustomer from "./Pages/Customer/AddEditCustomer";
import AllRides from "./Pages/Rides/AllRides";
import AllCoupons from "./Pages/Coupons/AllCoupons";
import AddNewCoupons from "./Pages/Coupons/AddNewCoupon";
import RevenueReport from "./Pages/RevenueReport/RevenueReport";
import ManagePricing from "./Pages/ManagePricing/ManagePricing";
import CashHandling from "./Pages/CashHandling/CashHandling";
import SettingsForm from "./Pages/Settings/Settings";
import CapabilitiesTable from "./Pages/Security/Capabilities";
import RolesTable from "./Pages/Security/Roles";
import PageList from "./Pages/PageList/PageList";
import Heatmap from "./Pages/Heatmap/Heatmap";
import LiveUsers from "./Pages/LiveUsers/LiveUsers";
import RideBookingForm from "./Pages/Rides/AddNewRide";
import BrandsList from "./Pages/Vehicles/BrandsList";
import AddEditBrand from "./Pages/Vehicles/AddEditBrand";
import ModelsList from "./Pages/Vehicles/ModelsList";
import AddEditModel from "./Pages/Vehicles/AddEditModel";
import ColorsList from "./Pages/Vehicles/ColorsList";
import AddEditColor from "./Pages/Vehicles/AddEditColor";
import VehiclesList from "./Pages/Vehicles/VehiclesList";
import AddEditVehicle from "./Pages/Vehicles/AddEditVehicle";
import useTokenRefresh from "./hooks/useTokenRefresh";
import AssignList from "./Pages/Driver/AssignList";
import UserList from "./Pages/User/UserList";
import AddEditUser from "./Pages/User/AddEditUser";
import CategoriesList from "./Pages/Vehicles/vehicleCategoryList";
import FaresTable from "./Pages/Vehicles/faresTableList";
import AddEditFare from "./Pages/Vehicles/AddEditFare";
import AddEditVehicleCategory from "./Pages/Vehicles/AddEditVehicleCategory";
import RideTable from "./Pages/Rides/RideDetails";
import PrivateRoute from "./Components/PrivateRoute"; // Import PrivateRoute
import NotificationHistory from "./Pages/NotificationHistory/NotificationHistory";
import ManageScheduleRide from "./Pages/ScheduleRide/ManageScheduleRide";

import { useDispatch, useSelector } from "react-redux";
import { setFcmToken } from "./State/userSlice";

function App() {
  useTokenRefresh();

  const dispatch = useDispatch();
  const fcmToken = useSelector((state) => state.user.fcmToken);

  useEffect(() => {
    // Function to request FCM token
    const getFcmToken = async () => {
      try {
        const token = await requestFirebaseNotificationPermission();
        if (token) {
          dispatch(setFcmToken(token));
          console.log("FCM Token set successfully:", token);
        }
      } catch (err) {
        console.error("Error retrieving token:", err);
      }
    };

    // Check if fcmToken is missing in localStorage
    const storedToken = localStorage.getItem("fcmToken");
    if (!storedToken) {
      getFcmToken();
    }

    // Handle foreground messages
    onMessageListener()
      .then((payload) => {
        console.log("Foreground message received:", payload);
        new Notification(payload.notification.title, {
          body: payload.notification.body,
        });
      })
      .catch((err) =>
        console.error("Failed to receive foreground message:", err)
      );
  }, [dispatch, fcmToken]);

  return (
    <Router>
      <Toaster richColors />
      <Routes>
        {/* Public Route */}
        <Route path="/" element={<Login />} />

        {/* Protected Routes */}
        <Route element={<PrivateRoute />}>
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/coupons/all" element={<AllCoupons />} />
          <Route path="/coupons/add" element={<AddNewCoupons />} />
          <Route path="/rides/all" element={<AllRides />} />
          <Route path="/driver" element={<DriverList />} />
          <Route path="/users" element={<UserList />} />
          <Route path="/users/add-user" element={<AddEditUser />} />
          <Route path="/edit-user/:userId" element={<AddEditUser />} />
          <Route path="/customer" element={<CustomerList />} />
          <Route path="/add-customer" element={<AddEditCustomer />} />
          <Route
            path="/edit-customer/:customerId"
            element={<AddEditCustomer />}
          />
          <Route path="/add-driver" element={<AddEditDriver />} />
          <Route path="/edit-driver/:driverId" element={<AddEditDriver />} />
          <Route path="/assignments" element={<AssignList />} />
          <Route path="/vehicles/all" element={<VehiclesList />} />
          <Route path="/revenuereport" element={<RevenueReport />} />
          <Route path="/managepricing" element={<ManagePricing />} />
          <Route path="/cashhandling" element={<CashHandling />} />
          <Route path="/SettingsForm" element={<SettingsForm />} />
          <Route
            path="/security/capabilities"
            element={<CapabilitiesTable />}
          />
          <Route path="/security/roles" element={<RolesTable />} />
          <Route path="/Heatmap" element={<Heatmap />} />
          <Route path="/LiveUsers" element={<LiveUsers />} />
          <Route path="/rides/add" element={<RideBookingForm />} />
          <Route path="/vehicles/brand" element={<BrandsList />} />
          <Route path="/vehicles/model" element={<ModelsList />} />
          <Route path="/pages" element={<PageList />} />
          <Route path="/vehicles/add-brand" element={<AddEditBrand />} />
          <Route
            path="/vehicles/edit-brand/:brandId"
            element={<AddEditBrand />}
          />
          <Route path="/vehicles/add-model" element={<AddEditModel />} />
          <Route
            path="/vehicles/edit-model/:modelId"
            element={<AddEditModel />}
          />
          <Route path="/vehicles/colors" element={<ColorsList />} />
          <Route path="/vehicles/add-color" element={<AddEditColor />} />
          <Route
            path="/vehicles/edit-color/:colorId"
            element={<AddEditColor />}
          />
          <Route path="/vehicles/add-vehicle" element={<AddEditVehicle />} />
          <Route
            path="/vehicles/edit-vehicle/:vehicleId"
            element={<AddEditVehicle />}
          />
          <Route path="/driver/assignlist" element={<AssignList />} />
          <Route
            path="/vehicles/vehiclecategory"
            element={<CategoriesList />}
          />
          <Route
            path="/vehicles/add-vehicle-category"
            element={<AddEditVehicleCategory />}
          />
          <Route
            path="/vehicles/edit-vehicle-category/:categoryId"
            element={<AddEditVehicleCategory />}
          />
          <Route path="/vehicles/fares" element={<FaresTable />} />
          <Route path="/vehicles/fares/add" element={<AddEditFare />} />
          <Route
            path="/vehicles/fares/edit/:fareId"
            element={<AddEditFare />}
          />
          <Route path="/RideTable" element={<RideTable />} />
          <Route
            path="/NotificationHistory"
            element={<NotificationHistory />}
          />
          <Route
            path="/manage-scheduled-rides"
            element={<ManageScheduleRide />}
          />
        </Route>
        {/* Fallback Route */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
