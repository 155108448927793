// sideBarSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  expandedList: {
    dashboard: false,
    heatmap: false,
    liveusers: false,
    users: false,
    managePricing: false,
    revenue: false,
    rides: false,
    coupons: false,
    cashHandling: false,
    vehicles: false,
    security: false,
    page: false,
    settings: false,
  },
};

const sideBarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggleCategory: (state, action) => {
      const category = action.payload;

      // Check if the clicked category is already open
      const isCurrentlyOpen = state.expandedList[category];

      // Close all categories
      Object.keys(state.expandedList).forEach((key) => {
        state.expandedList[key] = false;
      });

      // Toggle the clicked category only if it wasn't already open
      if (!isCurrentlyOpen) {
        state.expandedList[category] = true;
      }
    },
    // Optional: Set the entire expanded list if needed elsewhere
    setExpandedList: (state, action) => {
      state.expandedList = action.payload;
    },
  },
});

export const { toggleCategory, setExpandedList } = sideBarSlice.actions;
export default sideBarSlice.reducer;
