import React, { useState } from "react";
import Navbar from "../../Components/Navbar"; // Adjust this to the actual path
import Sidebar from "../../Components/Sidebar"; 

const CapabilitiesTable = () => {
    const [caps, setCaps] = useState([
        { id: "#245", name: "Pd", code: "pd", parent: "NA", status: "Enable" },
        { id: "#244", name: "Read PD", code: "can_read_pd", parent: "Users", status: "Enable" },
        { id: "#243", name: "Delete PD", code: "can_delete_pd", parent: "Users", status: "Enable" },
        { id: "#242", name: "Add PD", code: "can_add_pd", parent: "Users", status: "Enable" },
        { id: "#241", name: "Delete Organization", code: "can_delete_organization_entity", parent: "Organization", status: "Enable" },
        { id: "#240", name: "Read Organization", code: "can_read_organization_entity", parent: "Organization", status: "Enable" },
        { id: "#239", name: "Write Organization", code: "can_write_organization_entity", parent: "Organization", status: "Enable" },
        { id: "#238", name: "Organization", code: "organization", parent: "NA", status: "Enable" },
        { id: "#237", name: "Can read operators", code: "can_read_operators", parent: "Users", status: "Enable" },
        { id: "#236", name: "Can add color", code: "can_add_color", parent: "Vehicles", status: "Enable" },
    ]);

    return (
        <>
        <Navbar />
        <div className="flex pt-16">
          <Sidebar currentSelection={{main:"capabilities",sub:"null"}} />
          {/* Main content area */}
          <div className="flex-1 bg-gray-50 p-6 overflow-y-auto h-screen">
            <h1 className="text-2xl font-semibold mb-6">Dashboard</h1>
              <div className="mb-4">
              <div className="p-4 bg-gray-50 min-h-screen">
    <div className="bg-white rounded-lg shadow-md p-4">
        <h1 className="text-lg font-semibold mb-3">All Caps</h1>
        
        {/* Table Header */}
        <div className="flex justify-between items-center mb-3">
            <div>
                <span>Showing 1 to 10 of 237</span>
            </div>
            <div className="flex space-x-2 items-center">
                <select className="border border-gray-300 rounded-md p-1">
                    <option>10 Per Page</option>
                    <option>20 Per Page</option>
                    <option>30 Per Page</option>
                </select>
                <button className="bg-green-500 text-white px-3 py-1 rounded-md text-sm">Refresh</button>
            </div>
        </div>

        {/* Table */}
        <table className="min-w-full bg-white rounded-md text-sm">
            <thead>
                <tr className="border-b">
                    <th className="text-left p-2">ID</th>
                    <th className="text-left p-2">Cap Name</th>
                    <th className="text-left p-2">Cap Code</th>
                    <th className="text-left p-2">Parent</th>
                    <th className="text-left p-2">Status</th>
                </tr>
            </thead>
            <tbody>
                {caps.map((cap, index) => (
                    <tr key={index} className="border-b">
                        <td className="p-2">{cap.id}</td>
                        <td className="p-2">{cap.name}</td>
                        <td className="p-2">{cap.code}</td>
                        <td className="p-2">{cap.parent}</td>
                        <td className="p-2">
                            <span className={`px-2 py-1 rounded-md text-white text-xs ${cap.status === "Enable" ? "bg-green-500" : "bg-gray-400"}`}>
                                {cap.status}
                            </span>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>

        {/* Pagination */}
        <div className="flex justify-between items-center mt-3">
            <span>Showing 1 to 10 of 237</span>
            <div className="flex space-x-2">
                <button className="p-1 rounded-md border border-gray-300">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
                    </svg>
                </button>
                <button className="p-1 rounded-md border border-gray-300">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                    </svg>
                </button>
            </div>
        </div>
    </div>
</div>
        </div>
           
                </div>
            </div>
        </>
    );
};

export default CapabilitiesTable;
