
import React, { useState } from "react";
import Navbar from "../../Components/Navbar"; // Adjust this to the actual path
import Sidebar from "../../Components/Sidebar"; 



const RolesTable = () => {


    const roles = [
        { id: "#24", name: "PD/3", parent: "Admin", status: "Enable", description: "PD" },
        { id: "#23", name: "Accountant/3", parent: "Admin", status: "Enable", description: "Accountant" },
        { id: "#22", name: "Operator/3", parent: "Admin", status: "Enable", description: "Operator" },
        { id: "#21", name: "Drivers/3", parent: "Admin", status: "Enable", description: "Driver" },
        { id: "#17", name: "Admin/1", parent: "Super Admin", status: "Enable", description: "This group will be for supervising the operations of vendors/sellers." },
        { id: "#15", name: "Customer/2", parent: "NA", status: "Enable", description: "--" },
        { id: "#1", name: "Super Admin/0", parent: "NA", status: "Enable", description: "This is the developer role and has full access of the system" }
      ];
    
    return (
        <>
        <Navbar />
        <div className="flex pt-16">
          <Sidebar />
          {/* Main content area */}
          <div className="flex-1 bg-gray-50 p-6 overflow-y-auto h-screen">
            <h1 className="text-2xl font-semibold mb-6">Dashboard</h1>
              <div className="mb-4">
              <div className="p-4 bg-gray-50 min-h-screen">
    <div className="bg-white rounded-lg shadow-md p-4">

<div className="p-4 bg-gray-50 min-h-screen">
    <div className="bg-white rounded-lg shadow-md p-4">
        <h1 className="text-lg font-semibold mb-3">All Roles</h1>
        
        {/* Table Header */}
        <div className="flex justify-between items-center mb-3">
            <span>Showing 1 to 10 of 16</span>
            <div className="flex space-x-2 items-center">
                <select className="border border-gray-300 rounded-md p-1">
                    <option>10 Per Page</option>
                    <option>20 Per Page</option>
                    <option>30 Per Page</option>
                </select>
                <button className="bg-green-500 text-white px-3 py-1 rounded-md text-sm">Refresh</button>
            </div>
        </div>

        {/* Table */}
        <table className="min-w-full bg-white rounded-md text-sm">
            <thead>
                <tr className="border-b">
                    <th className="text-left p-2">ID</th>
                    <th className="text-left p-2">Name/Level</th>
                    <th className="text-left p-2">Parent</th>
                    <th className="text-left p-2">Status</th>
                    <th className="text-left p-2">Description</th>
                    <th className="text-center p-2">Actions</th>
                </tr>
            </thead>
            <tbody>
                {roles.map((role, index) => (
                    <tr key={index} className="border-b">
                        <td className="p-2">{role.id}</td>
                        <td className="p-2">{role.name}</td>
                        <td className="p-2">{role.parent}</td>
                        <td className="p-2">
                            <span className={`px-2 py-1 rounded-md text-white text-xs ${role.status === "Enable" ? "bg-green-500" : "bg-gray-400"}`}>
                                {role.status}
                            </span>
                        </td>
                        <td className="p-2">{role.description || "--"}</td>
                        <td className="p-2 text-center">
                            <button className="border border-blue-400 text-blue-500 px-2 py-1 rounded-md">View</button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>

        {/* Pagination */}
        <div className="flex justify-between items-center mt-3">
            <span>Showing 1 to 10 of 16</span>
            <div className="flex space-x-2">
                <button className="p-1 rounded-md border border-gray-300">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
                    </svg>
                </button>
                <button className="p-1 rounded-md border border-gray-300">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                    </svg>
                </button>
            </div>
        </div>
    </div>
</div>
</div>
        </div>
           
                </div>
            </div>
            </div>
        </>
    );
};

export default RolesTable;
