// src/pages/Vehicles/AddEditVehicleCategory.jsx

import { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useAuthToken from "../../hooks/customHooks";
import vehicleAPI from "../../utils/API/vehicleApis";
import { toast } from "sonner";

function AddEditVehicleCategory() {
  const token = useAuthToken();
  const navigate = useNavigate();
  const location = useLocation();
  const { categoryId } = useParams();
  const editCategory = location.state?.category;
  const [categoryDetails, setCategoryDetails] = useState({
    category_name: "",
    status: "",
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategoryDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // If editing, set the existing category details
  useEffect(() => {
    if (categoryId && editCategory) {
      console.log("Edit: ", editCategory);
      setCategoryDetails({
        category_name: editCategory.category_name,
        status: editCategory.status,
      });
    }
  }, [categoryId, editCategory]);

  const validate = () => {
    const newErrors = {};

    // Category Name: Required and must be a string
    if (!categoryDetails.category_name.trim()) {
      newErrors.category_name = "Category name is required.";
    }else if (categoryDetails.category_name.length > 20) {
      newErrors.category_name = "Category Name cannot exceed 20 characters.";
      toast.error("Category Name cannot exceed 20 characters.");
    }


    // Status: Must be either "Draft" or "Published"
    if (!["draft", "published"].includes(categoryDetails.status.toLowerCase())) {
      newErrors.status = "Please select a valid status.";
    }

    setErrors(newErrors);

    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform validation
    if (!validate()) {
      // If validation fails, do not proceed
      return;
    }

    setLoading(true);
    try {
      let response;
      if (categoryId) {
        response = await vehicleAPI.editVehicleCategoryAPI(categoryDetails, categoryId, token);
      } else {
        response = await vehicleAPI.addVehicleCategoryAPI(categoryDetails, token);
      }

      if (response) {
        toast.success(`Category ${categoryId ? "updated" : "added"} successfully!`);
        setCategoryDetails({
          category_name: "",
          status: "",
        });
        setErrors({});
        // Navigate to categories list after successful submission
        navigate("/vehicles/vehiclecategory");
      } else {
        console.log("Something went wrong. Please try again.");
        toast.error("Something went wrong. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      alert(error.response?.data?.message || "An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Navbar />
      <div className="flex pt-16">
        <Sidebar currentSelection={{ main: "vehicles", sub: "vehiclecategory" }} />
        <div className="flex-1 bg-stone-200 p-6 overflow-y-auto">
          <div className="container mx-auto p-4">
            <div className="bg-stone-50 p-5 mb-8 rounded-xl mt-12">
              <form onSubmit={handleSubmit}>
                <div className="flex justify-between">
                  <div className="text-xl py-2 my-2">
                    {categoryId ? "Edit" : "Add New"} Vehicle Category
                  </div>
                  <div>
                    <button
                      type="button"
                      className="bg-transparent text-green-700 border border-green-700 rounded-lg p-2 h-10"
                      onClick={() => {
                        navigate("/vehicles/vehiclecategory");
                      }}
                    >
                      Back
                    </button>
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-6 mt-4">
                  {/* Category Name */}
                  <div className="flex flex-col text-gray-600">
                    <label>Category Name *</label>
                    <input
                      type="text"
                      name="category_name"
                      placeholder="Enter category name"
                      value={categoryDetails.category_name}
                      onChange={handleChange}
                      required
                      className={`border rounded-xl p-2 ${
                        errors.category_name ? "border-red-500" : "border-gray-300"
                      }`}
                    />
                    {errors.category_name && (
                      <span className="text-red-500 text-sm">
                        {errors.category_name}
                      </span>
                    )}
                  </div>

                  {/* Status */}
                  <div className="flex flex-col text-gray-600">
                    <label>Status *</label>
                    <select
                      name="status"
                      value={categoryDetails.status}
                      onChange={handleChange}
                      required
                      className={`border rounded-xl p-2 ${
                        errors.status ? "border-red-500" : "border-gray-300"
                      }`}
                    >
                      <option value="">Select Status</option>
                      <option value="draft">Draft</option>
                      <option value="published">Published</option>
                    </select>
                    {errors.status && (
                      <span className="text-red-500 text-sm">
                        {errors.status}
                      </span>
                    )}
                  </div>
                </div>

                <div className="flex gap-2 mt-4">
                  <button
                    type="submit"
                    className={`bg-transparent text-green-700 border border-green-700 rounded-lg p-2 h-10 ${
                      loading ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    disabled={loading}
                  >
                    {loading ? "Submitting..." : categoryId ? "Update" : "Save"}
                  </button>
                  <button
                    type="reset"
                    className="bg-transparent text-red-700 border border-red-700 rounded-lg p-2 h-10"
                    onClick={() => {
                      setCategoryDetails({
                        category_name: "",
                        status: "",
                      });
                      setErrors({});
                    }}
                  >
                    Reset
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddEditVehicleCategory;
