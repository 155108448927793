// src/hooks/useLogout.js
import { useDispatch } from "react-redux";
import { removeTokens } from "../State/userSlice";
import authApis from "../utils/API/authApis";
import { persistor } from "../State/store";
import { useNavigate } from "react-router-dom";
import useAuthToken from "../hooks/customHooks";

const useLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useAuthToken();

  const performLogout = async () => {
    try {
      const logoutResult = await authApis.logoutApi(token);

      console.log("LOGOUT: ",logoutResult);

      // Dispatch the logout action after successful API call
      dispatch(removeTokens()); // Use 'removeTokens' here

      // Purge the persisted state
      await persistor.purge();
      console.log("Persisted state purged");

      // Redirect to login
      navigate("/");
    } catch (error) {
      console.error("An error occurred during logout:", error);
      alert("An unexpected error occurred. Please try again.");
    }
  };

  return performLogout;
};

export default useLogout;
