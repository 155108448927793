// src/Pages/Vehicles/Brands.jsx

import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import { MdEdit, MdDelete } from "react-icons/md";
import Pagination from "../../Components/Pagination";
import vehicleAPI from "../../utils/API/vehicleApis";
import { useNavigate } from "react-router-dom";
import useAuthToken from "../../hooks/customHooks";
import { toast } from "sonner"; // Import Sonner for toast notifications
import ConfirmModal from "../../Components/ConfirmModal"; // Import ConfirmModal

function BrandsList() {
  const navigate = useNavigate();
  const token = useAuthToken();
  const [filterValue, setFilterValue] = useState({ brandName: "", status: "" });
  const [searchPayload, setSearchPayload] = useState({
    brandName: "",
    status: "",
  });

  // State for pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  // State for brands data, total pages, and total items
  const [brands, setBrands] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0); // Added totalItems

  // State for loading and error
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // State for confirmation modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [brandToDelete, setBrandToDelete] = useState(null);

  /**
   * Fetches brands from the API based on current filters and pagination.
   */
  const fetchBrands = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await vehicleAPI.getBrandsAPI(
        currentPage,
        itemsPerPage,
        searchPayload,
        token
      );

      setBrands(response.data.items);
      setTotalPages(response.data.totalPages);
      setTotalItems(response.data.totalItems); // Set total items
    } catch (err) {
      const errorMessage =
        err.response?.data?.message ||
        err.message ||
        "An error occurred while fetching brands.";
      setError(errorMessage);
      toast.error(errorMessage); // Show error toast
    } finally {
      setLoading(false);
    }
  };

  /**
   * Fetch data whenever currentPage, itemsPerPage, or searchPayload changes
   */
  useEffect(() => {
    fetchBrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage, searchPayload]);

  /**
   * Handles the filter button click.
   */
  const handleFilter = () => {
    setSearchPayload(filterValue);
    setCurrentPage(1); // Reset to first page on new filter
  };

  /**
   * Handles changes in the search input.
   */
  const handleSearchChange = (e) => {
    setFilterValue((prev) => ({
      ...prev,
      brandName: e.target.value,
    }));
  };

  /**
   * Handles changes in the status filter.
   */
  const handleStatusFilterChange = (e) => {
    setFilterValue((prev) => ({ ...prev, status: e.target.value }));
  };

  /**
   * Resets the filters to their initial state.
   */
  const handleReset = () => {
    setFilterValue({ brandName: "", status: "" });
    setSearchPayload({ brandName: "", status: "" });
    setCurrentPage(1);
  };

  /**
   * Navigates to the edit brand page.
   * @param {string} brandId - The ID of the brand to edit.
   * @param {object} brand - The brand object.
   */
  const handleEdit = (brandId, brand) => {
    navigate(`/vehicles/edit-brand/${brandId}`, { state: { brand } });
  };

  /**
   * Opens the confirmation modal to delete a brand.
   * @param {object} brand - The brand object to delete.
   */
  const handleDeleteClick = (brand) => {
    setBrandToDelete(brand);
    setIsModalOpen(true);
  };

  /**
   * Confirms the deletion of the brand.
   */
  const confirmDeleteBrand = async () => {
    if (!brandToDelete) return;

    setIsModalOpen(false);
    setLoading(true);
    setError(null);

    try {
      await vehicleAPI.deleteBrandAPI(brandToDelete.id, token);
      toast.success("Brand deleted successfully!");
      // Refetch brands to update the list
      fetchBrands();
    } catch (err) {
      const errorMessage =
        err.response?.data?.message ||
        err.message ||
        "Failed to delete brand.";
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
      setBrandToDelete(null);
    }
  };

  /**
   * Cancels the deletion and closes the modal.
   */
  const cancelDelete = () => {
    setIsModalOpen(false);
    setBrandToDelete(null);
  };

  /**
   * Handles change in items per page.
   * @param {object} e - Event object.
   */
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  /**
   * Handles page number click.
   * @param {number} pageNumber - The page number to navigate to.
   */
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  /**
   * Handles navigation to the first page.
   */
  const handleFirstPage = () => setCurrentPage(1);

  /**
   * Handles navigation to the last page.
   */
  const handleLastPage = () => setCurrentPage(totalPages);

  /**
   * Handles navigation to the previous page.
   */
  const handlePreviousPage = () =>
    setCurrentPage((prev) => Math.max(prev - 1, 1));

  /**
   * Handles navigation to the next page.
   */
  const handleNextPage = () =>
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));

  return (
    <>
      <Navbar />
      <div className="flex pt-16">
        {/* Sidebar with current selection */}
        <Sidebar currentSelection={{ main: "vehicles", sub: "brand" }} />
        <div className="flex-1 bg-stone-200 p-6 overflow-y-auto">
          <div className="container mx-auto p-4">
            {/* Heading */}
            <h1 className="text-2xl text-gray-600 font-bold mb-4">
              Brands List
            </h1>

            {/* Search and Filter Section */}
            <div className="bg-stone-50 p-5 mb-8 rounded-xl">
              <div className="flex space-x-16 items-center">
                {/* Search Input */}
                <div className="flex flex-col text-gray-600">
                  <label htmlFor="search">Search</label>
                  <input
                    id="search"
                    type="text"
                    placeholder="Search by Brand Name"
                    value={filterValue.brandName}
                    onChange={handleSearchChange}
                    className="border border-gray-300 rounded p-2 w-96"
                  />
                </div>

                {/* Status Filter */}
                <div className="flex flex-col text-gray-600">
                  <label htmlFor="status">Status</label>
                  <select
                    id="status"
                    className="border border-gray-300 rounded p-2"
                    value={filterValue.status}
                    onChange={handleStatusFilterChange} // Changed from onClick to onChange
                  >
                    <option value="">-- Select --</option>
                    <option value="Draft">Draft</option>
                    <option value="Published">Published</option>
                  </select>
                </div>
              </div>

              {/* Filter and Reset Buttons */}
              <div className="flex gap-2 mt-4">
                <button
                  className="bg-transparent text-green-700 border border-green-700 rounded-lg p-2 h-10"
                  onClick={handleFilter}
                >
                  Filter
                </button>
                <button
                  className="bg-transparent text-red-700 border border-red-700 rounded-lg p-2 h-10"
                  onClick={handleReset}
                >
                  Reset
                </button>
              </div>
            </div>

            {/* Brands List Header */}
            <div className="flex bg-stone-50 font-semibold justify-between text-gray-600 mb-0.5 p-3 rounded-t-xl">
              <div>Brands List</div>
              {/* Items Per Page Selector */}
              <div className="flex items-center space-x-2">
                <label htmlFor="itemsPerPage" className="text-sm">
                  Items per page:
                </label>
                <select
                  id="itemsPerPage"
                  className="border border-gray-300 text-sm rounded p-2"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                </select>
                <button
                  className="border border-emerald-900 text-sm font-normal rounded-md p-2 text-emerald-900"
                  onClick={() => {
                    navigate("/vehicles/add-brand");
                  }}
                >
                  + Add New Brand
                </button>
              </div>
            </div>

            {/* Pagination and Items Information */}
            <div className="flex flex-col md:flex-row bg-stone-50 justify-between items-center text-sm mb-0.5 p-3 rounded-b-none rounded-t-none">
              {/* Showing X to Y of Z */}
              <div className="mb-2 md:mb-0">
                {loading ? (
                  <span>Loading...</span>
                ) : (
                  <span>
                    Showing{" "}
                    {totalItems === 0
                      ? 0
                      : (currentPage - 1) * itemsPerPage + 1}{" "}
                    to {Math.min(currentPage * itemsPerPage, totalItems)} of{" "}
                    {totalItems}
                  </span>
                )}
              </div>

              {/* Pagination Component */}
              {totalPages > 1 && (
                <div className="flex justify-center mt-4">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageClick}
                    onFirstPage={handleFirstPage}
                    onLastPage={handleLastPage}
                    onPreviousPage={handlePreviousPage}
                    onNextPage={handleNextPage}
                  />
                </div>
              )}
            </div>

            {/* Brands List Section */}
            <div className="space-y-0">
              {/* Header Row */}
              <div className="flex bg-stone-50 justify-center items-center font-bold text-sm mb-0.5 p-2 border-b border-gray-300">
                <div className="flex-1 text-left">ID</div>
                <div className="flex-1 text-left">Brand</div>
                <div className="flex-1 text-left">Created At</div>
                <div className="flex-1 text-right">Actions</div>
              </div>

              {/* Loading and Error States */}
              {loading ? (
                <div className="flex justify-center p-4 text-gray-500">
                  Loading...
                </div>
              ) : brands?.length > 0 ? (
                brands.map((brand) => (
                  <div
                    key={brand.id} // Ensure brand.id is unique and exists
                    className="flex bg-white p-2 border-b border-gray-300 transition-transform transform text-sm gap-8 hover:bg-gray-50"
                  >
                    <div className="flex-1 px-1 text-left">{brand.id}</div>
                    <div
                      className="flex-1 overflow-hidden text-left"
                      style={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                      }}
                    >
                      {brand.brandName}
                    </div>
                    <div className="flex-1 mr-4 text-left">
                      {new Date(brand.createdAt).toLocaleDateString()}{" "}
                      {/* Format date */}
                    </div>

                    <div className="flex-1 flex justify-end space-x-2">
                      <button
                        className="bg-transparent text-green-700 border border-green-700 rounded-lg p-2 text-xs h-8 hover:bg-green-100"
                        aria-label={`Edit brand ${brand.id}`}
                        onClick={() => handleEdit(brand.id, brand)}
                      >
                        <MdEdit />
                      </button>
                      <button
                        className="bg-transparent text-red-700 border border-red-700 rounded-lg p-2 text-xs h-8 hover:bg-red-100"
                        aria-label={`Delete brand ${brand.id}`}
                        onClick={() => handleDeleteClick(brand)}
                        disabled={loading}
                      >
                        <MdDelete />
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <div className="flex bg-white p-4 text-center text-gray-500">
                  No brands found.
                </div>
              )}

              {/* Footer Showing X to Y of Z */}
              <div className="flex flex-col md:flex-row bg-stone-50 justify-between items-center text-sm mb-0.5 p-3 rounded-b-xl">
                <div>
                  Showing{" "}
                  {totalItems === 0
                    ? 0
                    : (currentPage - 1) * itemsPerPage + 1}{" "}
                  to {Math.min(currentPage * itemsPerPage, totalItems)} of{" "}
                  {totalItems}
                </div>

                {/* Pagination Component at Bottom */}
                {totalPages > 1 && (
                  <div className="flex justify-center mt-4">
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageClick}
                      onFirstPage={handleFirstPage}
                      onLastPage={handleLastPage}
                      onPreviousPage={handlePreviousPage}
                      onNextPage={handleNextPage}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Confirmation Modal for Deletion */}
      <ConfirmModal
        isOpen={isModalOpen}
        title="Confirm Deletion"
        message={`Are you sure you want to delete the brand "${brandToDelete?.brandName}"? This action cannot be undone.`}
        onConfirm={confirmDeleteBrand}
        onCancel={cancelDelete}
      />
    </>
  );
}

export default BrandsList;
