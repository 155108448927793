// src/Pages/AllPages/AllPages.jsx
import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import { MdEdit, MdDelete } from "react-icons/md";
import StatusBadge from "../../Components/StatusBadge";
import Pagination from "../../Components/Pagination"; // Import the Pagination component
import { fakeApiPageDetails } from "../../SampleData/fakeApiUserData";

function PageList() {
  // State for pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  // State for pages data and total pages
  const [pages, setPages] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  // State for loading and error
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Function to fetch data from the fake API
  const fetchPages = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fakeApiPageDetails({
        page: currentPage,
        limit: itemsPerPage,
      });

      setPages(response.page);
      setTotalPages(response.totalPages);
    } catch (err) {
      setError(err.message || "An error occurred while fetching pages.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch data whenever currentPage or itemsPerPage changes
  useEffect(() => {
    fetchPages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage]);

  // Handle change in items per page
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  // Handle page number click
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle first, last, previous, and next page actions
  const handleFirstPage = () => setCurrentPage(1);
  const handleLastPage = () => setCurrentPage(totalPages);
  const handlePreviousPage = () =>
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  const handleNextPage = () =>
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));

  return (
    <>
      <Navbar />
      <div className="flex pt-16">
        {/* Sidebar with current selection */}
        <Sidebar currentSelection={{ main: "page", sub: "allPages" }} />
        <div className="flex-1 bg-stone-200 p-6 overflow-y-auto h-screen">
          <div className="container mx-auto p-4">
            {/* Heading */}
            <h1 className="text-2xl text-gray-600 font-bold mb-4">All Pages</h1>

            {/* Search and Filter Section */}
            <div className="bg-stone-50 p-5 mb-8 rounded-xl">
              <div className="flex space-x-16 items-center">
                {/* Search Input */}
                <div className="flex flex-col text-gray-600">
                  <label htmlFor="search">Search</label>
                  <input
                    id="search"
                    type="text"
                    placeholder="Search by Page Name | ID"
                    className="border border-gray-300 rounded p-2 w-96"
                  />
                </div>

                {/* Status Filter */}
                <div className="flex flex-col text-gray-600">
                  <label htmlFor="status">Status</label>
                  <select
                    id="status"
                    className="border border-gray-300 rounded p-2"
                  >
                    <option>-- Select --</option>
                    <option value="published">Published</option>
                    <option value="draft">Draft</option>
                    {/* Add more status options as needed */}
                  </select>
                </div>
              </div>

              {/* Filter and Reset Buttons */}
              <div className="flex gap-2 mt-4">
                <button className="bg-transparent text-green-700 border border-green-700 rounded-lg p-2 h-10">
                  Filter
                </button>
                <button className="bg-transparent text-red-700 border border-red-700 rounded-lg p-2 h-10">
                  Reset
                </button>
              </div>
            </div>

            {/* Pages List Header */}
            <div className="flex bg-stone-50 font-semibold justify-between text-gray-600 mb-0.5 p-3 rounded-t-xl">
              <div>All Pages</div>
              {/* Items Per Page Selector */}
              <div className="flex items-center space-x-2">
                <label htmlFor="itemsPerPage" className="text-sm">
                  Items per page:
                </label>
                <select
                  id="itemsPerPage"
                  className="border border-gray-300 text-sm rounded p-2"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                </select>
              </div>
            </div>

            {/* Pagination and Items Information */}
            <div className="flex flex-col md:flex-row bg-stone-50 justify-between items-center text-sm mb-0.5 p-3 rounded-b-none rounded-t-none">
              {/* Showing X to Y of Z */}
              <div className="mb-2 md:mb-0">
                {loading ? (
                  <span>Loading...</span>
                ) : (
                  <span>
                    Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
                    {Math.min(
                      currentPage * itemsPerPage,
                      totalPages * itemsPerPage
                    )}{" "}
                    of {totalPages * itemsPerPage}
                  </span>
                )}
              </div>

              {/* Pagination Component */}
              {totalPages > 1 && (
                <div className="flex justify-center mt-4">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageClick}
                    onFirstPage={handleFirstPage}
                    onLastPage={handleLastPage}
                    onPreviousPage={handlePreviousPage}
                    onNextPage={handleNextPage}
                  />
                </div>
              )}
            </div>

            {/* Pages List Section */}
            <div className="space-y-0">
              {/* Header Row */}
              <div className="flex bg-stone-50 justify-center items-center font-bold text-sm mb-0.5 p-2 border-b border-gray-300">
                <div className="flex-1 text-left">ID</div>
                <div className="flex-1 text-left">Page Name</div>
                <div className="flex-1 text-left">Status</div>
                <div className="flex-1 text-left">Created At</div>
                <div className="flex-1 text-right">Actions</div>
              </div>

              {/* Loading and Error States */}
              {loading ? (
                <div className="flex justify-center p-4 text-gray-500">
                  Loading...
                </div>
              ) : error ? (
                <div className="flex justify-center p-4 text-red-500">
                  {error}
                </div>
              ) : pages.length > 0 ? (
                pages.map((page) => (
                  <div
                    key={page.id}
                    className="flex bg-white p-2 border-b border-gray-300 transition-transform transform text-sm gap-8 hover:bg-gray-50"
                  >
                    <div className="flex-1 px-1 text-left">{page.id}</div>
                    <div
                      className="flex-1 overflow-hidden text-left"
                      style={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                      }}
                    >
                      {page.pagename}
                    </div>
                    <div className="flex-1 mr-4 text-left">
                      <StatusBadge status={page.status} />
                    </div>
                    <div className="flex-1 mr-4 text-left">
                      {page.createdat}
                    </div>

                    <div className="flex-1 flex justify-end space-x-2">
                      <button
                        className="bg-transparent text-green-700 border border-green-700 rounded-lg p-2 text-xs h-8 hover:bg-green-100 mx-2"
                        aria-label={`Edit page ${page.id}`}
                      >
                        <MdEdit />
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <div className="flex bg-white p-4 text-center text-gray-500">
                  No pages found.
                </div>
              )}

              {/* Footer Showing X to Y of Z */}
              <div className="flex bg-stone-50 justify-between items-center text-sm mb-0.5 p-3 rounded-b-xl">
                <div>
                  Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
                  {Math.min(
                    currentPage * itemsPerPage,
                    totalPages * itemsPerPage
                  )}{" "}
                  of {totalPages * itemsPerPage}
                </div>

                {/* Pagination at Bottom (Optional) */}
                {totalPages > 1 && (
                  <div className="flex justify-center mt-4">
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageClick}
                      onFirstPage={handleFirstPage}
                      onLastPage={handleLastPage}
                      onPreviousPage={handlePreviousPage}
                      onNextPage={handleNextPage}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PageList;
