// src/components/PrivateRoute.jsx
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = () => {
  const accessToken = useSelector((state) => state.user.accessToken);

  // Optionally, you can add more checks, such as token validity
  const isAuthenticated = Boolean(accessToken);

  return isAuthenticated ? <Outlet /> : <Navigate to="/" replace />;
};

export default PrivateRoute;
