// src/Pages/RevenueReport/RevenueReport.jsx

import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import Pagination from "../../Components/Pagination"; // Import the Pagination component
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MdEdit, MdDelete } from "react-icons/md";
import StatusBadge from "../../Components/StatusBadge"; // Assuming you have this component


const RevenueReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const revenueData = [];

  // State for search and filter
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDriver, setSelectedDriver] = useState("");
  const [dateRange, setDateRange] = useState("");

  // State for pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  // State for filtered data
  const [filteredData, setFilteredData] = useState(revenueData);

  // Effect to filter data based on search, driver, and date range
  useEffect(() => {
    let data = [...revenueData];

    // Filter by search query
    if (searchQuery.trim() !== "") {
      data = data.filter((item) =>
        [item.customerName, item.driverName, item.paymentMode]
          .join(" ")
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      );
    }

    // Filter by driver
    if (selectedDriver !== "") {
      data = data.filter(
        (item) => item.driverName.toLowerCase() === selectedDriver.toLowerCase()
      );
    }

    // Filter by date range
    if (dateRange.trim() !== "") {
      const [startDate, endDate] = dateRange.split(" to ");
      if (startDate && endDate) {
        data = data.filter((item) => {
          const itemDate = new Date(item.dateTime);
          const start = new Date(startDate);
          const end = new Date(endDate);
          return itemDate >= start && itemDate <= end;
        });
      }
    }

    setFilteredData(data);
    setCurrentPage(1); // Reset to first page when filters change
  }, [searchQuery, selectedDriver, dateRange]);

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Get the data for the current page
  const displayedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle change in items per page
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  // Handle page number click
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle first, last, previous, and next page actions
  const handleFirstPage = () => setCurrentPage(1);
  const handleLastPage = () => setCurrentPage(totalPages);
  const handlePreviousPage = () =>
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  const handleNextPage = () =>
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Handle driver select change
  const handleDriverChange = (e) => {
    setSelectedDriver(e.target.value);
  };

  // Handle date range change
  const handleDateChange = (e) => {
    setDateRange(e.target.value);
  };

  // Handle reset functionality
  const handleReset = () => {
    setSearchQuery("");
    setSelectedDriver("");
    setDateRange("");
  };

  return (
    <>
      <Navbar />
      <div className="flex pt-16">
        <Sidebar currentSelection={{main:"revenue",sub:null}}/>
        {/* Main content area */}
        <div className="flex-1 bg-stone-200 p-6 overflow-y-auto">
          <div className="container mx-auto p-4">
            {/* Header */}
            <div className="flex flex-col bg-white rounded-md my-4 pb-12">
              {/* Title */}
              <div className="bg-woagreen p-4 rounded-t-md mb-4">
                <h2 className="text-white text-lg">Revenue Report</h2>
              </div>

              {/* Search and Filter Section */}
              <div className="grid grid-flow-col justify-stretch md:flex-row gap-4 mt-4 mx-6">
                {/* Date Range Input */}
                <div className="flex flex-col text-gray-600">
                  <label htmlFor="dateRange">Date Range</label>
                  <input
                    type="text"
                    name="dateRange"
                    id="dateRange"
                    className="p-2 border rounded-md"
                    placeholder="2024-10-07 to 2024-10-07"
                    value={dateRange}
                    onChange={handleDateChange}
                  />
                </div>

                {/* Driver Select */}
                <div className="flex flex-col text-gray-600">
                  <label htmlFor="driver">Driver</label>
                  <select
                    id="driver"
                    className="p-2 border rounded-md"
                    value={selectedDriver}
                    onChange={handleDriverChange}
                  >
                    <option value="">Select Driver</option>
                    {/* Populate driver options dynamically */}
                    {/* Example:
                      <option value="John Doe">John Doe</option>
                      <option value="Jane Smith">Jane Smith</option>
                    */}
                    {[
                      ...new Set(revenueData.map((item) => item.driverName)),
                    ].map((driverName) => (
                      <option key={driverName} value={driverName}>
                        {driverName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {/* Filter and Reset Buttons */}
              <div className="flex gap-2 mt-4 mx-6">
                <button className="bg-transparent text-green-700 border border-green-700 rounded-lg p-2 h-10">
                  Filter
                </button>
                <button
                  className="bg-transparent text-red-700 border border-red-700 rounded-lg p-2 h-10"
                  onClick={handleReset}
                >
                  Reset
                </button>
              </div>
            </div>

            {/* Summary Cards */}
            <div className="grid grid-cols-2 md:grid-cols-6 gap-4 mb-4">
              <div className="bg-white flex flex-col justify-evenly p-4 rounded-md">
                <h3 className="text-sm text-green-700 font-light">
                  TOTAL REVENUE
                </h3>
                <p className="text-md">AED {filteredData.reduce((acc, item) => acc + item.payableAmt, 0)}</p>
              </div>
              <div className="bg-white flex flex-col p-4 rounded-md">
                <h3 className="text-sm text-green-700 font-light">
                  ONLINE REVENUE
                </h3>
                <p className="text-md">
                  AED{" "}
                  {filteredData
                    .filter((item) => item.paymentMode.toLowerCase() === "online")
                    .reduce((acc, item) => acc + item.payableAmt, 0)}
                </p>
              </div>
              <div className="bg-white flex flex-col p-4 rounded-md">
                <h3 className="text-sm text-green-700 font-light">
                  CASH REVENUE
                </h3>
                <p className="text-md">
                  AED{" "}
                  {filteredData
                    .filter((item) => item.paymentMode.toLowerCase() === "cash")
                    .reduce((acc, item) => acc + item.payableAmt, 0)}
                </p>
              </div>
              <div className="bg-white flex flex-col p-4 rounded-md">
                <h3 className="text-sm text-green-700 font-light">TOTAL VAT</h3>
                <p className="text-md">
                  AED {filteredData.reduce((acc, item) => acc + item.vat, 0)}
                </p>
              </div>
              <div className="bg-white flex flex-col p-4 rounded-md">
                <h3 className="text-sm text-green-700 font-light">TOTAL RIDES</h3>
                <p className="text-md">{filteredData.length}</p>
              </div>
              <div className="bg-white flex flex-col p-4 rounded-md">
                <h3 className="text-sm text-green-700 font-light">
                  TOTAL CUSTOMERS
                </h3>
                <p className="text-md">
                  {[
                    ...new Set(filteredData.map((item) => item.customerName)),
                  ].length}
                </p>
              </div>
            </div>

            {/* Revenue Table */}
            <div className="bg-white p-4 rounded-md">
              <table className="w-full border-collapse">
                <thead className="text-sm text-gray-700">
                  <tr className="border-b">
                    <th className="p-2">ID</th>
                    <th className="p-2">Date &amp; Time</th>
                    <th className="p-2">Customer Name</th>
                    <th className="p-2">Driver Name</th>
                    <th className="p-2">Pickup Location</th>
                    <th className="p-2">Drop Location</th>
                    <th className="p-2">Sub Total</th>
                    <th className="p-2">Discount</th>
                    <th className="p-2">VAT</th>
                    <th className="p-2">Payable Amt.</th>
                    <th className="p-2">Payment Mode</th>
                    <th className="p-2">Invoice</th>
                  </tr>
                </thead>
                <tbody>
                  {displayedData.length > 0 ? (
                    displayedData.map((item) => (
                      <tr key={item.id} className="border-b">
                        <td className="p-2">{item.id}</td>
                        <td className="p-2">{new Date(item.dateTime).toLocaleString()}</td>
                        <td className="p-2">{item.customerName}</td>
                        <td className="p-2">{item.driverName}</td>
                        <td className="p-2">{item.pickupLocation}</td>
                        <td className="p-2">{item.dropLocation}</td>
                        <td className="p-2">AED {item.subTotal}</td>
                        <td className="p-2">AED {item.discount}</td>
                        <td className="p-2">AED {item.vat}</td>
                        <td className="p-2">AED {item.payableAmt}</td>
                        <td className="p-2">{item.paymentMode}</td>
                        <td className="p-2">
                          {/* Assuming invoice is a link or a button */}
                          <button
                            className="text-blue-500 underline"
                            onClick={() => {
                              // Handle invoice viewing or downloading
                              navigate(`/invoices/${item.id}`);
                            }}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="12" className="text-center p-4 text-red-500">
                        No results found under selected criteria.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              {/* Pagination and Showing Text */}
              <div className="flex justify-between items-center mt-4">
                {/* Showing X to Y of Z */}
                <p>
                  Showing{" "}
                  {filteredData.length > 0
                    ? `${(currentPage - 1) * itemsPerPage + 1} to ${Math.min(
                        currentPage * itemsPerPage,
                        filteredData.length
                      )}`
                    : "0"}{" "}
                  of {filteredData.length}
                </p>

                {/* Pagination Component */}
                {totalPages > 1 && (
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageClick}
                    onFirstPage={handleFirstPage}
                    onLastPage={handleLastPage}
                    onPreviousPage={handlePreviousPage}
                    onNextPage={handleNextPage}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RevenueReport;
