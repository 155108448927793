// src/pages/Vehicles/AddEditColor.jsx

import { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import vehicleAPI from "../../utils/API/vehicleApis";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useAuthToken from "../../hooks/customHooks";
import { toast } from "sonner"; 

function AddEditColor() {
  const token = useAuthToken();
  const navigate = useNavigate();
  const location = useLocation();
  const { colorId } = useParams(); // Fixed usage
  const editColor = location.state?.color;

  const [colorDetails, setColorDetails] = useState({
    color: "",
    status: "",
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); // Added loading state
  const [submitError, setSubmitError] = useState(""); // Added submit error state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setColorDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (colorId && editColor) {
      setColorDetails({
        color: editColor.color || "",
        status: editColor.status || "",
      });
    }
  }, [colorId, editColor]);

  const validate = () => {
    const newErrors = {};

    // Color: Required and must be a string
    if (!colorDetails.color.trim()) {
      newErrors.color = "Color name is required.";
    }else if (colorDetails.color.length > 20) {
      newErrors.color = "Color Name cannot exceed 20 characters.";
      toast.error("Color Name cannot exceed 20 characters.");
    }

    // Status: Must be either "draft" or "published"
    if (!["draft", "published"].includes(colorDetails.status.toLowerCase())) {
      newErrors.status = "Please select a valid status.";
    }

    setErrors(newErrors);

    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform validation
    if (!validate()) {
      // If validation fails, do not proceed
      return;
    }

    setLoading(true);
    setSubmitError("");

    try {
      let response;
      if (colorId) {
        response = await vehicleAPI.editColorAPI(colorDetails, colorId,token);
      } else {
        response = await vehicleAPI.addColorAPI(colorDetails,token);
      }

      if (response.status === 200 || response.status === 201) {
        toast.success(`Color ${colorId ? "updated" : "added"} successfully!`);
        setColorDetails({
          color: "",
          status: "",
        });
        setErrors({});
        navigate("/vehicles/colors");
      } else {
        setSubmitError("Something went wrong. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      setSubmitError(
        "An error occurred while submitting the form. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Navbar />
      <div className="flex pt-16">
        <Sidebar currentSelection={{ main: "vehicles", sub: "color" }} />
        <div className="flex-1 bg-stone-200 p-6 overflow-y-auto">
          <div className="container mx-auto p-4">
            <div className="bg-stone-50 p-5 mb-8 rounded-xl mt-12">
              <form onSubmit={handleSubmit}>
                <div className="flex justify-between items-center">
                  <h2 className="text-xl font-semibold">
                    {colorId ? "Edit Color" : "Add New Color"}
                  </h2>
                  <button
                    type="button"
                    className="bg-transparent text-green-700 border border-green-700 rounded-lg p-2 h-10"
                    onClick={() => navigate("/vehicles/colors")}
                    disabled={loading}
                  >
                    Back
                  </button>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
                  {/* Color Name */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="color">Color Name *</label>
                    <input
                      id="color"
                      type="text"
                      name="color"
                      placeholder="Enter color name"
                      value={colorDetails.color}
                      onChange={handleChange}
                      required
                      className={`border rounded-xl p-2 ${
                        errors.color ? "border-red-500" : "border-gray-300"
                      }`}
                      aria-describedby={errors.color ? "color-error" : null}
                      disabled={loading}
                    />
                    {errors.color && (
                      <span
                        id="color-error"
                        className="text-red-500 text-sm mt-1"
                        role="alert"
                      >
                        {errors.color}
                      </span>
                    )}
                  </div>

                  {/* Status */}
                  <div className="flex flex-col text-gray-600">
                    <label htmlFor="status">Status *</label>
                    <select
                      id="status"
                      name="status"
                      value={colorDetails.status}
                      onChange={handleChange}
                      required
                      className={`border rounded-xl p-2 ${
                        errors.status ? "border-red-500" : "border-gray-300"
                      }`}
                      aria-describedby={errors.status ? "status-error" : null}
                      disabled={loading}
                    >
                      <option value="">Select Status</option>
                      <option value="draft">Draft</option>
                      <option value="published">Published</option>
                    </select>
                    {errors.status && (
                      <span
                        id="status-error"
                        className="text-red-500 text-sm mt-1"
                        role="alert"
                      >
                        {errors.status}
                      </span>
                    )}
                  </div>
                </div>

                {/* Submit Error */}
                {submitError && (
                  <div className="text-red-500 text-sm mt-4" role="alert">
                    {submitError}
                  </div>
                )}

                <div className="flex gap-2 mt-6">
                  <button
                    type="submit"
                    className={`${
                      loading
                        ? "bg-green-300 cursor-not-allowed"
                        : "bg-transparent text-green-700 border border-green-700"
                    } rounded-lg p-2 h-10 flex items-center justify-center`}
                    disabled={loading}
                  >
                    {loading ? "Saving..." : "Save"}
                  </button>
                  <button
                    type="button" // Changed to type button to prevent default reset
                    className={`${
                      loading
                        ? "bg-red-300 cursor-not-allowed"
                        : "bg-transparent text-red-700 border border-red-700"
                    } rounded-lg p-2 h-10`}
                    onClick={() => {
                      setColorDetails({
                        color: "",
                        status: "",
                      });
                      setErrors({});
                      setSubmitError("");
                    }}
                    disabled={loading}
                  >
                    Reset
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddEditColor;
