// src/pages/ManageScheduleRide/ManageScheduleRide.jsx

import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import TabsLgBasic from "../../Components/Tabs"; // Import the Tabs component
import { toast } from "sonner";
import rideApis from "../../utils/API/rideApis";
import useAuthToken from "../../hooks/customHooks";
import { FaChevronDown, FaChevronUp } from "react-icons/fa"; // Importing icons for expand/collapse
import RescheduledRidesTable from "../../Components/ResheduledRidesTable"; // Import the rescheduled table
import AssignedRidesTable from "../../Components/AssignedRidesTable"; // Import the assigned table
import UnassignedRidesTable from "../../Components/UnassignedRidesTable"; // Import the unassigned table

function ManageScheduleRide() {
  const [activeTab, setActiveTab] = useState("unassigned");
  const [assignedRides, setAssignedRides] = useState([]);
  const [unassignedRides, setUnassignedRides] = useState([]);
  const [rescheduledRides, setRescheduledRides] = useState([]); // State for rescheduled rides
  const [loading, setLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRide, setSelectedRide] = useState(null);
  const [drivers, setDrivers] = useState([]);
  const [assigning, setAssigning] = useState(false);
  const [expandedDrivers, setExpandedDrivers] = useState({}); // State to track expanded drivers
  const token = useAuthToken();

  // Fetch scheduled rides on component mount
  useEffect(() => {
    fetchScheduledRides();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchScheduledRides = async () => {
    setLoading(true);
    try {
      const response = await rideApis.getScheduledRidesAPI(token);
      if (response.data && response.data.success) {
        setAssignedRides(response.data.assignedRides);
        setUnassignedRides(response.data.unassignedRides);
        setRescheduledRides(response.data.rescheduledRides || []); // Assuming API provides rescheduledRides
      } else {
        toast.error(response.data?.message || "Failed to fetch scheduled rides.");
      }
    } catch (error) {
      console.error("Error fetching scheduled rides:", error);
      toast.error("An error occurred while fetching rides.");
    }
    setLoading(false);
  };

  const openAssignModal = (ride) => {
    setSelectedRide(ride);
    setIsModalOpen(true);
    fetchAvailableDrivers(ride);
  };

  const closeAssignModal = () => {
    setIsModalOpen(false);
    setSelectedRide(null);
    setDrivers([]);
    setExpandedDrivers({}); // Reset expanded drivers when modal closes
  };

  const fetchAvailableDrivers = async (ride) => {
    try {
      const vehicleModel = ride.scheduled_vehicle_model;
      if (!vehicleModel) {
        toast.error("Selected ride does not have a vehicle model.");
        return;
      }

      const payload = { vehicleModel }; // Ensure payload is an object
      const response = await rideApis.getDriverScheduleAPI(payload, token);
      if (response.data && response.data.drivers) {
        setDrivers(response.data.drivers);
      } else {
        toast.error(response.data?.message || "Failed to fetch drivers.");
      }
    } catch (error) {
      console.error("Error fetching available drivers:", error);
      toast.error("An error occurred while fetching drivers.");
    }
  };

  const handleAssignDriver = async (driverId) => {
    if (!selectedRide) return;
    setAssigning(true);
    try {
      const payload = {
        rideId: selectedRide.id,
        driverId: driverId,
      };
      const response = await rideApis.assignDriverScheduleAPI(payload, token);
      if (response.data && response.data.success) {
        toast.success("Driver assigned successfully!");
        fetchScheduledRides(); // Refresh data after assignment
        closeAssignModal();
      } else {
        toast.error(response.data?.message || "Failed to assign driver.");
      }
    } catch (error) {
      console.error("Error assigning driver:", error);
      toast.error("An error occurred while assigning driver.");
    }
    setAssigning(false);
  };

  const toggleExpandDriver = (driverId) => {
    setExpandedDrivers((prev) => ({
      ...prev,
      [driverId]: !prev[driverId],
    }));
  };

  return (
    <>
      <Navbar />
      <div className="flex pt-16">
        {/* Sidebar with current selection */}
        <Sidebar currentSelection={{ main: "rides", sub: "mngScheduledRides" }} />
        <div className="flex-1 bg-stone-200 p-6 overflow-y-auto h-screen">
          <h1 className="text-2xl font-semibold mb-4">Manage Scheduled Rides</h1>

          {/* Tabs */}
          <div className="mb-4">
            <TabsLgBasic activeTab={activeTab} setActiveTab={setActiveTab} />
          </div>

          {/* Content */}
          {loading ? (
            <div className="flex justify-center items-center">
              <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
            </div>
          ) : activeTab === "unassigned" ? (
            <UnassignedRidesTable rides={unassignedRides} onAssign={openAssignModal} />
          ) : activeTab === "assigned" ? (
            <AssignedRidesTable rides={assignedRides} />
          ) : (
            <RescheduledRidesTable rides={rescheduledRides} />
          )}

          {/* Assign Driver Modal */}
          {isModalOpen && (
            <div
              className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
              role="dialog"
              aria-modal="true"
              aria-labelledby="assignDriverModalTitle"
            >
              <div className="bg-white rounded-lg w-3/4 max-w-2xl p-6 overflow-y-auto max-h-full" role="document">
                <h2 id="assignDriverModalTitle" className="text-xl font-semibold mb-4">
                  Assign Driver
                </h2>
                {drivers.length === 0 ? (
                  <p className="text-center text-gray-500">No available drivers.</p>
                ) : (
                  <div className="min-w-full bg-white">
                    <table className="min-w-full bg-white">
                      <thead>
                        <tr>
                          <th className="py-2 px-4 border-b">Driver ID</th>
                          <th className="py-2 px-4 border-b">Phone</th>
                          <th className="py-2 px-4 border-b">Vehicle Model</th>
                          <th className="py-2 px-4 border-b">Scheduled Rides</th>
                          <th className="py-2 px-4 border-b">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {drivers.map((driver) => (
                          <React.Fragment key={driver.driverID}>
                            <tr className="border-b">
                              <td className="py-2 px-4 border-b">{driver.driverID}</td>
                              <td className="py-2 px-4 border-b">{driver.phone}</td>
                              <td className="py-2 px-4 border-b">{driver.vehicleModel}</td>
                              <td className="py-2 px-4 border-b">
                                {driver.scheduledRides.length > 0
                                  ? `${driver.scheduledRides.length} Ride${driver.scheduledRides.length > 1 ? 's' : ''}`
                                  : "No Rides"}
                                <button
                                  onClick={() => toggleExpandDriver(driver.driverID)}
                                  className="focus:outline-none mx-2"
                                  aria-expanded={expandedDrivers[driver.driverID] || false}
                                  aria-controls={`driver-schedule-${driver.driverID}`}
                                >
                                  {expandedDrivers[driver.driverID] ? (
                                    <FaChevronUp />
                                  ) : (
                                    <FaChevronDown />
                                  )}
                                </button>
                              </td>
                              <td className="py-2 px-4 border-b">
                                <button
                                  className="bg-green-500 text-white px-3 py-1 rounded"
                                  onClick={() => handleAssignDriver(driver.driverID)}
                                  disabled={assigning}
                                >
                                  {assigning ? "Assigning..." : "Assign"}
                                </button>
                              </td>
                            </tr>
                            {expandedDrivers[driver.driverID] && (
                              <tr id={`driver-schedule-${driver.driverID}`}>
                                <td className="py-2 px-4 border-b"></td>
                                <td colSpan="5" className="py-2 px-4 border-b bg-gray-100">
                                  {driver.scheduledRides.length > 0 ? (
                                    <div>
                                      <h3 className="text-md font-semibold mb-2">Scheduled Rides:</h3>
                                      <ul className="list-disc list-inside">
                                        {driver.scheduledRides.map((ride) => (
                                          <li key={ride.id}>
                                            <strong>Ride ID:</strong> {ride.id} | <strong>Pickup:</strong>{" "}
                                            {ride.pickup} | <strong>Drop:</strong> {ride.drop} |{" "}
                                            <strong>Scheduled At:</strong>{" "}
                                            {new Date(ride.scheduledDateTime).toLocaleString()}
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  ) : (
                                    <p>No scheduled rides for this driver.</p>
                                  )}
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                <div className="mt-4 flex justify-end">
                  <button
                    className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                    onClick={closeAssignModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ManageScheduleRide;
