export const pageData = [
    {
      "id": "#2213",
      "pagename": "Cookies Policy",
      "status": "Published",
      "createdat": "01 January, 1970 04:00 am"
    },
    {
      "id": "#2157",
      "pagename": "Privacy Policy",
      "status": "Published",
      "createdat": "01 January, 1970 04:00 am"
    },
    {
      "id": "#394",
      "pagename": "Privacy Policy",
      "status": "Published",
      "createdat": "01 January, 1970 04:00 am"
    },
    {
      "id": "#393",
      "pagename": "Terms and Conditions",
      "status": "Published",
      "createdat": "21 December, 2020 10:51 am"
    },
    {
      "id": "#386",
      "pagename": "Page Not Found",
      "status": "Published",
      "createdat": "23 December, 2020 01:21 am"
    },
    {
      "id": "#383",
      "pagename": "Zeizzu - English Home Page",
      "status": "Published",
      "createdat": "05 December, 2020 03:21 pm"
    }
  ]
  