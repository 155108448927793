import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import { toast } from "sonner";
import apis from "../../utils/API/driverApis";
import useAuthToken from "../../hooks/customHooks";

function NotificationHistory() {
  const [notifications, setNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10; // Number of items per page
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state
  const token = useAuthToken();

  // Fetch notifications from backend API with pagination
  const fetchNotifications = async (page) => {
    setLoading(true); // Start loading
    setError(null); // Reset any previous errors

    try {
      const response = await apis.getDriverNotifications(page, itemsPerPage,token);
      console.log("Fetched Notifications Data:", response);

      // Check if the response contains a valid structure
      if (response && typeof response === "object" && response.data) {
        const data = response.data;

        if (data.notifications && Array.isArray(data.notifications)) {
          const parsedNotifications = data.notifications.map((notification) => ({
            notification_log_id: notification.notification_log_id,
            ride_req_id: notification.ride_req_id,
            recipient_id: notification.recipient_id,
            delivery_status: notification.delivery_status,
            action: notification.action,
            tripStatus: notification.tripStatus || "N/A",
            createdAt: notification.createdAt,
            pickup: "N/A", // Removed 'details' parsing for now
            drop: "N/A",
            fare: "N/A",
            distance: "N/A",
          }));

          console.log("Parsed Notifications:", parsedNotifications);
          setNotifications(parsedNotifications);
          setTotalPages(Math.ceil(data.totalItems / itemsPerPage));
        } else {
          console.error("Data structure did not contain a valid notifications array:", data);
          throw new Error("Invalid data structure received from the server.");
        }
      } else {
        console.error("Unexpected response type:", response);
        throw new Error("Unexpected response type received from the server.");
      }
    } catch (err) {
      console.error("Failed to fetch notifications:", err);
      setError("Failed to load notifications.");
      toast.error("Failed to load notifications. Please try again later.");
    } finally {
      setLoading(false); // End loading
    }
  };

  useEffect(() => {
    fetchNotifications(currentPage);
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const generatePageNumbers = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
    return pages;
  };

  // Function to format ISO date string to readable date and time
  const formatDateTime = (isoString) => {
    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return { formattedDate, formattedTime };
  };

  return (
    <>
      <Navbar />
      <div className="flex pt-16">
        {/* Sidebar with current selection */}
        <Sidebar currentSelection={{ main: "NotificationHistory", sub: null }} />
        <div className="flex-1 bg-stone-200 p-6 overflow-y-auto h-screen">
          <div className="container mx-auto p-4 bg-gray-50 shadow-lg rounded-lg">
            <h3 className="text-md font-light text-white bg-woagreen p-6 rounded-t-lg mb-4">
              Notification History
            </h3>

            {/* Loading Indicator */}
            {loading && (
              <div className="text-center my-4">
                <span className="text-gray-700">Loading notifications...</span>
              </div>
            )}

            {/* Error Message */}
            {error && (
              <div className="text-center my-4 text-red-500">
                {error}
              </div>
            )}

            {/* Notifications Table */}
            {!loading && !error && (
              <>
                {notifications.length === 0 ? (
                  <div className="text-center my-4 text-gray-700">
                    No notifications found.
                  </div>
                ) : (
                  <table className="min-w-full bg-white">
                    <thead>
                      <tr>
                        <th className="text-left px-6 py-3 border-b border-gray-200 text-gray-600 font-medium">
                          ID
                        </th>
                        <th className="text-left px-6 py-3 border-b border-gray-200 text-gray-600 font-medium">
                          Request ID
                        </th>
                        <th className="text-left px-6 py-3 border-b border-gray-200 text-gray-600 font-medium">
                          Driver ID
                        </th>
                        <th className="text-left px-6 py-3 border-b border-gray-200 text-gray-600 font-medium">
                          Status
                        </th>
                        <th className="text-left px-6 py-3 border-b border-gray-200 text-gray-600 font-medium">
                          Action
                        </th>
                        <th className="text-left px-6 py-3 border-b border-gray-200 text-gray-600 font-medium">
                          Trip Status
                        </th>
                        <th className="text-left px-6 py-3 border-b border-gray-200 text-gray-600 font-medium">
                          Date
                        </th>
                        <th className="text-left px-6 py-3 border-b border-gray-200 text-gray-600 font-medium">
                          Time
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {notifications.map((notification) => {
                        const { formattedDate, formattedTime } = formatDateTime(notification.createdAt);
                        return (
                          <tr key={notification.notification_log_id}>
                            <td className="px-6 py-4 border-b border-gray-200 text-gray-700">
                              {notification.notification_log_id}
                            </td>
                            <td className="px-6 py-4 border-b border-gray-200 text-gray-700">
                              {notification.ride_req_id}
                            </td>
                            <td className="px-6 py-4 border-b border-gray-200 text-gray-700">
                              {notification.recipient_id}
                            </td>
                            <td className="px-6 py-4 border-b border-gray-200 text-gray-700">
                              {notification.delivery_status}
                            </td>
                            <td className="px-6 py-4 border-b border-gray-200 text-gray-700">
                              {notification.action}
                            </td>
                            <td className="px-6 py-4 border-b border-gray-200 text-gray-700">
                              {notification.tripStatus}
                            </td>
                            <td className="px-6 py-4 border-b border-gray-200 text-gray-700">
                              {formattedDate}
                            </td>
                            <td className="px-6 py-4 border-b border-gray-200 text-gray-700">
                              {formattedTime}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}

                {/* Pagination Controls */}
                {totalPages > 1 && (
                  <div className="flex justify-center items-center mt-4 space-x-2">
                    <button
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                      className={`${currentPage === 1
                        ? "bg-gray-300 cursor-not-allowed"
                        : "bg-gray-200 hover:bg-gray-300"
                        } text-gray-700 px-3 py-1 rounded-md`}
                    >
                      Previous
                    </button>

                    {generatePageNumbers().map((page) => (
                      <button
                        key={page}
                        onClick={() => handlePageChange(page)}
                        className={`${currentPage === page
                          ? "bg-woagreen text-white"
                          : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                          } px-3 py-1 rounded-md`}
                      >
                        {page}
                      </button>
                    ))}

                    <button
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                      className={`${currentPage === totalPages
                        ? "bg-gray-300 cursor-not-allowed"
                        : "bg-gray-200 hover:bg-gray-300"
                        } text-gray-700 px-3 py-1 rounded-md`}
                    >
                      Next
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default NotificationHistory;
