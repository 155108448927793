export const cashData = [
    {
        "DriverID": 2526,
        "Name": "Rebecca Garcia",
        "Email": "lopezjames@dawson.com",
        "WalletAmount": 219.24
    },
    {
        "DriverID": 1859,
        "Name": "Deborah King",
        "Email": "emily53@gmail.com",
        "WalletAmount": 700.91
    },
    {
        "DriverID": 9453,
        "Name": "William Smith",
        "Email": "frank89@hotmail.com",
        "WalletAmount": 584.21
    },
    {
        "DriverID": 2403,
        "Name": "Antonio Shepherd",
        "Email": "nberg@barry.biz",
        "WalletAmount": 63.66
    },
    {
        "DriverID": 5233,
        "Name": "Michelle Cameron",
        "Email": "jeffreyzuniga@alexander-aguilar.com",
        "WalletAmount": 296.06
    },
    {
        "DriverID": 7445,
        "Name": "Joseph Long",
        "Email": "heather76@ortiz.net",
        "WalletAmount": 713.31
    },
    {
        "DriverID": 1035,
        "Name": "Ms. Stacy Hunt",
        "Email": "hansonbrad@gmail.com",
        "WalletAmount": 223.71
    },
    {
        "DriverID": 5694,
        "Name": "Elijah Dennis",
        "Email": "rodriguezjessica@mckenzie.com",
        "WalletAmount": 60.34
    },
    {
        "DriverID": 3278,
        "Name": "Andrea Conner",
        "Email": "vcohen@castaneda.org",
        "WalletAmount": 909.12
    },
    {
        "DriverID": 7493,
        "Name": "Sarah Roberts",
        "Email": "btaylor@yahoo.com",
        "WalletAmount": 715.54
    },
    {
        "DriverID": 3970,
        "Name": "Toni Baldwin",
        "Email": "nicholasnorton@hotmail.com",
        "WalletAmount": 128.53
    },
    {
        "DriverID": 7853,
        "Name": "Alexander Williams",
        "Email": "iphillips@gmail.com",
        "WalletAmount": 582.41
    },
    {
        "DriverID": 8218,
        "Name": "Kenneth Hill",
        "Email": "nhernandez@yahoo.com",
        "WalletAmount": 389.7
    },
    {
        "DriverID": 9143,
        "Name": "Richard Rios",
        "Email": "joseph48@gmail.com",
        "WalletAmount": 672.0
    },
    {
        "DriverID": 9417,
        "Name": "Michael Dalton",
        "Email": "hornstephen@yahoo.com",
        "WalletAmount": 303.58
    },
    {
        "DriverID": 1916,
        "Name": "Antonio Robinson",
        "Email": "rogerstimothy@gmail.com",
        "WalletAmount": 792.97
    },
    {
        "DriverID": 4339,
        "Name": "Eddie Owens",
        "Email": "amyfoster@hotmail.com",
        "WalletAmount": 979.47
    },
    {
        "DriverID": 4408,
        "Name": "Ashley Clark",
        "Email": "tiffany73@harvey.com",
        "WalletAmount": 850.81
    },
    {
        "DriverID": 2763,
        "Name": "Dustin Walker",
        "Email": "alisondavis@nelson-davis.com",
        "WalletAmount": 744.54
    },
    {
        "DriverID": 9317,
        "Name": "Craig Hoffman",
        "Email": "meyerandrew@gmail.com",
        "WalletAmount": 358.85
    },
    {
        "DriverID": 5532,
        "Name": "Christopher Phillips",
        "Email": "heather14@hotmail.com",
        "WalletAmount": 938.26
    },
    {
        "DriverID": 2849,
        "Name": "Rebecca Friedman",
        "Email": "francisco41@powers.com",
        "WalletAmount": 994.91
    },
    {
        "DriverID": 1608,
        "Name": "Samantha Johnson",
        "Email": "crogers@gmail.com",
        "WalletAmount": 168.68
    },
    {
        "DriverID": 2703,
        "Name": "Kelly Russell",
        "Email": "websterrebecca@gmail.com",
        "WalletAmount": 618.65
    },
    {
        "DriverID": 9072,
        "Name": "Kathryn Vaughn",
        "Email": "othomas@gmail.com",
        "WalletAmount": 703.73
    },
    {
        "DriverID": 5084,
        "Name": "Theresa Valencia",
        "Email": "calvincolon@hotmail.com",
        "WalletAmount": 897.91
    },
    {
        "DriverID": 7487,
        "Name": "Alexandra Larson",
        "Email": "kimberly93@hotmail.com",
        "WalletAmount": 98.41
    },
    {
        "DriverID": 9845,
        "Name": "Marie Jones",
        "Email": "amy73@dixon.biz",
        "WalletAmount": 387.73
    },
    {
        "DriverID": 5276,
        "Name": "Michael Perkins",
        "Email": "brownjonathan@gmail.com",
        "WalletAmount": 456.38
    },
    {
        "DriverID": 3031,
        "Name": "Gregory Marshall",
        "Email": "emily75@hotmail.com",
        "WalletAmount": 567.1
    },
    {
        "DriverID": 1629,
        "Name": "Catherine Murray",
        "Email": "carrieedwards@yahoo.com",
        "WalletAmount": 879.4
    },
    {
        "DriverID": 1264,
        "Name": "David Jenkins",
        "Email": "kevin92@yahoo.com",
        "WalletAmount": 705.81
    },
    {
        "DriverID": 6921,
        "Name": "Eric Jones",
        "Email": "christinagreen@hotmail.com",
        "WalletAmount": 648.18
    },
    {
        "DriverID": 4970,
        "Name": "Christina Thompson",
        "Email": "tommy70@wallace.com",
        "WalletAmount": 975.09
    },
    {
        "DriverID": 3368,
        "Name": "Kevin Romero",
        "Email": "andrewhicks@hotmail.com",
        "WalletAmount": 783.33
    },
    {
        "DriverID": 3036,
        "Name": "William Garcia",
        "Email": "sherry94@ramos.com",
        "WalletAmount": 724.49
    },
    {
        "DriverID": 8572,
        "Name": "Travis Mitchell",
        "Email": "melissarodriguez@gmail.com",
        "WalletAmount": 978.71
    },
    {
        "DriverID": 5706,
        "Name": "Gary Young",
        "Email": "catherine03@graves.com",
        "WalletAmount": 356.1
    },
    {
        "DriverID": 4173,
        "Name": "Debbie Watson",
        "Email": "katherinethomas@cooper.org",
        "WalletAmount": 538.44
    },
    {
        "DriverID": 6674,
        "Name": "Jacob Stevens",
        "Email": "kimberly75@jackson.com",
        "WalletAmount": 149.83
    },
    {
        "DriverID": 7420,
        "Name": "Christopher Bradley",
        "Email": "perezkristina@hotmail.com",
        "WalletAmount": 61.95
    },
    {
        "DriverID": 4891,
        "Name": "Matthew Rodriguez",
        "Email": "susan27@yahoo.com",
        "WalletAmount": 303.54
    },
    {
        "DriverID": 2368,
        "Name": "Kimberly Armstrong",
        "Email": "michelleburns@hotmail.com",
        "WalletAmount": 123.29
    },
    {
        "DriverID": 9900,
        "Name": "Joshua Smith",
        "Email": "courtneyjones@gmail.com",
        "WalletAmount": 210.49
    },
    {
        "DriverID": 3940,
        "Name": "Christina Rodriguez",
        "Email": "georgesmith@gmail.com",
        "WalletAmount": 109.79
    },
    {
        "DriverID": 4752,
        "Name": "Anna Christensen",
        "Email": "whitebetty@adams.org",
        "WalletAmount": 875.14
    },
    {
        "DriverID": 5673,
        "Name": "Charles Hill",
        "Email": "sarah77@hotmail.com",
        "WalletAmount": 611.53
    },
    {
        "DriverID": 2876,
        "Name": "Randy Hayes",
        "Email": "jonathansmith@hotmail.com",
        "WalletAmount": 799.49
    },
    {
        "DriverID": 1516,
        "Name": "Chad Hall",
        "Email": "john33@gmail.com",
        "WalletAmount": 148.83
    }
]
