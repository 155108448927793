import { ADMIN_AUTH, DELETE_USER, GET_USERS_BY_ROLE, UPDATE_USER } from "./apiEndPoint";
import { apiDeleteFetcher, apiGetFetcher, apiPostFetcher, apiPutFetcher } from "./apiFetcher";

/**
 * To get all the users by role.
 * @param {Object} payload - Search by brand name and status.
 */
const getUsersAPI = async (token) => {
  let url = `${ADMIN_AUTH}${GET_USERS_BY_ROLE}`;
  return apiGetFetcher(url, token);
};

/**
 * To get all the users by role.
 * @param {Object} payload - Search by brand name and status.
 */
const getUsersPgnAPI = async (page,items,payload,token) => {
  let url = `${ADMIN_AUTH}${GET_USERS_BY_ROLE}/${page}/${items}`;
  return apiPostFetcher(url, payload,token);
};

/**
 * To add/register a user with administrative privileges.
 * @param {Object} payload - Details of the user.
 * @param {String} token - Bearer token.
 * @returns {Object} An object with {data,error,status}.
 */
const addUserAPI = async (payload,token) => {
  let url = `${ADMIN_AUTH}/register`;
  return apiPostFetcher(url,payload,token);
}

/**
 * To update a user without password.
 * @param {String} token - Bearer token.
 * @param {String} userId - The user id that has to be deleted.
 * @returns {Object} An object with {data,error,status}.
 */
const deleteUserAPI = async (token,userId) => {
  let url = `${ADMIN_AUTH}${DELETE_USER}/${userId}`;
  return apiDeleteFetcher(url,token);
}

/**
 * To update a user's details.
 * @param {Object} payload - Details of the user.
 * @param {String} token - Bearer token.
 * @param {String} userId - Id of the user that needs editing.
 * @returns {Object} An object with {data,error,status}.
 */
const updateUserAPI = async (payload,token,userId) => {
  let url = `${ADMIN_AUTH}${UPDATE_USER}/${userId}`;
  return apiPutFetcher(url,payload,token);
}

/**
 * To update a user's password.
 * @param {Object} newPassword - Details of the user.
 * @param {String} token - Bearer token.
 * @returns {Object} An object with {data,error,status}.
 */
const editUserPasswordAPI = async (payload,token) => {
  let url = `${ADMIN_AUTH}/register`;
  return apiPutFetcher(url,payload,token);
}




const usersApi = {
  getUsersAPI,
  addUserAPI,
  updateUserAPI,
  getUsersPgnAPI,
  editUserPasswordAPI,
  deleteUserAPI
};

export default usersApi;
