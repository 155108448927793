// src/Components/RidesTable.jsx

import React from "react";

export default function RidesTable({ headers, rides, onAssign, type }) {
  return (
    <div className="w-full overflow-x-auto bg-white p-4 rounded shadow">
      {rides.length === 0 ? (
        <p className="text-center text-gray-500">No {type} rides available.</p>
      ) : (
        <table className="w-full text-left border-collapse rounded bg-white" cellSpacing="0">
          <thead>
            <tr className="border-b border-slate-300">
              {headers.map((header, index) => (
                <th
                  key={index}
                  scope="col"
                  className="h-12 px-6 text-sm font-medium stroke-slate-700 text-slate-700"
                >
                  {header}
                </th>
              ))}
              {onAssign && (
                <th
                  scope="col"
                  className="h-12 px-6 text-sm font-medium stroke-slate-700 text-slate-700"
                >
                  Action
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {rides.map((ride) => (
              <tr key={ride.id} className="border-b border-slate-200">
                {headers.map((header) => {
                  const key = header.toLowerCase().replace(/ /g, "");
                  if (key === "scheduleddate&time" || key === "rescheduleddate&time") {
                    return (
                      <td
                        key={`${ride.id}-${key}`}
                        className="h-12 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500"
                      >
                        {new Date(ride[`${key}`]).toLocaleString()}
                      </td>
                    );
                  } else {
                    return (
                      <td
                        key={`${ride.id}-${key}`}
                        className="h-12 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500"
                      >
                        {ride[key]}
                      </td>
                    );
                  }
                })}
                {onAssign && (
                  <td className="h-12 px-6 text-sm transition duration-300 border-slate-200 stroke-slate-500 text-slate-500">
                    <button
                      className="bg-blue-500 text-white px-3 py-1 rounded"
                      onClick={() => onAssign(ride)}
                    >
                      Assign
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}
