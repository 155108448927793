// src/Pages/User/UserList.jsx

import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import { MdEdit, MdDelete } from "react-icons/md";
import Pagination from "../../Components/Pagination"; 
import usersApi from "../../utils/API/userApis"; 
import useAuthToken from "../../hooks/customHooks";
import { useNavigate } from "react-router-dom";

function UserList() {
  const token = useAuthToken();
  const navigate = useNavigate();

  // State for applied filters
  const [filters, setFilters] = useState({
    searchString: "",
    roles: "",
  });

  // State for filter input fields
  const [filterInputs, setFilterInputs] = useState({
    searchString: "",
    roles: "",
  });

  // State for pagination
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  // State for users data, total pages, and total items
  const [users, setUsers] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  // State for loading and error
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  /**
   * Fetches users from the API based on current filters and pagination.
   */
  const fetchUsers = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await usersApi.getUsersPgnAPI(
        currentPage,
        itemsPerPage,
        filters,
        token
      );
      // Assuming the API response structure has data.items, data.totalPages, data.totalItems
      setUsers(response.data.items || []);
      setTotalPages(response.data.totalPages || 1);
      setTotalItems(response.data.totalItems || 0);
    } catch (err) {
      setError(
        err.response?.data?.message ||
          err.message ||
          "An error occurred while fetching users."
      );
    } finally {
      setLoading(false);
    }
  };

  /**
   * Fetch users whenever currentPage, itemsPerPage, or filters change.
   */
  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, itemsPerPage, filters]);

  /**
   * Handles change in items per page.
   * @param {object} e - Event object.
   */
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  /**
   * Handles input changes for filter inputs.
   * @param {object} e - Event object.
   */
  const handleFilterInputChange = (e) => {
    const { name, value } = e.target;
    setFilterInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  /**
   * Handles page number click.
   * @param {number} pageNumber - The page number to navigate to.
   */
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  /**
   * Handles navigation to the first page.
   */
  const handleFirstPage = () => setCurrentPage(1);

  /**
   * Handles navigation to the last page.
   */
  const handleLastPage = () => setCurrentPage(totalPages);

  /**
   * Handles navigation to the previous page.
   */
  const handlePreviousPage = () =>
    setCurrentPage((prev) => Math.max(prev - 1, 1));

  /**
   * Handles navigation to the next page.
   */
  const handleNextPage = () =>
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));

  /**
   * Handles Filter button click.
   */
  const handleFilterClick = () => {
    setFilters(filterInputs);
    setCurrentPage(1); // Reset to first page when filters are applied
  };

  /**
   * Handles Reset button click.
   */
  const handleResetClick = () => {
    setFilterInputs({
      searchString: "",
      roles: "",
    });
    setFilters({
      searchString: "",
      roles: "",
    });
    setCurrentPage(1);
    // fetchUsers(); // No need to call fetchUsers here because useEffect will trigger due to filters change
  };

  /**
   * Handles Edit button click.
   * @param {string} userId - The ID of the user to edit.
   * @param {object} user - The user data.
   */
  const handleEdit = (userId, user) => {
    navigate(`/edit-user/${userId}`, { state: { user } });
  };

  /**
   * Handles the deletion of a user.
   * @param {string} userId - The ID of the user to delete.
   */
  const handleDeleteUser = async (userId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this user?"
    );
    if (!confirmDelete) return;

    // Optimistically update the UI
    const previousUsers = [...users];
    setUsers(users.filter((user) => user.id !== userId));
    setTotalItems((prevTotal) => prevTotal - 1);
    setTotalPages(Math.ceil((totalItems - 1) / itemsPerPage));

    setLoading(true);
    setError(null);
    try {
      await usersApi.deleteUserAPI( token,userId); // Ensure this API method exists
      await fetchUsers(); // Refetch data to ensure consistency
      alert("User deleted successfully!");
    } catch (err) {
      setError(
        err.response?.data?.message ||
          err.message ||
          "Failed to delete user."
      );
      // Revert to previous state in case of error
      setUsers(previousUsers);
      setTotalItems((prevTotal) => prevTotal + 1);
      setTotalPages(Math.ceil(totalItems / itemsPerPage));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Navbar />
      <div className="flex pt-16">
        {/* Sidebar with current selection */}
        <Sidebar currentSelection={{ main: "users", sub: "users" }} />
        <div className="flex-1 bg-stone-200 p-6 overflow-y-auto">
          <div className="container mx-auto p-4">
            {/* Heading */}
            <h1 className="text-2xl text-gray-600 font-bold mb-4">
              User List
            </h1>

            {/* Search and Filter Section */}
            <div className="bg-stone-50 p-5 mb-8 rounded-xl">
              <div className="flex flex-col md:flex-row space-x-0 md:space-x-16 space-y-4 md:space-y-0 items-start md:items-center">
                {/* Search Input */}
                <div className="flex flex-col text-gray-600">
                  <label htmlFor="searchString">Search</label>
                  <input
                    id="searchString"
                    type="text"
                    name="searchString"
                    placeholder="Search by Name | Mobile | Email"
                    value={filterInputs.searchString}
                    onChange={handleFilterInputChange}
                    className="border border-gray-300 rounded p-2 w-96"
                  />
                </div>

                {/* Role Filter */}
                <div className="flex flex-col text-gray-600">
                  <label htmlFor="roles">Role</label>
                  <select
                    id="roles"
                    name="roles"
                    value={filterInputs.roles}
                    onChange={handleFilterInputChange}
                    className="border border-gray-300 rounded p-2"
                  >
                    <option value="">-- Select --</option>
                    <option value="SUPERADMIN">Super Admin</option>
                    <option value="ADMIN">Admin</option>
                    <option value="MODERATOR">Moderator</option>
                    {/* Add more role options as needed */}
                  </select>
                </div>
              </div>

              {/* Filter and Reset Buttons */}
              <div className="flex gap-2 mt-4">
                <button
                  type="button"
                  className="bg-transparent text-green-700 border border-green-700 rounded-lg p-2 h-10"
                  onClick={handleFilterClick}
                >
                  Filter
                </button>
                <button
                  type="button"
                  className="bg-transparent text-red-700 border border-red-700 rounded-lg p-2 h-10"
                  onClick={handleResetClick}
                >
                  Reset
                </button>
              </div>
            </div>

            {/* Users List Header */}
            <div className="flex bg-stone-50 font-semibold justify-between text-gray-600 mb-0.5 p-3 rounded-t-xl">
              <div>User List</div>
              {/* Items Per Page Selector */}
              <div className="flex items-center space-x-2">
                <label htmlFor="itemsPerPage" className="text-sm">
                  Items per page:
                </label>
                <select
                  id="itemsPerPage"
                  className="border border-gray-300 text-sm rounded p-2"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                </select>
                <button
                  type="button"
                  className="border border-emerald-900 text-sm font-normal rounded-md p-2 text-emerald-900"
                  onClick={() => {
                    navigate("/users/add-user");
                  }}
                >
                  + Add New User
                </button>
              </div>
            </div>

            {/* Pagination and Items Information */}
            <div className="flex flex-col md:flex-row bg-stone-50 justify-between items-center text-sm mb-0.5 p-3 rounded-b-none rounded-t-none">
              {/* Showing X to Y of Z */}
              <div className="mb-2 md:mb-0">
                {loading ? (
                  <span>Loading...</span>
                ) : (
                  <span>
                    Showing{" "}
                    {totalItems === 0
                      ? 0
                      : (currentPage - 1) * itemsPerPage + 1}{" "}
                    to {Math.min(currentPage * itemsPerPage, totalItems)} of{" "}
                    {totalItems}
                  </span>
                )}
              </div>

              {/* Pagination Component */}
              {totalPages > 1 && (
                <div className="flex justify-center mt-4">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageClick}
                    onFirstPage={handleFirstPage}
                    onLastPage={handleLastPage}
                    onPreviousPage={handlePreviousPage}
                    onNextPage={handleNextPage}
                  />
                </div>
              )}
            </div>

            {/* Users List Section */}
            <div className="space-y-0">
              {/* Header Row */}
              <div className="flex bg-stone-50 justify-center items-center font-bold text-sm mb-0.5 p-2 border-b border-gray-300">
                <div className="flex-1 text-left">ID</div>
                <div className="flex-1 text-left">Name</div>
                <div className="flex-1 text-center">Mobile</div>
                <div className="flex-1 basis-40 text-left">Email</div>
                <div className="flex-1 text-left">Role</div>
                <div className="flex-1 text-left">Created At</div>
                <div className="flex-1 text-right">Actions</div>
              </div>

              {/* Loading and Error States */}
              {loading ? (
                <div className="flex justify-center p-4 text-gray-500">
                  Loading...
                </div>
              ) : error ? (
                <div className="flex justify-center p-4 text-red-500">
                  {error}
                </div>
              ) : users.length > 0 ? (
                users.map((user) => (
                  <div
                    key={user.id}
                    className="flex bg-white p-2 border-b border-gray-300 transition-transform transform text-sm gap-4 hover:bg-gray-50"
                  >
                    <div className="flex-1 px-1 text-left">{user.id}</div>
                    <div
                      className="flex-1 overflow-hidden text-left"
                      style={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                      }}
                    >
                      {user.firstName} {user.lastName}
                    </div>
                    <div className="flex-1 text-center">{user.phoneNumber}</div>
                    <div
                      className="flex-1 basis-40 overflow-hidden text-left"
                      style={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 2,
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {user.email}
                    </div>
                    <div className="flex-1 text-left">{user.roles}</div>
                    <div className="flex-1 text-left">
                      {new Date(user.createdAt).toLocaleDateString()}
                    </div>
                    <div className="flex-1 flex justify-end space-x-2">
                      <button
                        className="bg-transparent text-green-700 border border-green-700 rounded-lg p-2 text-xs h-8 hover:bg-green-100"
                        aria-label={`Edit user ${user.id}`}
                        onClick={() => handleEdit(user.id, user)}
                        disabled={false} // Change to false to enable
                      >
                        <MdEdit />
                      </button>
                      <button
                        className="bg-transparent text-red-700 border border-red-700 rounded-lg p-2 text-xs h-8 hover:bg-red-100"
                        aria-label={`Delete user ${user.id}`}
                        onClick={() => handleDeleteUser(user.id)}
                        disabled={false} // Change to false to enable
                      >
                        <MdDelete />
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <div className="flex bg-white p-4 text-center text-gray-500">
                  No users found.
                </div>
              )}

              {/* Footer Showing X to Y of Z */}
              <div className="flex flex-col md:flex-row bg-stone-50 justify-between items-center text-sm mb-0.5 p-3 rounded-b-xl">
                <div>
                  Showing{" "}
                  {totalItems === 0
                    ? 0
                    : (currentPage - 1) * itemsPerPage + 1}{" "}
                  to {Math.min(currentPage * itemsPerPage, totalItems)} of{" "}
                  {totalItems}
                </div>

                {/* Pagination Component at Bottom */}
                {totalPages > 1 && (
                  <div className="flex justify-center mt-4">
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageClick}
                      onFirstPage={handleFirstPage}
                      onLastPage={handleLastPage}
                      onPreviousPage={handlePreviousPage}
                      onNextPage={handleNextPage}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Display Delete Error if any */}
      {error && (
        <div className="fixed bottom-4 right-4 bg-red-500 text-white p-3 rounded">
          {error}
        </div>
      )}
    </>
  );
}

export default UserList;
