// src/Components/UnassignedRidesTable.jsx

import React from "react";
import RidesTable from "./RidesTable";

export default function UnassignedRidesTable({ rides, onAssign }) {
  const headers = [
    "Ride ID",
    "Customer ID",
    "Pickup",
    "Drop",
    "Vehicle Type",
    "Distance (km)",
    "Scheduled Date & Time",
  ];

  return (
    <RidesTable
      headers={headers}
      rides={rides}
      onAssign={onAssign}
      type="unassigned"
    />
  );
}
