// src/Components/Tabs.jsx

/* eslint-disable jsx-a11y/aria-proptypes */
/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useState, useRef, useEffect } from "react";

export default function TabsLgBasic({ activeTab, setActiveTab }) {
  const [tabSelected, setTabSelected] = useState({
    currentTab: 1,
    noTabs: 3,
  });

  const wrapperRef = useRef(null);

  const handleKeyDown = (e) => {
    if (e.keyCode === 39) {
      // Right arrow
      if (wrapperRef.current && wrapperRef.current.contains(e.target)) {
        if (tabSelected.currentTab < tabSelected.noTabs) {
          setTabSelected({
            ...tabSelected,
            currentTab: tabSelected.currentTab + 1,
          });
          setActiveTab(getTabName(tabSelected.currentTab + 1));
        } else {
          setTabSelected({
            ...tabSelected,
            currentTab: 1,
          });
          setActiveTab(getTabName(1));
        }
      }
    }

    if (e.keyCode === 37) {
      // Left arrow
      if (wrapperRef.current && wrapperRef.current.contains(e.target)) {
        if (tabSelected.currentTab > 1) {
          setTabSelected({
            ...tabSelected,
            currentTab: tabSelected.currentTab - 1,
          });
          setActiveTab(getTabName(tabSelected.currentTab - 1));
        } else {
          setTabSelected({
            ...tabSelected,
            currentTab: tabSelected.noTabs,
          });
          setActiveTab(getTabName(tabSelected.noTabs));
        }
      }
    }
  };

  const getTabName = (tabNumber) => {
    switch (tabNumber) {
      case 1:
        return "unassigned";
      case 2:
        return "assigned";
      case 3:
        return "rescheduled";
      default:
        return "unassigned";
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabSelected]);

  useEffect(() => {
    // Sync tabSelected.currentTab with activeTab prop
    switch (activeTab) {
      case "unassigned":
        setTabSelected((prev) => ({ ...prev, currentTab: 1 }));
        break;
      case "assigned":
        setTabSelected((prev) => ({ ...prev, currentTab: 2 }));
        break;
      case "rescheduled":
        setTabSelected((prev) => ({ ...prev, currentTab: 3 }));
        break;
      default:
        setTabSelected((prev) => ({ ...prev, currentTab: 1 }));
    }
  }, [activeTab]);

  return (
    <>
      {/*<!-- Component: Basic lg sized tab --> */}
      <section className="max-w-full" aria-multiselectable="false">
        <ul
          className="flex bg-white p-2 rounded-lg items-center border-b border-slate-200"
          role="tablist"
          ref={wrapperRef}
        >
          <li className="" role="presentation">
            <button
              className={`-mb-px inline-flex h-12 w-full items-center justify-center gap-2 whitespace-nowrap rounded-t border-b-2 px-6 text-sm font-medium tracking-wide transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-600 focus:bg-emerald-50 focus-visible:outline-none disabled:cursor-not-allowed ${
                tabSelected.currentTab === 1
                  ? "border-emerald-500 stroke-emerald-500 text-emerald-500 hover:border-emerald-600  hover:text-emerald-600 focus:border-emerald-700 focus:stroke-emerald-700 focus:text-emerald-700 disabled:border-slate-500"
                  : "justify-self-center border-transparent stroke-slate-700 text-slate-700 hover:border-emerald-500 hover:text-emerald-500 focus:border-emerald-600 focus:stroke-emerald-600 focus:text-emerald-600 disabled:text-slate-500"
              }`}
              id="tab-label-1a"
              role="tab"
              aria-setsize="3"
              aria-posinset="1"
              tabIndex={`${tabSelected.currentTab === 1 ? "0" : "-1"}`}
              aria-controls="tab-panel-1a"
              aria-selected={`${
                tabSelected.currentTab === 1 ? "true" : "false"
              }`}
              onClick={() => {
                setTabSelected({ ...tabSelected, currentTab: 1 });
                setActiveTab("unassigned");
              }}
            >
              <span>Unassigned Rides</span>
            </button>
          </li>
          <li className="" role="presentation">
            <button
              className={`-mb-px inline-flex h-12 w-full items-center justify-center gap-2 whitespace-nowrap rounded-t border-b-2 px-6 text-sm font-medium tracking-wide transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-600 focus:bg-emerald-50 focus-visible:outline-none disabled:cursor-not-allowed ${
                tabSelected.currentTab === 2
                  ? "border-emerald-500 stroke-emerald-500 text-emerald-500 hover:border-emerald-600  hover:text-emerald-600 focus:border-emerald-700 focus:stroke-emerald-700 focus:text-emerald-700 disabled:border-slate-500"
                  : "justify-self-center border-transparent stroke-slate-700 text-slate-700 hover:border-emerald-500 hover:text-emerald-500 focus:border-emerald-600 focus:stroke-emerald-600 focus:text-emerald-600 disabled:text-slate-500"
              }`}
              id="tab-label-2a"
              role="tab"
              aria-setsize="3"
              aria-posinset="2"
              tabIndex={`${tabSelected.currentTab === 2 ? "0" : "-1"}`}
              aria-controls="tab-panel-2a"
              aria-selected={`${
                tabSelected.currentTab === 2 ? "true" : "false"
              }`}
              onClick={() => {
                setTabSelected({ ...tabSelected, currentTab: 2 });
                setActiveTab("assigned");
              }}
            >
              <span>Assigned Rides</span>
            </button>
          </li>
          <li className="" role="presentation">
            <button
              className={`-mb-px inline-flex h-12 w-full items-center justify-center gap-2 whitespace-nowrap rounded-t border-b-2 px-6 text-sm font-medium tracking-wide transition duration-300 hover:bg-emerald-50 hover:stroke-emerald-600 focus:bg-emerald-50 focus-visible:outline-none disabled:cursor-not-allowed ${
                tabSelected.currentTab === 3
                  ? "border-emerald-500 stroke-emerald-500 text-emerald-500 hover:border-emerald-600  hover:text-emerald-600 focus:border-emerald-700 focus:stroke-emerald-700 focus:text-emerald-700 disabled:border-slate-500"
                  : "justify-self-center border-transparent stroke-slate-700 text-slate-700 hover:border-emerald-500 hover:text-emerald-500 focus:border-emerald-600 focus:stroke-emerald-600 focus:text-emerald-600 disabled:text-slate-500"
              }`}
              id="tab-label-3a"
              role="tab"
              aria-setsize="3"
              aria-posinset="3"
              tabIndex={`${tabSelected.currentTab === 3 ? "0" : "-1"}`}
              aria-controls="tab-panel-3a"
              aria-selected={`${
                tabSelected.currentTab === 3 ? "true" : "false"
              }`}
              onClick={() => {
                setTabSelected({ ...tabSelected, currentTab: 3 });
                setActiveTab("rescheduled");
              }}
            >
              <span>Rescheduled Rides</span>
            </button>
          </li>
        </ul>
      </section>
      {/*<!-- End Basic lg sized tab --> */}
    </>
  );
}
